import React, { useState, useEffect } from "react";
import Header from "../Header/Header";
import { Link, useNavigate } from "react-router-dom";
import { API_DOMAIN, GlobalConstants } from "../../utils/GlobalConst";
import axios from "axios";
import { toast } from "react-toastify";
import { EMAIL_REGEX } from "../../utils/Validation";
import PhoneInput from "react-phone-input-2";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;

function RequestVenue() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [venueName, setVenueName] = useState("");
  const [venueAddress, setVenueAddress] = useState("");
  const [venueCapacity, setVenueCapacity] = useState("");
  const [venueType, setVenueType] = useState("");
  const [details, setDetails] = useState("");
  const [nameErr, setNameErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [venueNameErr, setVenueNameErr] = useState("");

  // Language Const Start
  const [text_name, setText_name] = useState("Name");
  const [text_ph_name, setText_ph_name] = useState("Enter Name");

  const [text_email, setText_email] = useState("Email");
  const [text_ph_email, setText_ph_email] = useState("Enter Email");

  const [text_venueName, setText_venueName] = useState("Venue Name");
  const [text_ph_venueName, setText_ph_venueName] =
    useState("Enter venue name");
  const [text_venueAddress, setText_venueAddress] = useState("Venue Address");
  const [text_ph_venueAddress, setText_ph_venueAddress] = useState(
    "Enter venue address"
  );

  const [text_cancel, setText_cancel] = useState("Cancel");
  const [text_save, setText_save] = useState("Save");
  const [text_err_email, setText_err_email] = useState("Please Enter Email");
  const [text_err_valid_email, setText_err_valid_email] = useState(
    "Please Enter Valid Email"
  );
  const [text_reqVenueHead, setText_reqVenueHead] = useState("Request A Venue");
  const [text_phoneNumber, setText_phoneNumber] = useState("Phone Number");
  const [text_ph_mobile, setText_ph_mobile] = useState("Enter Phone Number");

  const [text_details, setText_details] = useState("Details");
  const [text_ph_details, setText_ph_details] = useState("Enter Venue Details");
  const [text_capacity, setText_capacity] = useState("Capacity");
  const [text_ph_capacity, setText_ph_capacity] = useState(
    "Enter venue capacity"
  );
  const [text_venueType, setText_venueType] = useState("Type of Venue");
  const [text_ph_venueType, setText_ph_venueType] = useState(
    "Enter Type of Venue"
  );
  const [text_err_name, setText_err_name] = useState("Please Enter Name");
  const [text_err_venueName, setText_err_venueName] = useState(
    "Please Enter Venue Name"
  );
  const [btn_create_event, setBtn_create_event] = useState("Create Event");
  const [text_home, setText_home] = useState("Home");

  const ReqVenueValidationHandler = () => {
    var isValidLogin = true;

    if (!email) {
      isValidLogin = false;
      setEmailErr(<>* {text_err_email} !</>);
    }
    if (email && !EMAIL_REGEX.test(email) === true) {
      isValidLogin = false;
      setEmailErr(<>* {text_err_valid_email}!</>);
    }
    if (!name) {
      isValidLogin = false;
      setNameErr(<>* {text_err_name}!</>);
    }
    if (!venueName) {
      isValidLogin = false;
      setVenueNameErr(<>* {text_err_venueName}!</>);
    }
    return isValidLogin;
  };
  const requestVenueHandle = () => {
    if (ReqVenueValidationHandler()) {
      var API_URL = API_DOMAIN + "venue/addvenuerequest";

      let headerConfig = {
        headers: {
          authorization: "bearer " + sessionStorage.getItem("token"),
        },
      };
      var data = {
        name: name,
        phone: phone,
        email: email,
        details: details,
        venueName: venueName,
        venueAddress: venueAddress,
        venueCapacity: venueCapacity,
        venueType: venueType,
      };

      axios
        .post(API_URL, data, headerConfig)
        .then(function (response) {
          console.log("create", response);
          if (response.status === 200) {
            if (response.data.message === "ok") {
              toast.success("Venue Request Submitted ");
              setTimeout(() => {
                navigate("/");
              }, 1000);
            }
          }
        })
        .catch(function (error) {
          console.log(error);
          if (error.response.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            setTimeout(() => {
              navigate("/");
            }, 1000);
          } else {
            toast.error(error.response.data.message);
          }
        })
        .then(function () {});
    }
  };
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);

  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_name(
        xpath.select1("/resources/string[@name='text_name']", doc).firstChild
          .data
      );
      setText_ph_name(
        xpath.select1("/resources/string[@name='text_ph_name']", doc).firstChild
          .data
      );
      setText_email(
        xpath.select1("/resources/string[@name='text_email']", doc).firstChild
          .data
      );
      setText_ph_email(
        xpath.select1("/resources/string[@name='text_ph_email']", doc)
          .firstChild.data
      );
      setText_venueName(
        xpath.select1("/resources/string[@name='text_venueName']", doc)
          .firstChild.data
      );
      setText_ph_venueName(
        xpath.select1("/resources/string[@name='text_ph_venueName']", doc)
          .firstChild.data
      );
      setText_venueAddress(
        xpath.select1("/resources/string[@name='text_venueAddress']", doc)
          .firstChild.data
      );
      setText_ph_venueAddress(
        xpath.select1("/resources/string[@name='text_ph_venueAddress']", doc)
          .firstChild.data
      );
      setText_phoneNumber(
        xpath.select1("/resources/string[@name='text_phoneNumber']", doc)
          .firstChild.data
      );
      setText_reqVenueHead(
        xpath.select1("/resources/string[@name='text_reqVenueHead']", doc)
          .firstChild.data
      );
      setText_ph_mobile(
        xpath.select1("/resources/string[@name='text_ph_mobile']", doc)
          .firstChild.data
      );
      setText_details(
        xpath.select1("/resources/string[@name='text_details']", doc).firstChild
          .data
      );
      setText_ph_details(
        xpath.select1("/resources/string[@name='text_ph_details']", doc)
          .firstChild.data
      );
      setText_capacity(
        xpath.select1("/resources/string[@name='text_capacity']", doc)
          .firstChild.data
      );
      setText_ph_capacity(
        xpath.select1("/resources/string[@name='text_ph_capacity']", doc)
          .firstChild.data
      );
      setText_venueType(
        xpath.select1("/resources/string[@name='text_venueType']", doc)
          .firstChild.data
      );
      setText_ph_venueType(
        xpath.select1("/resources/string[@name='text_ph_venueType']", doc)
          .firstChild.data
      );
      setText_err_name(
        xpath.select1("/resources/string[@name='text_err_name']", doc)
          .firstChild.data
      );
      setText_err_venueName(
        xpath.select1("/resources/string[@name='text_err_venueName']", doc)
          .firstChild.data
      );
      setText_err_email(
        xpath.select1("/resources/string[@name='text_err_email']", doc)
          .firstChild.data
      );
      setText_err_valid_email(
        xpath.select1("/resources/string[@name='text_err_valid_email']", doc)
          .firstChild.data
      );
      setText_save(
        xpath.select1("/resources/string[@name='text_save']", doc).firstChild
          .data
      );
      setText_cancel(
        xpath.select1("/resources/string[@name='text_cancel']", doc).firstChild
          .data
      );
      setText_home(
        xpath.select1("/resources/string[@name='text_home']", doc).firstChild
          .data
      );
      setBtn_create_event(
        xpath.select1("/resources/string[@name='btn_create_event']", doc)
          .firstChild.data
      );
    } catch (ert) {}
  };
  return (
    <>
      <Header />
      <div
        className="Create-event-wrapper eventdetail-nightmod"
        id="otherPages"
      >
        <div className="breadcrumb-block">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-10">
                <div className="barren-breadcrumb">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">{text_home}</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/create-event">{btn_create_event}</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {text_reqVenueHead}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="event-dt-block p-80">
          <div className="container w-75">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12">
                <div className="main-title text-center">
                  <h3>{text_reqVenueHead}</h3>
                </div>
              </div>
              <div className="main-card">
                <div className="p-4 bp-form main-form">
                  <div className="row p-4">
                    <div className="col-lg-3 col-md-12">
                      <div className="form-group border_bottom-create profile-night-form pb_30">
                        <label className="form-label fs-16">
                          {text_name}
                          <span className="starsty">*</span>
                        </label>
                        <input
                          className="form-control h_50"
                          type="text"
                          placeholder={text_ph_name}
                          onChange={(e) => [
                            setName(e.target.value),
                            setNameErr(""),
                          ]}
                        />
                        <span className="starsty">{nameErr}</span>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12">
                      <div className="form-group border_bottom-create profile-night-form pb_30">
                        <label className="form-label fs-16">
                          {text_phoneNumber}
                        </label>
                        <PhoneInput
                          country={"us"}
                          placeholder={text_ph_mobile}
                          onChange={(e) => setPhone(e)}
                          inputclassName="Register-input"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12">
                      <div className="form-group border_bottom-create profile-night-form pb_30">
                        <label className="form-label fs-16">
                          {text_email}
                          <span className="starsty">*</span>
                        </label>
                        <input
                          className="form-control h_50"
                          type="email"
                          placeholder={text_ph_email}
                          onChange={(e) => [
                            setEmail(e.target.value),
                            setEmailErr(""),
                          ]}
                        />
                        <span className="starsty">{emailErr}</span>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12">
                      <div className="form-group border_bottom-create profile-night-form pb_30">
                        <label className="form-label fs-16">
                          {text_details}
                        </label>
                        <input
                          className="form-control h_50"
                          type="text"
                          placeholder={text_ph_details}
                          onChange={(e) => setDetails(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12">
                      <div className="form-group border_bottom-create profile-night-form pb_30">
                        <label className="form-label fs-16">
                          {text_venueName}
                          <span className="starsty">*</span>
                        </label>
                        <input
                          className="form-control h_50"
                          type="text"
                          placeholder={text_ph_venueName}
                          onChange={(e) => [
                            setVenueName(e.target.value),
                            setVenueNameErr(""),
                          ]}
                        />
                        <span className="starsty">{venueNameErr}</span>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12">
                      <div className="form-group border_bottom-create profile-night-form pb_30">
                        <label className="form-label fs-16">
                          {text_venueAddress}
                        </label>
                        <input
                          className="form-control h_50"
                          type="text"
                          placeholder={text_ph_venueAddress}
                          onChange={(e) => setVenueAddress(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12">
                      <div className="form-group border_bottom-create profile-night-form pb_30">
                        <label className="form-label fs-16">
                          {text_capacity}
                        </label>
                        <input
                          className="form-control h_50"
                          type="number"
                          placeholder={text_ph_capacity}
                          onChange={(e) => setVenueCapacity(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12">
                      <div className="form-group border_bottom-create profile-night-form pb_30">
                        <label className="form-label fs-16">
                          {text_venueType}
                        </label>
                        <input
                          className="form-control h_50"
                          type="text"
                          placeholder={text_ph_venueType}
                          onChange={(e) => setVenueType(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center gap-3 mt-3">
                    <button className="cancelBtn" onClick={() => navigate(-1)}>
                      {text_cancel}
                    </button>
                    <button className="saveBtn" onClick={requestVenueHandle}>
                      {text_save}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RequestVenue;

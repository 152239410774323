import React, { useEffect, useState } from "react";
import { FaTicketAlt } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { API_DOMAIN, GlobalConstants } from "../../utils/GlobalConst";
import axios from "axios";
import { toast } from "react-toastify";
import Header from "../Header/Header";
import { Box, Divider, Modal } from "@mui/material";
import { IoIosCloseCircleOutline } from "react-icons/io";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function VenueConfiguration() {
  const _ = require("lodash");

  const navigate = useNavigate();
  const data = useLocation();
  const venueDetails = data.state !== null ? data.state.venuedetails : 0;
  console.log("venue", venueDetails);
  const [openSingleSeatPopup, setOpenSingleSeatPopup] = useState(false);
  const handleOpenSingleSeatPopup = () => setOpenSingleSeatPopup(true);
  const handleCloseSingleSeatPopup = () => setOpenSingleSeatPopup(false);
  const [popupdata, setPopupdata] = useState([]);
  const [disableSeat, setDisableSeat] = useState(false);
  const [visibleSeat, setVisibleSeat] = useState(true);
  const [seatNumber, setSeatNumber] = useState("");
  const [openRowSeatPopup, setOpenRowSeatPopup] = useState(false);
  const handleOpenRowSeatPopup = () => setOpenRowSeatPopup(true);
  const handleCloseRowSeatPopup = () => setOpenRowSeatPopup(false);
  const [rowPopupData, setRowPopupData] = useState([]);
  const [text_cancel, setText_cancel] = useState("Cancel");
  const [text_save, setText_save] = useState("Save");
  const [text_seatnostart, setText_seatnostart] = useState("Seat No Start");
  const [text_visibleseats, setText_visibleseats] = useState("Visible Seat");
  const [text_disableseats, setText_disableseats] = useState("Disable Seat");
  const [text_seatno, setText_seatno] = useState("Seat No");
  const [text_close, setText_close] = useState("Close");
  const [text_configureseatno, setText_configureseatno] = useState(
    "Configure Seat Number"
  );
  const [text_configureseats1, setText_configureseats1] =
    useState("Configure Seats");
  const [text_sectiontotal, setText_sectiontotal] = useState("Section Total");
  const [text_entrygate, setText_entrygate] = useState("Entry Gate");
  const [text_price, setText_price] = useState("Price");
  const [text_totalseats, setText_totalseats] = useState("Total Seats");
  const [text_sectionname, setText_sectionname] = useState("Section Name");
  const [text_configure, setText_configure] = useState("Configure Price");
  const [text_configurepriceandseats, setText_configurepriceandseats] =
    useState("Configure Price And Seats");
  const [text_configurevenue, setText_configurevenue] =
    useState("Configure Venue");
  const [text_venueconfig, setText_venueconfig] = useState(
    "Venue Configuration"
  );
  const [text_eveType, setText_eveType] = useState("Event Type");
  const [text_home, setText_home] = useState("Home");
  const styleTwo = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    borderRadius: "20px",
  };
  const [venueSeatingDetails, setVenueSeatingDetails] = useState([]);

  const [seatDetails, setseatDetails] = useState({});
  const [showSeats, setShowSeats] = useState(false);
  useEffect(() => {
    setVenueSeatingDetails(venueDetails.seatingarrangement);
  }, []);
  const [selectedSectionId, setSelectedSectionId] = useState("");
  const getSeatDetails = (secid) => {
    var venueId = venueDetails.venueid;
    var API_URL =
      API_DOMAIN +
      `seat/select/venueseat?venueid=${venueId}&section_id=${secid}`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        console.log("seatdetails List", response.data.data);
        if (response.data.message === "success") {
          setShowSeats(true);
          let data = response.data.data;
          var dataMain = data;
          const newArr = dataMain.map((v) => ({ ...v, ischecked: false }));
          console.log(newArr);
          ArrayTOObject(newArr);
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  const ArrayTOObject = (arr) => {
    var seatss = arr,
      result = seatss.reduce(function (r, a) {
        r[a.rowstart] = r[a.rowstart] || [];
        r[a.rowstart].push(a);
        return r;
      }, Object.create(null));
    //sorting below
    var orderedData = Object.keys(result)
      .sort()
      .reduce((obj, key) => {
        obj[key] = result[key];
        return obj;
      }, {});
    for (const key in orderedData) {
      orderedData[key].sort((a, b) => a.column - b.column);
    }
    console.log("object", orderedData);
    setseatDetails(orderedData);
  };
  //   select row handle testing pending
  let checkRowAll = (arr) => arr.every((v) => v.ischecked === true);

  const handleSelectFullRow = (seat, e) => {
    var temp = _.clone(seatDetails);
    var tempp = temp[seat.rowstart];
    setRowPopupData(tempp);
    handleOpenRowSeatPopup();
    for (var i in tempp) {
      tempp[i].ischecked = e.target.checked;
    }
    temp[seat.rowstart] = tempp;
    setseatDetails(temp);
  };

  //   update by row API
  const updateSeatByRow = () => {
    var API_URL = API_DOMAIN + `seat/admin/venueseat/byrow/configure`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    let data = {
      seatDetails: [
        {
          sectionid: selectedSectionId,
          seatnostart: Number(seatNumber),
          rowstart: rowPopupData[0].rowstart,
          venueid: rowPopupData[0].venueid,
          isdisable: disableSeat,
          isvisible: visibleSeat,
        },
      ],
    };
    axios
      .put(API_URL, data, headerConfig)
      .then(function (response) {
        console.log("update seat by row", response);
        if (response.data.message === "success") {
          handleCloseRowSeatPopup();
          setDisableSeat(false);
          setVisibleSeat(true);
          setSeatNumber("");
          setRowPopupData([]);
          getSeatDetails(selectedSectionId);
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  //   update single seat APi
  const updateSingleSeat = () => {
    var API_URL = API_DOMAIN + `seat/admin/venueseat/one/configure`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    let data = {
      seatDetails: [
        {
          sectionid: selectedSectionId,
          seatno: seatNumber,
          seatid: popupdata.seatid,
          venueid: popupdata.venueid,
          isdisable: disableSeat,
          isvisible: visibleSeat,
        },
      ],
    };
    axios
      .put(API_URL, data, headerConfig)
      .then(function (response) {
        console.log("update single seat ", response);
        if (response.data.message === "success") {
          handleCloseSingleSeatPopup();
          setDisableSeat(false);
          setVisibleSeat(true);
          setSeatNumber("");
          setPopupdata([]);
          getSeatDetails(selectedSectionId);
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);

  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_cancel(
        xpath.select1("/resources/string[@name='text_cancel']", doc).firstChild
          .data
      );
      setText_save(
        xpath.select1("/resources/string[@name='text_save']", doc).firstChild
          .data
      );
      setText_configurepriceandseats(
        xpath.select1(
          "/resources/string[@name='text_configurepriceandseats']",
          doc
        ).firstChild.data
      );
      setText_configurevenue(
        xpath.select1("/resources/string[@name='text_configurevenue']", doc)
          .firstChild.data
      );
      setText_venueconfig(
        xpath.select1("/resources/string[@name='text_venueconfig']", doc)
          .firstChild.data
      );
      setText_eveType(
        xpath.select1("/resources/string[@name='text_eveType']", doc).firstChild
          .data
      );
      setText_home(
        xpath.select1("/resources/string[@name='text_home']", doc).firstChild
          .data
      );
      setText_configure(
        xpath.select1("/resources/string[@name='text_configure']", doc)
          .firstChild.data
      );
      setText_sectionname(
        xpath.select1("/resources/string[@name='text_sectionname']", doc)
          .firstChild.data
      );
      setText_totalseats(
        xpath.select1("/resources/string[@name='text_totalseats']", doc)
          .firstChild.data
      );
      setText_price(
        xpath.select1("/resources/string[@name='text_price']", doc).firstChild
          .data
      );
      setText_entrygate(
        xpath.select1("/resources/string[@name='text_entrygate']", doc)
          .firstChild.data
      );
      setText_sectiontotal(
        xpath.select1("/resources/string[@name='text_sectiontotal']", doc)
          .firstChild.data
      );
      setText_configureseats1(
        xpath.select1("/resources/string[@name='text_configureseats1']", doc)
          .firstChild.data
      );
      setText_configureseatno(
        xpath.select1("/resources/string[@name='text_configureseatno']", doc)
          .firstChild.data
      );
      setText_close(
        xpath.select1("/resources/string[@name='text_close']", doc).firstChild
          .data
      );
      setText_seatno(
        xpath.select1("/resources/string[@name='text_seatno']", doc).firstChild
          .data
      );
      setText_disableseats(
        xpath.select1("/resources/string[@name='text_disableseats']", doc)
          .firstChild.data
      );
      setText_visibleseats(
        xpath.select1("/resources/string[@name='text_visibleseats']", doc)
          .firstChild.data
      );
      setText_seatnostart(
        xpath.select1("/resources/string[@name='text_seatnostart']", doc)
          .firstChild.data
      );
    } catch (ert) {}
  };
  return (
    <>
      <Header />
      <div
        className="Create-event-wrapper eventdetail-nightmod"
        id="otherPages"
      >
        <div className="breadcrumb-block">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-10">
                <div className="barren-breadcrumb">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">{text_home}</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/event-type">{text_eveType}</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {text_venueconfig}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="event-dt-block p-80">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12">
                <div className="main-title text-center">
                  <h3>{text_configurevenue}</h3>
                </div>
              </div>
              <div className="col-xl-8 col-lg-9 col-md-12">
                <div className="wizard-steps-block">
                  <div id="add-event-tab" className="step-app">
                    <div className="tab-from-content">
                      <div className="main-card">
                        <div className="bp-title">
                          <h4>
                            <FaTicketAlt className="step_icon me-3" />
                            {text_configurepriceandseats}
                          </h4>
                        </div>
                        <div className="p-4 bp-form main-form">
                          <div className="form-group border_bottom-create profile-night-form pb_30">
                            <div className="ticket-section">
                              <label className="form-label fs-16">
                                {text_configure}
                              </label>
                              <div className="content-holder">
                                <div className="row ">
                                  <table className="OwnerTable">
                                    <thead>
                                      <tr>
                                        <th className="OwnerTableHead">
                                          {text_sectionname}
                                        </th>
                                        <th className="OwnerTableHead">
                                          {text_totalseats}
                                        </th>

                                        <th className="OwnerTableHead">
                                          {text_price}
                                        </th>
                                        <th className="OwnerTableHead">
                                          {text_entrygate}
                                        </th>
                                        <th className="OwnerTableHead">
                                          {text_sectiontotal}
                                        </th>
                                        <th className="OwnerTableHead">
                                          {text_configureseats1}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {" "}
                                      {venueSeatingDetails.map((row) => {
                                        return (
                                          <>
                                            <tr key={1}>
                                              <td className="OwnerTableBody p-2 ">
                                                <input
                                                  type="text"
                                                  value={row.section}
                                                  className="mt-1 form-control h_25"
                                                />
                                              </td>

                                              <td className="OwnerTableBody p-2">
                                                <input
                                                  type="number"
                                                  className="mt-1 form-control h_25"
                                                  value={row.number_of_seat}
                                                />
                                              </td>

                                              <td className="OwnerTableBody p-2">
                                                <div className="d-flex">
                                                  <input
                                                    type="number"
                                                    value={row.price}
                                                    className="mt-1 form-control h_25"
                                                  />
                                                </div>
                                              </td>
                                              <td className="OwnerTableBody p-2 ">
                                                <input
                                                  type="text"
                                                  value={row.entrygate}
                                                  className="mt-1 form-control h_25"
                                                />
                                              </td>
                                              <td className="OwnerTableBody p-2">
                                                <input
                                                  type="number"
                                                  value={row.sectiontotal}
                                                  className="mt-1 form-control h_25"
                                                />
                                              </td>
                                              <td className="OwnerTableBody p-2">
                                                <button
                                                  className=" steps_btn configure-btn"
                                                  onClick={() => [
                                                    getSeatDetails(
                                                      row.section_id
                                                    ),
                                                    setSelectedSectionId(
                                                      row.section_id
                                                    ),
                                                  ]}
                                                >
                                                  {text_configureseats1}
                                                </button>
                                              </td>
                                            </tr>{" "}
                                          </>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {showSeats === true ? (
                          <>
                            <div className="p-4 bp-form main-form">
                              <div className="form-group border_bottom-create profile-night-form pb_30">
                                <div className="ticket-section mt-3">
                                  <label className="form-label fs-16">
                                    {text_configureseatno}
                                  </label>
                                  <div className="content-holder">
                                    <div className=""></div>
                                    <div className="row seatscroll ">
                                      {Object.keys(seatDetails)?.map(function (
                                        rowstart
                                      ) {
                                        return (
                                          <>
                                            <div className="p-3">
                                              <div className=" d-flex gap-4 justify-content-between">
                                                <div className="d-flex  gap-4 ">
                                                  <h5>
                                                    <input
                                                      type="checkbox"
                                                      className="mx-2 selectcheckSTY"
                                                      checked={checkRowAll(
                                                        seatDetails[rowstart]
                                                      )}
                                                      onChange={(e) => [
                                                        handleSelectFullRow(
                                                          {
                                                            rowstart: rowstart,
                                                          },
                                                          e
                                                        ),
                                                      ]}
                                                    />
                                                    {rowstart} .
                                                  </h5>
                                                  {seatDetails[rowstart].map(
                                                    (seat, index) => {
                                                      return (
                                                        <>
                                                          <div
                                                            className="seats gap-3"
                                                            type="A"
                                                          >
                                                            <div
                                                              className={
                                                                seat.isdisable ===
                                                                true
                                                                  ? "seatDisable seat"
                                                                  : seat.isconfigure ===
                                                                    true
                                                                  ? "seatConfigured seat"
                                                                  : "seat"
                                                              }
                                                            >
                                                              <input
                                                                type="checkbox"
                                                                id={seat?._id}
                                                                checked={
                                                                  seat.ischecked
                                                                }
                                                              />
                                                              <label
                                                                for={seat?._id}
                                                              >
                                                                {seat?.seatno}{" "}
                                                                <br />
                                                              </label>
                                                            </div>
                                                            <input
                                                              type="checkbox"
                                                              className="selectcheckSTY mt-2"
                                                              onChange={(e) => [
                                                                console.log(
                                                                  seat
                                                                ),
                                                                setPopupdata(
                                                                  seat
                                                                ),
                                                                handleOpenSingleSeatPopup(),
                                                              ]}
                                                              checked={
                                                                seat.ischecked
                                                              }
                                                            />
                                                          </div>
                                                        </>
                                                      );
                                                    }
                                                  )}
                                                </div>
                                                <div></div>
                                              </div>
                                            </div>
                                          </>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>

                    <div className="step-footer step-tab-pager mt-4">
                      <button
                        data-direction="prev"
                        className="btn cancelBtn me-2"
                        onClick={() => navigate(-1)}
                      >
                        {text_close}
                      </button>
                      <button
                        className="btn btn-default btn-hover steps_btn me-2"
                        onClick={() => handleOpenSingleSeatPopup()}
                      >
                        {text_save}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Ṣingle Seat Popup */}
      <Modal
        open={openSingleSeatPopup}
        onClose={handleCloseSingleSeatPopup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Upload-image-Modal"
      >
        <Box
          sx={styleTwo}
          id="header-registermodal"
          className="Upload-image-event"
        >
          <div>
            <div className="d-flex justify-content-end">
              <IoIosCloseCircleOutline
                className="closeIcon-venue"
                onClick={() => [handleCloseSingleSeatPopup()]}
              />
            </div>
            {console.log("popupdata", popupdata)}
            <h3 className="popup-main-title">{popupdata.seatname}</h3>
            <Divider />
            <div className="container p-4">
              <div className=" d-flex gap-5">
                <div>
                  <input
                    type="checkbox"
                    className="checkboxconfigure"
                    onChange={() => setDisableSeat(true)}
                    checked={disableSeat}
                  />
                  <label htmlFor="" className="labelSTYPopup">
                    &nbsp; &nbsp;{text_disableseats}
                  </label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    className="checkboxconfigure"
                    onChange={() => setVisibleSeat(false)}
                    checked={visibleSeat}
                  />
                  <label htmlFor="" className="labelSTYPopup">
                    &nbsp; &nbsp;{text_visibleseats}
                  </label>
                </div>
              </div>
              <div className=" form-group mt-3">
                <label className="form-label">{text_seatno}</label>
                <input
                  type="text"
                  className="form-control 
                 w-50 "
                  onChange={(e) => setSeatNumber(e.target.value)}
                />
              </div>
            </div>
            <div className="d-flex justify-content-center gap-3 mt-3">
              <button
                onClick={() => handleCloseSingleSeatPopup()}
                className="cancelBtn"
              >
                {" "}
                {text_cancel}{" "}
              </button>

              <button className="resell-Btn" onClick={() => updateSingleSeat()}>
                {" "}
                {text_save}
              </button>
            </div>
          </div>
        </Box>
      </Modal>

      {/* row Seat Popup */}
      <Modal
        open={openRowSeatPopup}
        onClose={handleCloseRowSeatPopup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Upload-image-Modal"
      >
        <Box
          sx={styleTwo}
          id="header-registermodal"
          className="Upload-image-event"
        >
          <div>
            <div className="d-flex justify-content-end">
              <IoIosCloseCircleOutline
                className="closeIcon-venue"
                onClick={() => [handleCloseRowSeatPopup()]}
              />
            </div>
            <h3 className="popup-main-title">
              {rowPopupData.map((d) => {
                return <>{d.seatname}</>;
              })}
            </h3>
            <Divider />
            <div className="container p-4">
              <div className=" d-flex gap-5">
                <div>
                  <input
                    type="checkbox"
                    className="checkboxconfigure"
                    onChange={() => setDisableSeat(true)}
                    checked={disableSeat}
                  />
                  <label htmlFor="" className="labelSTYPopup">
                    &nbsp; &nbsp;{text_disableseats}
                  </label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    className="checkboxconfigure"
                    onChange={() => setVisibleSeat(false)}
                    checked={visibleSeat}
                  />
                  <label htmlFor="" className="labelSTYPopup">
                    &nbsp; &nbsp;{text_visibleseats}
                  </label>
                </div>
              </div>
              <div className=" form-group mt-3">
                <label className="form-label">{text_seatnostart}</label>
                <input
                  type="text"
                  className="form-control 
                 w-50 "
                  onChange={(e) => setSeatNumber(e.target.value)}
                />
              </div>
            </div>
            <div className="d-flex justify-content-center gap-3 mt-3">
              <button
                onClick={() => handleCloseRowSeatPopup()}
                className="cancelBtn"
              >
                {" "}
                {text_cancel}{" "}
              </button>

              <button className="resell-Btn" onClick={() => updateSeatByRow()}>
                {" "}
                {text_save}
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default VenueConfiguration;

import React, { useEffect, useState } from "react";
import "../LandingPage/LandingPage.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { FaBookmark, FaCalendarAlt } from "react-icons/fa";
import { MdWatchLater } from "react-icons/md";
import { BsDot } from "react-icons/bs";
import { GlobalConstants } from "../../utils/GlobalConst";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { FiBookmark } from "react-icons/fi";
import img1 from "../../assets/img/event-imgs/big-1.jpg";
import { GrUpload } from "react-icons/gr";
import { API_DOMAIN } from "../../utils/GlobalConst";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import mainlogo from "../../assets/img/tiq8-Logo-blue.png";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function LandingPage() {
  const navigate = useNavigate();
  const [allEventData, setAllEventData] = useState([]);
  const [SeasonalData, setSeasonalData] = useState([]);
  const [BundleData, setBundleData] = useState([]);
  const [LandingPageData, setLandingPageData] = useState([]);
  const [nextToken, setNextToken] = useState("");
  const [hasMoreEvents, setHasMoreEvents] = useState();
  const [indexBookmark, setIndexBookmark] = useState("");
  const [searchParams] = useSearchParams();
  const cid = searchParams.get("cid");
  const [text_singlematchtickets, setText_singlematchtickets] = useState(
    "Single Match Tickets"
  );
  const [text_seasonpass, setText_seasonpass] = useState("Season Pass");
  const [text_bundlepackages, setText_bundlepackages] =
    useState("Bundle Packages");
  const [text_seemore, setText_seemore] = useState("See More");
  const [text_emptyseason, setText_emptyseason] = useState(
    "No Season Pass Found"
  );
  const [text_eventslist, setText_eventslist] = useState("Events List");
  const [text_viewevents, setText_viewevents] = useState("View Events");
  const [text_nobundlefound, setText_nobundlefound] =
    useState("No Bundle Found");

  const [text_events, setText_events] = useState("Events");
  const [text_price, setText_price] = useState("Price");
  const [text_h, setText_h] = useState("h");

  const getEventsCard = () => {
    var API_URL = API_DOMAIN + `event/cb?uid=${cid}`;

    axios
      .get(API_URL)
      .then((response) => {
        console.log("all events ", response.data.data.event);
        var res = response.data.data.event;
        var arrr = res.filter((v) => v.partnername === "cibona");
        setAllEventData(res);
        setNextToken(res.nexttoken);
        setHasMoreEvents(res.hasMoreEvents);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  useEffect(() => {
    console.log(searchParams.get("cid"));
    getEventsCard();
    if (sessionStorage.getItem("token") !== null) {
      console.log("in if", sessionStorage.getItem("token"));
      getBookmarkedEvents();
    }
  }, []);

  const getMoreEventCard = (nextToken, D) => {
    var type = "selectall";
    var API_URL =
      API_DOMAIN + `event/public?type=${type}&limit=12&nexttoken=${nextToken}`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        var res = response?.data?.data?.event;
        for (let i = 0; i < res.length; i++) {
          D.push(res[i]);
          setAllEventData(D);
        }
        setHasMoreEvents(res.hasMoreEvents);
        setNextToken(res.nexttoken);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  const AddBookmarkHandle = (eveID, eveData) => {
    var API_URL = API_DOMAIN + `event/bookmark`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    var data = {
      event_unique_id: eveID,
      seat_unique_id: "seat_unique_id",
      seat_category_id: "seat_category_id",
      isbookmark: true,
      event_details: [eveData],
    };
    axios
      .post(API_URL, data, headerConfig)
      .then(function (response) {
        console.log(response);
        if (response.data.message === "Bookmark created") {
          getBookmarkedEvents();
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  const RemoveBookmarkHandle = (eveID) => {
    var API_URL = API_DOMAIN + `event/uncheck/bookmark`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    var data = {
      event_unique_id: eveID,
      seat_unique_id: "seat_unique_id",
      seat_category_id: "seat_category_id",
      isbookmark: false,
    };
    axios
      .patch(API_URL, data, headerConfig)
      .then(function (response) {
        console.log("Remove", response);
        if (response.data.message === "Bookmark updated") {
          getBookmarkedEvents();
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  const [bookmaredEventIdList, setBookmaredEventIdList] = useState([]);

  const getBookmarkedEvents = () => {
    var API_URL = API_DOMAIN + `event/get/bookmarks`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        console.log("Bookmarked Events", response.data.data);
        setBookmaredEventIdList(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  const isBookmarked = (eventId) => {
    return bookmaredEventIdList.some(
      (event) => event.event_unique_id === eventId
    );
  };

  console.log("first", bookmaredEventIdList, allEventData);

  const SeasonalCard = () => {
    var API_URL = API_DOMAIN + `seasonal/getall/byuser?uid=${cid}`;

    axios
      .get(API_URL)
      .then((response) => {
        console.log("seasonal data ", response.data.data);
        var res = response.data.data;
        setSeasonalData(res);
        console.log(response.data.data?.event_details);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  const BundlegetData = () => {
    var API_URL = API_DOMAIN + `bundle/getall/byuser?uid=${cid}`;

    axios
      .get(API_URL)
      .then((response) => {
        console.log("bundle data ", response.data.data);
        var res = response.data.data;
        setBundleData(res);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  const infogetData = () => {
    var API_URL = API_DOMAIN + `landingpage/select?uid=${cid}`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then((response) => {
        console.log("info get ", response.data.data);
        var res = response.data.data;
        setLandingPageData(res);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  useEffect(() => {
    SeasonalCard();
    BundlegetData();
    BundlegetData();
    infogetData();
  }, []);

  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);
  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_singlematchtickets(
        xpath.select1("/resources/string[@name='text_singlematchtickets']", doc)
          .firstChild.data
      );
      setText_seasonpass(
        xpath.select1("/resources/string[@name='text_seasonpass']", doc)
          .firstChild.data
      );
      setText_bundlepackages(
        xpath.select1("/resources/string[@name='text_bundlepackages']", doc)
          .firstChild.data
      );
      setText_seemore(
        xpath.select1("/resources/string[@name='text_seemore']", doc).firstChild
          .data
      );
      setText_emptyseason(
        xpath.select1("/resources/string[@name='text_emptyseason']", doc)
          .firstChild.data
      );
      setText_eventslist(
        xpath.select1("/resources/string[@name='text_eventslist']", doc)
          .firstChild.data
      );
      setText_viewevents(
        xpath.select1("/resources/string[@name='text_viewevents']", doc)
          .firstChild.data
      );
      setText_nobundlefound(
        xpath.select1("/resources/string[@name='text_nobundlefound']", doc)
          .firstChild.data
      );
      setText_events(
        xpath.select1("/resources/string[@name='text_events']", doc).firstChild
          .data
      );
      setText_price(
        xpath.select1("/resources/string[@name='text_price']", doc).firstChild
          .data
      );
      setText_h(
        xpath.select1("/resources/string[@name='text_h']", doc).firstChild.data
      );
    } catch (ert) {}
  };
  return (
    <>
      <Header />
      <div className="wrapper" id="otherPages">
        <div className="hero-banner-landing">
          {LandingPageData.map((v) => {
            return (
              <>
                <img
                  className="landing-main-img"
                  src={v?.logourl === "path" ? mainlogo : v?.logourl}
                  alt=""
                />
                <div>
                  {" "}
                  <h2 className="text-center mt-3">
                    <b>{v?.title}</b>
                  </h2>
                  <p className="text-center container">{v?.description}</p>
                </div>
              </>
            );
          })}
        </div>

        <div className="explore-events">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="event-filter-items">
                  <div className="featured-controls">
                    <div className="scrollable-buttons-container">
                      <div
                        className="nav custom-tabs nav-pills nav-fill controls landing-page-buttons"
                        role="tablist"
                      >
                        <button
                          className="control  nav-link-btn active"
                          data-bs-toggle="tab"
                          data-bs-target="#tab-01"
                          type="button"
                          role="tab"
                          aria-controls="tab-01"
                          aria-selected="true"
                        >
                          {text_singlematchtickets}
                        </button>
                        <button
                          className="control  nav-link-btn"
                          data-bs-toggle="tab"
                          data-bs-target="#tab-02"
                          type="button"
                          role="tab"
                          aria-controls="tab-02"
                          aria-selected="false"
                        >
                          {text_seasonpass}
                        </button>
                        <button
                          className="control nav-link-btn"
                          data-bs-toggle="tab"
                          data-bs-target="#tab-03"
                          type="button"
                          role="tab"
                          aria-controls="tab-03"
                          aria-selected="false"
                        >
                          {text_bundlepackages}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="tab-content mb-4">
                <div
                  className="tab-pane fade show active"
                  id="tab-01"
                  role="tabpanel"
                >
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="main-title">
                      <h3>{text_events}</h3>
                    </div>
                  </div>
                  <div className="row">
                    {allEventData.map((e, index) => {
                      return (
                        <>
                          <div
                            className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mix arts concert workshops volunteer sports health_Wellness"
                            data-ref="mixitup-target"
                            id={index}
                          >
                            <div className="main-card mt-4">
                              <span
                                className="bookmark-icon bookmark-content"
                                title=""
                                onClick={() => [setIndexBookmark(index)]}
                              >
                                {isBookmarked(e.event_unique_id) ? (
                                  <>
                                    <FaBookmark
                                      className="bookmarkicon-fill"
                                      onClick={() =>
                                        RemoveBookmarkHandle(e?.event_unique_id)
                                      }
                                    />
                                  </>
                                ) : (
                                  <>
                                    <FiBookmark
                                      className="bookmarkicon"
                                      onClick={() =>
                                        AddBookmarkHandle(e?.event_unique_id, e)
                                      }
                                    />
                                  </>
                                )}
                              </span>
                              <div
                                className="event-thumbnail"
                                onClick={() =>
                                  navigate(
                                    `/event-details/${e?.event_unique_id}`
                                  )
                                }
                              >
                                <Link
                                  to={`/event-details/${e?.event_unique_id}`}
                                  className="thumbnail-img"
                                >
                                  <img
                                    src={
                                      e?.EventImagePath === "path"
                                        ? img1
                                        : e?.EventImagePath
                                    }
                                    alt=""
                                  />
                                </Link>
                              </div>
                              <div className="event-content">
                                <Link
                                  to={`/event-details/${e?.event_unique_id}`}
                                  className="event-title event-css"
                                >
                                  {e?.EventTitle}
                                </Link>
                                <div className="duration-price-remaining">
                                  <span>
                                    {e?.venuedetails[0].venuename} ({" "}
                                    {e?.venuedetails[0].address},{" "}
                                    {e?.venuedetails[0].city},{" "}
                                    {e?.venuedetails[0].state},{" "}
                                    {e?.venuedetails[0].country},{" "}
                                    {e?.venuedetails[0].zipcode})
                                  </span>
                                </div>
                              </div>
                              <div className="event-footer">
                                <div className="event-timing date-event-page">
                                  <div className="publish-date">
                                    <span>
                                      <FaCalendarAlt />{" "}
                                      {moment
                                        .utc(e?.StartDate)
                                        .format("DD MMM, yyyy")}{" "}
                                      &nbsp;
                                    </span>
                                    <span className="dot dot-size-allevent">
                                      <BsDot
                                        style={{
                                          width: "23px",
                                          height: "23px",
                                        }}
                                      />
                                    </span>
                                    <span>
                                      {" "}
                                      {moment
                                        .utc(e?.StartDate)
                                        .format("ddd")},{" "}
                                      {moment
                                        .utc(e?.StartTime)
                                        .format("hh:mm a")}{" "}
                                    </span>
                                  </div>
                                  <span className="publish-time">
                                    <MdWatchLater
                                      style={{ width: "15px", height: "15px" }}
                                    />{" "}
                                    {new Date(
                                      moment.utc(e?.EndTime)
                                    ).getHours() -
                                      new Date(
                                        moment.utc(e?.StartTime)
                                      ).getHours()}{" "}
                                    {text_h}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>{" "}
                        </>
                      );
                    })}
                  </div>

                  {hasMoreEvents ? (
                    <>
                      <div className="browse-btn">
                        <button
                          onClick={() =>
                            getMoreEventCard(nextToken, allEventData)
                          }
                          className="main-btn btn-hover "
                        >
                          {text_seemore}
                        </button>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <div className="tab-pane fade show" id="tab-02" role="tabpanel">
                  {SeasonalData.length === 0 ? (
                    <>
                      <div className="main-card mt-4">
                        <h3 className="text-center font-16 mt-3 fw-bold">
                          {text_emptyseason}
                        </h3>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-xl-12 col-lg-12 col-md-12">
                        <div className="main-title">
                          <h3>{text_seasonpass}</h3>
                        </div>
                      </div>
                      <div className="d-flex gap-3 season-pass-section flex-wrap">
                        {/*scroll class scroll-attend-event */}
                        {SeasonalData.map((e, index) => {
                          return (
                            <>
                              <div className="seasonal-card" id={index}>
                                <div className="main-card mt-4">
                                  <div className="card-top season-card-top p-4">
                                    <div className="card-event-img resell-img-style responsive-card-img">
                                      <img
                                        src={
                                          e?.url === "" || e?.url === undefined
                                            ? img1
                                            : e?.url
                                        }
                                        alt=""
                                      />
                                    </div>
                                    <div className="card-event-dt w-75">
                                      <h5>
                                        {e?.ticketname} : {e.event_count}{" "}
                                        {text_events}
                                      </h5>
                                      <h6 className="seasontextnight">
                                        {" "}
                                        {text_eventslist}:{" "}
                                        <b>
                                          {" "}
                                          {e.event_details.map((ed) => {
                                            return (
                                              <>{ed?.EventTitle}&nbsp; / </>
                                            );
                                          })}
                                        </b>
                                      </h6>
                                      <br />
                                      <h5>
                                        {text_price}: {e?.currency} {e?.price}
                                      </h5>
                                      <div className="event-btn-group">
                                        <button
                                          className="esv-btn me-2"
                                          onClick={() =>
                                            navigate(
                                              `/seasonalevent/${e?.seasonalticketid}`,
                                              {
                                                state: {
                                                  seasonalticketid:
                                                    e?.seasonalticketid,
                                                },
                                              }
                                            )
                                          }
                                        >
                                          <GrUpload /> {text_viewevents}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}{" "}
                      </div>
                    </>
                  )}
                </div>

                <div
                  className="tab-pane fade show "
                  id="tab-03"
                  role="tabpanel"
                >
                  {BundleData.length === 0 ? (
                    <>
                      <div className="main-card mt-4">
                        <h3 className="text-center font-16 mt-3 fw-bold">
                          {text_nobundlefound}
                        </h3>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-xl-12 col-lg-12 col-md-12 ">
                        <div className="main-title">
                          <h3>{text_bundlepackages}</h3>
                        </div>
                      </div>
                      <div className="d-flex gap-3 flex-wrap">
                        {BundleData.map((e, index) => {
                          return (
                            <>
                              <div className="seasonal-card" id={index}>
                                <div className="main-card mt-4">
                                  <div className="card-top season-card-top p-4">
                                    <div className="card-event-img resell-img-style  responsive-card-img">
                                      <img
                                        src={
                                          e?.url === "" || e?.url === undefined
                                            ? img1
                                            : e?.url
                                        }
                                        alt=""
                                      />
                                    </div>
                                    <div className="card-event-dt w-75">
                                      <h5>
                                        {e?.ticketname} : {e.event_count}{" "}
                                        {text_events}
                                      </h5>
                                      <h6 className="seasontextnight">
                                        {" "}
                                        {text_eventslist}:{" "}
                                        <b>
                                          {" "}
                                          {e.event_details.map((ed) => {
                                            return (
                                              <>{ed?.EventTitle}&nbsp; / </>
                                            );
                                          })}
                                        </b>
                                      </h6>
                                      <br />
                                      <h5>
                                        {text_price}: {e?.currency} {e?.price}
                                      </h5>
                                      <div className="event-btn-group">
                                        <button
                                          className="esv-btn me-2"
                                          onClick={() =>
                                            navigate(
                                              `/bundleevent/${e?.bundleticketid}`,
                                              {
                                                state: {
                                                  bundleticketid:
                                                    e?.bundleticketid,
                                                },
                                              }
                                            )
                                          }
                                        >
                                          <GrUpload /> {text_viewevents}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default LandingPage;

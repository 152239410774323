import React, { useEffect, useState } from "react";
import { FiHome } from "react-icons/fi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { API_DOMAIN, GlobalConstants } from "../../utils/GlobalConst";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import img1 from "../../assets/img/event-imgs/big-1.jpg";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import confirmImg from "../../assets/img/confirmed.png";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function ResellPaymentStatus() {
  const navigate = useNavigate();
  const data = useLocation();
  const pid = data.state !== null ? data.state.paymentid : false;
  console.log("object", pid);
  // language variable
  const [text_viewtickets, setText_viewtickets] = useState("View Tickets");
  const [text_eveLocation, setText_eveLocation] = useState("Event Loaction");
  const [text_owner_Name, setText_owner_Name] = useState("Owner Name");
  const [text_ticketconfirmed, setText_ticketconfirmed] =
    useState("Ticket Confirmed");
  const [text_date, setText_date] = useState("Date");
  const [text_time, setText_time] = useState("Time");
  const [text_seat, setText_seat] = useState("Seat");
  const [text_PaymentProccessing, setText_PaymentProccessing] = useState(
    "Payment Proccessing"
  );
  const [text_home, setText_home] = useState("Home");
  const [text_ticketconfirmed_desc, setText_ticketconfirmed_desc] = useState(
    "We are pleased to inform you that your reservation request has been received and confirmed"
  );
  
  const [paymentID, setPaymentID] = useState("");
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  var ticketDetails = JSON.parse(sessionStorage.getItem("resellTicketDetail"));
  var eveDetails = JSON.parse(sessionStorage.getItem("resellEveDetail"));
  var ownerDetails = JSON.parse(sessionStorage.getItem("ownerDetails"));
  const buyTicketHandle = (payid) => {
    var API_URL = API_DOMAIN + "seat/resellbuy";

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    var data = {
      paymentid: payid,
      ownerdetails: JSON.parse(sessionStorage.getItem("ownerDetails")),
    };
    axios
      .post(API_URL, data, headerConfig)
      .then(function (response) {
        console.log("check out", response);
        if (response.data.message === "success") {
          setPaymentSuccess(true);
        }
      })
      .catch(function (error) {
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  useEffect(() => {
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );
    const paymentid = new URLSearchParams(window.location.search).get(
      "payment_intent"
    );
    setPaymentID(paymentid);
    buyTicketHandle(paymentid);
  }, [1]);
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);

  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_viewtickets(
        xpath.select1("/resources/string[@name='text_viewtickets']", doc)
          .firstChild.data
      );
      setText_home(
        xpath.select1("/resources/string[@name='text_home']", doc).firstChild
          .data
      );
      setText_eveLocation(
        xpath.select1("/resources/string[@name='text_eveLocation']", doc)
          .firstChild.data
      );
      setText_owner_Name(
        xpath.select1("/resources/string[@name='text_owner_Name']", doc)
          .firstChild.data
      );
      setText_date(
        xpath.select1("/resources/string[@name='text_date']", doc).firstChild
          .data
      );
      setText_time(
        xpath.select1("/resources/string[@name='text_time']", doc).firstChild
          .data
      );
      setText_seat(
        xpath.select1("/resources/string[@name='text_seat']", doc).firstChild
          .data
      );
      setText_PaymentProccessing(
        xpath.select1("/resources/string[@name='text_PaymentProccessing']", doc)
          .firstChild.data
      );
      setText_ticketconfirmed(
        xpath.select1("/resources/string[@name='text_ticketconfirmed']", doc)
          .firstChild.data
      );
      setText_ticketconfirmed_desc(
        xpath.select1(
          "/resources/string[@name='text_ticketconfirmed_desc']",
          doc
        ).firstChild.data
      );
    } catch (ert) {}
  };
  return (
    <>
      <Header />
      <div className="wrapper">
        <div className="breadcrumb-block">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-10">
                <div className="barren-breadcrumb">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">{text_home}</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {text_ticketconfirmed}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        {paymentSuccess === false ? (
          <>
            <div className="event-dt-block p-80">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-xl-5 col-lg-7 col-md-10">
                    <div className="booking-confirmed-content">
                      <div className="main-card">
                        <div className="booking-confirmed-top text-center p_30">
                          <h4>{text_PaymentProccessing}</h4>
                          <br />
                          <div className="booking-confirmed-img mt-4">
                            <div className="loader mt-4">
                              <div className="circleLoading"></div>
                              <div className="circleLoading"></div>
                              <div className="circleLoading"></div>
                              <div className="circleLoading"></div>
                            </div>
                          </div>
                          <br /> <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="event-dt-block p-80">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-xl-5 col-lg-7 col-md-10">
                    <div className="booking-confirmed-content">
                      <div className="main-card">
                        <div className="booking-confirmed-top text-center p_30">
                          <div className="booking-confirmed-img mt-4">
                            <img src={confirmImg} alt="" />
                          </div>
                          <h4>{text_ticketconfirmed}</h4>
                          <p className="ps-lg-4 pe-lg-4">
                            {text_ticketconfirmed_desc}.
                          </p>
                        </div>
                        <div className="booking-confirmed-bottom">
                          {ticketDetails.map((td) => {
                            return (
                              <>
                                <div className="booking-confirmed-bottom-bg p_30">
                                  <div className="event-order-dt">
                                    <div className="event-thumbnail-img">
                                      <img
                                        src={
                                          eveDetails?.EventImagePath === "path"
                                            ? img1
                                            : eveDetails?.EventImagePath
                                        }
                                        alt=""
                                      />
                                    </div>
                                    <div className="event-order-dt-content">
                                      <h5>{eveDetails?.EventTitle}</h5>
                                      <span>
                                        <b>{text_seat}:</b>{" "}
                                        {ticketDetails[0].seat_name}
                                      </span>
                                      <span>
                                        <b>
                                          {text_date} & {text_time}:
                                        </b>{" "}
                                        {moment
                                          .utc(eveDetails?.StartDate)
                                          .format("ddd, DD MMM, yyyy")}{" "}
                                        {moment
                                          .utc(eveDetails?.StartTime)
                                          .format("hh : mm A")}{" "}
                                        To{" "}
                                        {moment
                                          .utc(eveDetails?.EndDate)
                                          .format("ddd, DD MMM, yyyy")}{" "}
                                        {moment
                                          .utc(eveDetails?.EndTime)
                                          .format("hh : mm A")}
                                      </span>

                                      <div className="buyer-name">
                                        <b>{text_owner_Name}:</b>{" "}
                                        {ownerDetails[0]?.ownername}
                                      </div>
                                      <span className="mt-2">
                                        <b>{text_eveLocation}:</b>{" "}
                                        {eveDetails?.venuedetails[0].venuename}{" "}
                                        ~ {eveDetails?.venuedetails[0].address}
                                        {", "}
                                        {eveDetails?.venuedetails[0].city}
                                        {", "}{" "}
                                        {eveDetails?.venuedetails[0].state}
                                        {", "}
                                        {
                                          eveDetails?.venuedetails[0].country
                                        }{" "}
                                        {", "}
                                        {eveDetails?.venuedetails[0].zipcode}
                                      </span>
                                    </div>
                                  </div>
                                  <Link
                                    to="/invoice"
                                    className="main-btn btn-hover h_50 w-100 mt-5"
                                  >
                                    <i className="fa-solid fa-ticket rotate-icon me-3"></i>
                                    {text_viewtickets}
                                  </Link>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <ToastContainer />
      <Footer />
    </>
  );
}

export default ResellPaymentStatus;

import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import { Link, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import "../Team/CreateTeam.css";
import { API_DOMAIN, GlobalConstants } from "../../utils/GlobalConst";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Box, Divider, Modal } from "@mui/material";
import { IoIosCloseCircleOutline } from "react-icons/io";
import Loader from "../../utils/Loader";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function CreateTeam() {
  const navigate = useNavigate();
  const [teamName, setTeamName] = useState("");
  const [teamDesc, setTeamDesc] = useState("");

  const [teamActive, setTeamActive] = useState("");
  const [venueList, setVenueList] = useState([]);
  const [selectedVenueId, setSelectedVenueId] = useState("");

  //   language variable
  const [text_cancel, setText_cancel] = useState("Cancel");
  const [text_home, setText_home] = useState("Home");
  const [text_save, setText_save] = useState("Save");
  const [text_active, setText_active] = useState("Active");
  const [text_addadmin, setText_addadmin] = useState("Add Admin");
  const [text_adminval, setText_adminval] = useState("Enter Admin Email");
  const [text_adminemail, setText_adminemail] = useState("Admin Email");
  const [text_admin, setText_admin] = useState("Admin");
  const [text_selvenuename, setText_selvenuename] = useState("Select Venue Name");
  const [text_teamhomevenue, setText_teamhomevenue] =
    useState("Team Home Venue");
  const [text_enterteamname, setText_enterteamname] =
    useState("Enter Team Name");
  const [text_teamname, setText_teamname] = useState("Team name");
  const [text_createteam, setText_createteam] = useState("Create Team");
  const [text_orgdashboard, setText_orgdashboard] = useState(
    "Organization Dashboard"
  );
  const [text_upload_image, setText_upload_image] = useState("Upload Image");
  const [text_upload, setText_upload] = useState("Upload");
  // new variable language
  const [text_team_logo, setText_team_logo] = useState("Team Logo");
  const [text_team_des, setText_team_des] = useState("Team Description");
  const [text_enter_team_des, setText_enter_team_des] = useState(
    "Enter Team Desciption"
  );

  const styleTwo = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    borderRadius: "20px",
  };
  const [openImageUploadPopup, setOpenImageUploadPopup] = useState(false);
  const handleOpenImagePopup = () => setOpenImageUploadPopup(true);
  const handleCloseImagePopup = () => setOpenImageUploadPopup(false);
  const [Loading, setLoading] = useState(false);
  const [teamImage, setTeamImage] = useState("");
  const [FilePath, setFilePath] = useState("");
  const [adminInputFields, setAdminInputFields] = useState([
    {
      _id: uuidv4(),
      adminEmail: "",
    },
  ]);
  const handleChangeInput = (_id, event) => {
    const newInputFields = adminInputFields.map((i) => {
      if (_id === i._id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });

    setAdminInputFields(newInputFields);
  };
  const handleAddFields = () => {
    setAdminInputFields([
      ...adminInputFields,
      {
        _id: uuidv4(),
        adminEmail: "",
      },
    ]);
  };
  const handleRemoveAdmin = (_id) => {
    const values = [...adminInputFields];
    values.splice(
      values.findIndex((value) => value._id === _id),
      1
    );
    setAdminInputFields(values);
  };
  const teamAdminValidatation = () => {
    var isValid = true;

    for (let i = 0; i < adminInputFields.length; i++) {
      if (adminInputFields[i].adminEmail === "") {
        isValid = false;

        toast.error("please enter admin email");
      }
    }
    return isValid;
  };
  const handleCreateTeam = () => {
    if (teamAdminValidatation()) {
      const adminllist = [];
      for (let i = 0; i < adminInputFields.length; i++) {
        adminllist.push(adminInputFields[i].adminEmail);
      }
      console.log(adminllist);
      var API_URL = API_DOMAIN + "team/create";

      let headerConfig = {
        headers: {
          authorization: "bearer " + sessionStorage.getItem("token"),
        },
      };
      var data = {
        team_id_: uuidv4(),
        teamname: teamName,
        isteamactive: teamActive,
        adminlist: adminllist,
        venueid: selectedVenueId,
        teamLogo: FilePath,
        teamDescription: teamDesc,
      };
      axios
        .post(API_URL, data, headerConfig)
        .then(function (response) {
          console.log("create Team", response);
          if (response.data.status === true) {
            toast.success(response.data.message);
            setTimeout(() => {
              navigate("/org-myteam");
            }, 1000);
          }
        })
        .catch(function (error) {
          console.log(error);
          if (error.response.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            setTimeout(() => {
              navigate("/");
            }, 1000);
          } else {
            toast.error(error.response.data.message);
          }
        })
        .then(function () {});
    }
  };

  const getVenueDetails = () => {
    var API_URL = API_DOMAIN + "venue/venue";

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        console.log("venue", response);
        setVenueList(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  useEffect(() => {
    getVenueDetails();
  }, []);
  const ImguplpoadValidation = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type;
      if (fileType === "image/jpeg" || fileType === "image/png") {
        setTeamImage(file);
        // You can perform further actions with the selected file here
      } else {
        setTeamImage(null);
        alert("Please upload only JPEG or PNG files.");
      }
    }
  };
  const HandleImgUpload = () => {
    setLoading(true);
    var API_URL = API_DOMAIN + "file/upload";
    var imgdata = new FormData();
    imgdata.append("att_file", teamImage);
    let headerConfig = {
      headers: {
        "Content-Type": "form-data",
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .post(API_URL, imgdata, headerConfig)
      .then(function (response) {
        console.log("Img Upload", response);
        if (response.status === 200) {
          setLoading(false);
          setFilePath(response.data.data.Location);
          handleCloseImagePopup();
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
          setTimeout(() => {
            setLoading(false);
            handleCloseImagePopup();
            setTeamImage("");
          }, 1000);
        }
      })
      .then(function () {});
  };

  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);
  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_cancel(
        xpath.select1("/resources/string[@name='text_cancel']", doc).firstChild
          .data
      );
      setText_home(
        xpath.select1("/resources/string[@name='text_home']", doc).firstChild
          .data
      );
      setText_save(
        xpath.select1("/resources/string[@name='text_save']", doc).firstChild
          .data
      );
      setText_active(
        xpath.select1("/resources/string[@name='text_active']", doc).firstChild
          .data
      );
      setText_addadmin(
        xpath.select1("/resources/string[@name='text_addadmin']", doc)
          .firstChild.data
      );
      setText_adminval(
        xpath.select1("/resources/string[@name='text_adminval']", doc)
          .firstChild.data
      );
      setText_adminemail(
        xpath.select1("/resources/string[@name='text_adminemail']", doc)
          .firstChild.data
      );
      setText_admin(
        xpath.select1("/resources/string[@name='text_admin']", doc).firstChild
          .data
      );
      setText_selvenuename(
        xpath.select1("/resources/string[@name='text_selvenuename']", doc)
          .firstChild.data
      );
      setText_teamhomevenue(
        xpath.select1("/resources/string[@name='text_teamhomevenue']", doc)
          .firstChild.data
      );
      setText_enterteamname(
        xpath.select1("/resources/string[@name='text_enterteamname']", doc)
          .firstChild.data
      );
      setText_teamname(
        xpath.select1("/resources/string[@name='text_teamname']", doc)
          .firstChild.data
      );
      setText_createteam(
        xpath.select1("/resources/string[@name='text_createteam']", doc)
          .firstChild.data
      );
      setText_orgdashboard(
        xpath.select1("/resources/string[@name='text_orgdashboard']", doc)
          .firstChild.data
      );
      setText_upload(
        xpath.select1("/resources/string[@name='text_upload']", doc).firstChild
          .data
      );
      setText_upload_image(
        xpath.select1("/resources/string[@name='text_upload_image']", doc)
          .firstChild.data
      );
      setText_team_logo(
        xpath.select1("/resources/string[@name='text_team_logo']", doc)
          .firstChild.data
      );
      setText_team_des(
        xpath.select1("/resources/string[@name='text_team_des']", doc)
          .firstChild.data
      );
      setText_enter_team_des(
        xpath.select1("/resources/string[@name='text_enter_team_des']", doc)
          .firstChild.data
      );
    } catch (ert) {}
  };
  return (
    <>
      <Header />
      <div
        className="Create-event-wrapper eventdetail-nightmod"
        id="otherPages"
      >
        <div className="breadcrumb-block">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-10">
                <div className="barren-breadcrumb">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">{text_home}</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/org-dashboard">{text_orgdashboard}</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {text_createteam}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="event-dt-block p-80">
          <div className="container ">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12">
                <div className="main-title text-center">
                  <h3>{text_createteam}</h3>
                </div>
              </div>
              <div className="main-card">
                <div className="p-4 bp-form main-form">
                  <div className="row p-4">
                    <div className="col-lg-4 col-md-12">
                      <div className="form-group border_bottom-create profile-night-form pb_30">
                        <label className="form-label fs-16">
                          {text_teamname}
                          <span className="starsty">*</span>
                        </label>
                        <input
                          className="form-control h_50 inputleaguenight"
                          type="text"
                          placeholder={text_enterteamname}
                          onChange={(e) => setTeamName(e.target.value)}
                        />
                      </div>
                      <div className="form-group border_bottom-create profile-night-form pb_30 d-flex">
                        <input
                          type="checkbox"
                          className="mb-2 me-2"
                          name="isteamactive"
                          id=""
                          onChange={(e) => setTeamActive(e.target.checked)}
                        />
                        <label className="form-label mt-2">{text_active}</label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className="form-group border_bottom-create profile-night-form pb_30">
                        <label className="form-label fs-16">
                          {text_teamhomevenue}
                          <span className="starsty">*</span>
                        </label>
                        <select
                          className="form-control h_50 inputleaguenight"
                          type="text"
                          placeholder={text_enterteamname}
                          onChange={(e) => setSelectedVenueId(e.target.value)}
                        >
                          <option selected disabled>
                            {text_selvenuename}
                          </option>
                          {venueList.map((vl) => {
                            return (
                              <>
                                <option value={vl?.venueid}>
                                  {vl?.venuename}
                                </option>
                              </>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <label className="form-label fs-16">
                        {text_team_logo}
                      </label>
                      <button
                        className="btn btn-default btn-hover steps_btn mt-2"
                        onClick={handleOpenImagePopup}
                      >
                        {text_upload}
                      </button>
                      {FilePath !== "" ? (
                        <>
                          <img src={FilePath} alt="" className="eventImg" />
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className="form-group border_bottom-create profile-night-form pb_30">
                        <label className="form-label fs-16">
                          {text_team_des}
                        </label>
                        <input
                          className="form-control h_50 inputleaguenight"
                          type="text"
                          placeholder={text_enter_team_des}
                          onChange={(e) => setTeamDesc(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row p-4">
                    <h2>{text_admin} </h2>
                    {adminInputFields.map((inputField) => {
                      return (
                        <>
                          <div className="col-lg-4 col-md-12">
                            <div className="form-group border_bottom-create profile-night-form pb_30">
                              <label className="form-label fs-16">
                                {text_adminemail}
                                <span className="starsty">*</span>
                              </label>
                              <div className="d-flex gap-4">
                                <input
                                  className="form-control h_50 w-75 inputleaguenight"
                                  type="text"
                                  placeholder={text_adminval}
                                  value={inputField.adminEmail}
                                  name="adminEmail"
                                  id=""
                                  onChange={(e) =>
                                    handleChangeInput(inputField._id, e)
                                  }
                                />
                                <button
                                  onClick={() =>
                                    handleRemoveAdmin(inputField._id)
                                  }
                                  className="removeBtnTeam"
                                >
                                  {" "}
                                  X
                                </button>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                    <div className="justify-content-start d-flex gap-1 mb-2">
                      <button onClick={handleAddFields} className="ADDBtn">
                        +
                      </button>{" "}
                      <p>{text_addadmin}</p>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center gap-4 mt-5">
                  <button className="cancelBtn" onClick={() => navigate(-1)}>
                    {text_cancel}
                  </button>
                  <button className="saveBtn" onClick={handleCreateTeam}>
                    {text_save}
                  </button>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  Image Poupup */}
      <Modal
        open={openImageUploadPopup}
        onClose={handleCloseImagePopup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Upload-image-Modal"
      >
        <Box
          sx={styleTwo}
          id="header-registermodal"
          className="Upload-image-event"
        >
          <div>
            <div className="d-flex justify-content-end">
              <IoIosCloseCircleOutline
                className="closeIcon-venue"
                onClick={() => [handleCloseImagePopup()]}
              />
            </div>
            <h3 className="popup-main-title">{text_upload_image}</h3>
            <Divider />
            {Loading === true ? (
              <>
                <center className=" mt-3 mb-3">
                  <Loader />
                </center>
              </>
            ) : (
              <>
                <div className="container">
                  <div className="text-center form-group mt-3">
                    <input
                      type="file"
                      className="form-control h_50 uploadinput landinginputnight"
                      onChange={ImguplpoadValidation}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-center gap-3 mt-3">
                  <button
                    onClick={() => handleCloseImagePopup()}
                    className="cancelBtn"
                  >
                    {" "}
                    {text_cancel}
                  </button>

                  <button onClick={HandleImgUpload} className="resell-Btn">
                    {" "}
                    {text_save}
                  </button>
                </div>
              </>
            )}
          </div>
        </Box>
      </Modal>
      <ToastContainer />
    </>
  );
}

export default CreateTeam;

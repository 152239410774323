import React, { useState, useEffect } from "react";
import "../CreateEvent/CreateEvent.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { FaLongArrowAltRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import SportIcon from "../../assets/img/sports.png";
import { GlobalConstants } from "../../utils/GlobalConst";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function EventType() {
  const [text_home, setText_home] = useState("Home");
  const [text_create, setText_create] = useState("Create");
  const [text_createneweve, setText_createneweve] =
    useState("Create New Event");
    const [text_eveType, setText_eveType] = useState("Event Type");
    const [text_orgdashboard, setText_orgdashboard] = useState(
      "Organization Dashboard"
    );
  const CreateEventType = [
    {
      img: SportIcon,
      title: "Sports",
      link: "/create-event",
    },
  ];
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);

  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_home(
        xpath.select1("/resources/string[@name='text_home']", doc).firstChild
          .data
      );
      setText_create(
        xpath.select1("/resources/string[@name='text_create']", doc).firstChild
          .data
      );
      setText_createneweve(
        xpath.select1("/resources/string[@name='text_createneweve']", doc)
          .firstChild.data
      );
      setText_eveType(
        xpath.select1("/resources/string[@name='text_eveType']", doc)
          .firstChild.data
      );
      setText_orgdashboard(
        xpath.select1("/resources/string[@name='text_orgdashboard']", doc)
          .firstChild.data
      );
    } catch (ert) {}
  };
  return (
    <>
      <Header />
      <div className="event-type-wrapper eventtype-nightmode" id="otherPages">
        <div className="breadcrumb-block">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-10">
                <div className="barren-breadcrumb">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">{text_home}</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/org-dashboard">{text_orgdashboard}</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                      {text_eveType}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="event-dt-block p-80">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12">
                <div className="main-title text-center checkout-title">
                  <h3>{text_createneweve}</h3>
                </div>
              </div>
              <div className="col-xl-10 col-lg-8 col-md-12">
                <div className="create-block">
                  <div className="row">
                    {CreateEventType.map((v) => {
                      return (
                        <>
                          <div className="col-md-4"></div>
                          <div className="col-md-4">
                            <div className="main-card create-card mt-4">
                              <div className="create-icon">
                                <img
                                  src={v.img}
                                  alt=""
                                  className="event-type-iconsty"
                                />
                              </div>
                              <h4>{v.title}</h4>
                              <Link
                                to={v.link}
                                className="main-btn btn-hover h_40 w-100"
                              >
                                {text_create} <FaLongArrowAltRight />
                              </Link>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default EventType;

import React, { useState, useEffect } from "react";
import "../OrgDashboard/OrgDashboard.css";
import { Link, useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import OrgDashboardSidebar from "./OrgDashboardSidebar.jsx";
import { RxCross2 } from "react-icons/rx";
import {
  FaCamera,
  FaAngleLeft,
  FaAngleRight,
  FaMoneyBill,
  FaBox,
  FaEye,
  FaPlus,
  FaTicketAlt,
} from "react-icons/fa";
import img1 from "../../assets/img/event-imgs/img-1.jpg";
import { FaGauge, FaArrowTrendUp } from "react-icons/fa6";
import { API_DOMAIN, GlobalConstants } from "../../utils/GlobalConst.js";
import axios from "axios";
import { toast } from "react-toastify";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function OrgDashboard() {
  const [text_dashboard, setText_dashboard] = useState("Dashboard");
  const [text_daily, setText_daily] = useState("Daily");
  const [text_weekly, setText_weekly] = useState("Weekly");
  const [text_monthly, setText_monthly] = useState("Monthly");
  const [text_noticeorg, setText_noticeorg] = useState(
    "See the graphical representation below"
  );
  const [text_ticketsales, setText_ticketsales] = useState("Ticket Sales");
  const [text_pageviews, setText_pageviews] = useState("Page Views");
  const [text_orders, setText_orders] = useState("Orders");
  const [text_revenue, setText_revenue] = useState("Revenue");
  const [text_previousperiod, setText_previousperiod] = useState(
    "From Previous Period"
  );
  const [text_selectedeve, setText_selectedeve] = useState("Selected Events");
  const [text_createseasonpass, setText_createseasonpass] =
    useState("Create Season Pass");

  const [btn_create_event, setBtn_create_event] = useState("Create Event");
  const [text_createleague, setText_createleague] = useState("Create League");
  const [text_createteam, setText_createteam] = useState("Create Team");
  // new
  const [text_createbundlepass, setText_createbundlepass] =
    useState("Create Bundle Pass");
  const navigate = useNavigate();

  const [profileImgUrl, setProfileImgUrl] = useState("");

  const getProfielInfo = () => {
    var API_URL = API_DOMAIN + "user/profile/get";

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        var data = response?.data?.data;
        console.log("profile data", data);
        setProfileImgUrl(data?.ProfileURL);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          });
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };

  useEffect(() => {
    getProfielInfo();
  }, []);
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);

  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);

      setText_dashboard(
        xpath.select1("/resources/string[@name='text_dashboard']", doc)
          .firstChild.data
      );
      setText_createseasonpass(
        xpath.select1("/resources/string[@name='text_createseasonpass']", doc)
          .firstChild.data
      );
      setText_selectedeve(
        xpath.select1("/resources/string[@name='text_selectedeve']", doc)
          .firstChild.data
      );
      setText_previousperiod(
        xpath.select1("/resources/string[@name='text_previousperiod']", doc)
          .firstChild.data
      );
      setText_revenue(
        xpath.select1("/resources/string[@name='text_revenue']", doc).firstChild
          .data
      );
      setText_orders(
        xpath.select1("/resources/string[@name='text_orders']", doc).firstChild
          .data
      );
      setText_pageviews(
        xpath.select1("/resources/string[@name='text_pageviews']", doc)
          .firstChild.data
      );
      setText_ticketsales(
        xpath.select1("/resources/string[@name='text_ticketsales']", doc)
          .firstChild.data
      );
      setText_noticeorg(
        xpath.select1("/resources/string[@name='text_noticeorg']", doc)
          .firstChild.data
      );
      setText_monthly(
        xpath.select1("/resources/string[@name='text_monthly']", doc).firstChild
          .data
      );
      setText_weekly(
        xpath.select1("/resources/string[@name='text_weekly']", doc).firstChild
          .data
      );
      setText_daily(
        xpath.select1("/resources/string[@name='text_daily']", doc).firstChild
          .data
      );

      setText_createleague(
        xpath.select1("/resources/string[@name='text_createleague']", doc)
          .firstChild.data
      );
      setBtn_create_event(
        xpath.select1("/resources/string[@name='btn_create_event']", doc)
          .firstChild.data
      );
      setText_createteam(
        xpath.select1("/resources/string[@name='text_createteam']", doc)
          .firstChild.data
      );
      setText_createbundlepass(
        xpath.select1("/resources/string[@name='text_createbundlepass']", doc)
          .firstChild.data
      );
    } catch (ert) {}
  };

  return (
    <>
      <Header />
      <OrgDashboardSidebar />

      <div id="otherPages">
        <div className="wrapper wrapper-body" id="wrapper-body">
          <div className="dashboard-body">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-main-title">
                    <h3>
                      <FaGauge className="me-3" />
                      {text_dashboard}
                    </h3>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="main-card add-organisation-card p-4 mt-5">
                    <div className="ocard-left">
                      <div className="ocard-avatar">
                        <img
                          src={profileImgUrl === "" ? img1 : profileImgUrl}
                          alt=""
                        />
                      </div>
                      <div className="ocard-name">
                        <h4>
                          {" "}
                          {sessionStorage.getItem("userfirstname")} {""}
                          {sessionStorage.getItem("userlastname")}
                        </h4>
                        <span>{sessionStorage.getItem("orgName")}</span>
                      </div>
                    </div>
                    <div className="ocard-right">
                      <div className="mb-4 org-button-create">
                        <button
                          onClick={() => navigate("/create-league")}
                          className="offcanvas-create-btn "
                        >
                          <span>{text_createleague}</span>
                        </button>
                        <button
                          onClick={() => navigate("/create-team")}
                          className="offcanvas-create-btn "
                        >
                          <span>{text_createteam}</span>
                        </button>
                        <button
                          onClick={() => navigate("/event-type")}
                          className="offcanvas-create-btn "
                        >
                          <span>{btn_create_event}</span>
                        </button>
                      </div>
                      <div className="org-button-create gap-3">
                        <button
                          className=" co-main-btn co-main-btn-responsive "
                          onClick={() => navigate("/create-seasonal")}
                        >
                          <i className="fa-solid fa-plus mx-2">
                            <FaPlus className="mb-1" />
                          </i>
                          {text_createseasonpass}
                        </button>
                        <button
                          className=" co-main-btn co-main-btn-responsive"
                          onClick={() => navigate("/create-bundle")}
                        >
                          <i className="fa-solid fa-plus mx-2">
                            <FaPlus className="mb-1" />
                          </i>
                          {text_createbundlepass}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="main-card mt-4">
                    <div className="dashboard-wrap-content">
                      <div className="d-flex flex-wrap justify-content-between align-items-center p-4">
                        <div className="dashboard-date-wrap d-flex flex-wrap justify-content-between align-items-center">
                          <div className="dashboard-date-arrows">
                            <Link to="#" className="before_date">
                              <FaAngleLeft className="left-arrow" />
                            </Link>
                            <Link to="#" className="after_date disabled">
                              <FaAngleRight className="left-arrow" />
                            </Link>
                          </div>
                          <h5 className="dashboard-select-date fw-bold">
                            <span>1st April, 2022</span>-
                            <span>30th April, 2022</span>
                          </h5>
                        </div>
                        <div className="rs">
                          <div className="dropdown dropdown-text event-list-dropdown">
                            <button
                              className="dropdown-toggle event-list-dropdown"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <span>{text_selectedeve} (1)</span>
                            </button>
                            <ul className="dropdown-menu">
                              <li>
                                <Link className="dropdown-item" to="#">
                                  1
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="dashboard-report-content ">
                        <div className="row">
                          <div className="col-xl-3 col-lg-6 col-md-6">
                            <div className="dashboard-report-card purple">
                              <div className="card-content">
                                <div className="card-content">
                                  <span className="card-title fs-6">
                                    {text_revenue} (AUD)
                                  </span>
                                  <span className="card-sub-title fs-3">
                                    $550.00
                                  </span>
                                  <div className="d-flex align-items-center">
                                    <span>
                                      <FaArrowTrendUp className="search--icon" />
                                    </span>
                                    <span className="text-Light font-12 ms-2 me-2">
                                      0.00%
                                    </span>
                                    <span className="font-12 color-body text-nowrap">
                                      {text_previousperiod}
                                    </span>
                                  </div>
                                </div>
                                <div className="card-media">
                                  <FaMoneyBill className="moneybill-icon" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-6 col-md-6">
                            <div className="dashboard-report-card red">
                              <div className="card-content">
                                <div className="card-content">
                                  <span className="card-title fs-6">
                                    {text_orders}
                                  </span>
                                  <span className="card-sub-title fs-3">2</span>
                                  <div className="d-flex align-items-center">
                                    <span>
                                      <FaArrowTrendUp className="search--icon" />{" "}
                                    </span>
                                    <span className="text-Light font-12 ms-2 me-2">
                                      0.00%
                                    </span>
                                    <span className="font-12 color-body text-nowrap">
                                      {text_previousperiod}
                                    </span>
                                  </div>
                                </div>
                                <div className="card-media">
                                  <FaBox className="moneybill-icon" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-6 col-md-6">
                            <div className="dashboard-report-card info">
                              <div className="card-content">
                                <div className="card-content">
                                  <span className="card-title fs-6">
                                    {text_pageviews}
                                  </span>
                                  <span className="card-sub-title fs-3">
                                    30
                                  </span>
                                  <div className="d-flex align-items-center">
                                    <span>
                                      <FaArrowTrendUp className="search--icon" />{" "}
                                    </span>
                                    <span className="text-Light font-12 ms-2 me-2">
                                      0.00%
                                    </span>
                                    <span className="font-12 color-body text-nowrap">
                                      {text_previousperiod}
                                    </span>
                                  </div>
                                </div>
                                <div className="card-media">
                                  <FaEye className="moneybill-icon" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-6 col-md-6">
                            <div className="dashboard-report-card success">
                              <div className="card-content">
                                <div className="card-content">
                                  <span className="card-title fs-6">
                                    {text_ticketsales}
                                  </span>
                                  <span className="card-sub-title fs-3">3</span>
                                  <div className="d-flex align-items-center">
                                    <span>
                                      <FaArrowTrendUp className="search--icon" />{" "}
                                    </span>
                                    <span className="text-Light font-12 ms-2 me-2">
                                      0.00%
                                    </span>
                                    <span className="font-12 color-body text-nowrap">
                                      {text_previousperiod}
                                    </span>
                                  </div>
                                </div>
                                <div className="card-media">
                                  <FaTicketAlt className="moneybill-icon" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="main-card mt-4">
                    <div className="d-flex flex-wrap justify-content-between align-items-center border_bottom p-4">
                      <div className="dashboard-date-wrap d-flex flex-wrap justify-content-between align-items-center">
                        <div className="select-graphic-category">
                          <div className="form-group main-form mb-2 ">
                            <select
                              className="selectpicker select-org"
                              data-width="150px"
                            >
                              <option value="revenue">{text_revenue}</option>
                              <option value="orders">{text_orders}</option>
                              <option value="pageviews">
                                {text_pageviews}
                              </option>
                              <option value="ticketsales">
                                {text_ticketsales}
                              </option>
                            </select>
                          </div>
                          <small className="mt-4">{text_noticeorg}</small>
                        </div>
                      </div>
                      <div className="rs">
                        <div
                          className="btn-group"
                          role="group"
                          aria-label="Basic radio toggle button group"
                        >
                          <input
                            type="radio"
                            className="btn-check"
                            name="btnradio"
                            id="btnradio1"
                          />
                          <label
                            className="btn btn-outline-primary"
                            for="btnradio1"
                          >
                            {text_monthly}
                          </label>
                          <input
                            type="radio"
                            className="btn-check"
                            name="btnradio"
                            id="btnradio2"
                            checked
                          />
                          <label
                            className="btn btn-outline-primary"
                            for="btnradio2"
                          >
                            {text_weekly}
                          </label>
                          <input
                            type="radio"
                            className="btn-check"
                            name="btnradio"
                            id="btnradio3"
                          />
                          <label
                            className="btn btn-outline-primary"
                            for="btnradio3"
                          >
                            {text_daily}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="item-analytics-content p-4 ps-1 pb-2">
                      <div id="views-graphic"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrgDashboard;

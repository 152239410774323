import React, { useEffect, useState } from "react";
import mainlogo from "../../assets/img/tiq8-Logo-blue.png";
import "../Header/Header.css";
import profileimg from "../../assets/img/img-13.jpg";
import { Link, useNavigate } from "react-router-dom";
import { TiArrowSortedDown } from "react-icons/ti";
import {
  FaArrowRightArrowLeft,
  FaBars,
  FaBarsStaggered,
} from "react-icons/fa6";
import { CgCloseO } from "react-icons/cg";
import { IoCompass } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { toast } from "react-toastify";
import { API_DOMAIN, GlobalConstants } from "../../utils/GlobalConst";
import axios from "axios";
import { TbWorld } from "react-icons/tb";
import { IoMdPerson } from "react-icons/io";

const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function Header() {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const userFirstName = sessionStorage.getItem("userfirstname");
  const userLastName = sessionStorage.getItem("userlastname");
  const userEmail = sessionStorage.getItem("useremail");
  const isOrg = sessionStorage.getItem("isOrg");
  const [active, setActive] = useState(false);
  const [opensignOut, setOpenSignout] = useState(false);
  const [orgHeadershow, setOrgHeadershow] = useState(false);
  const [LeftMenu, setLeftMenu] = useState(false);
  const [profileImgUrl, setProfileImgUrl] = useState("");

  // language variable
  const [text_languagelable, setText_languagelabel] =
    useState("Select Language");
  const [text_eng, setText_eng] = useState("English");
  const [text_hin, setText_hin] = useState("Hindi");
  const [text_guj, setText_guj] = useState("Gujarati");
  const [text_arabic, setText_arabic] = useState("Arabic");
  const [text_spanish, setText_spanish] = useState("Spanish");
  const [text_croation, setText_croation] = useState("Croatian");
  const [text_contactus, setText_contactus] = useState("Contact Us");
  const [text_myhome, setText_myhome] = useState("My Home");
  const [text_exploreevent, setText_exploreevent] = useState("Explore Events");
  const [text_myorg, setText_myorg] = useState("My Organization");
  const [text_myprofile, setText_myprofile] = useState("My Profile");
  const [text_signout, setText_signout] = useState("Sign Out");
  const [text_areyousure, setText_areyousure] = useState(
    "Are you sure you want to Sign Out?"
  );
  const [text_yes, setText_yes] = useState("Yes");
  const [text_no, setText_no] = useState("No");
  const [text_teams, setText_teams] = useState("Teams");
  // new language variable
  const [text_signin, setText_signin] = useState("Sign In");
  const [text_leagues, setText_leagues] = useState("Leagues");
  const [text_partner_withus, setText_partner_withus] =
    useState("Partner with Us");
  const [text_romanian, setText_romanian] = useState("Romanian");
  const [text_italian, setText_italian] = useState("Italian");

  const handlenightmode = (event) => {
    if (document.documentElement.classList.contains("night-mode")) {
      localStorage.setItem("gmtNightMode", true);
      return;
    }
    localStorage.removeItem("gmtNightMode");
    if (document.getElementsByClassName("h-100") || active === false) {
      document.body.classList.add("night-mode");
      event.currentTarget.classList.add("add");
    }
    if (active === true) {
      document.body.classList.remove("night-mode");
      event.currentTarget.classList.remove("add");
    }
  };
  useEffect(() => {
    if (opensignOut) {
      document.getElementById("MainDiv").style.pointerEvents = "none";
      document.getElementById("otherPages").style.filter = "blur(3px)";
      document.getElementById("otherPages").style.filter = "blur(3px)";
      document.getElementById("otherPages").style.pointerEvents = "none";
      document.getElementById("otherPages").style.pointerEvents = "none";
    } else {
      document.getElementById("otherPages").style.filter = "blur(0px)";
      document.getElementById("otherPages").style.filter = "blur(0px)";
      document.getElementById("MainDiv").style.pointerEvents = "all";
      document.getElementById("otherPages").style.pointerEvents = "all";
      document.getElementById("otherPages").style.pointerEvents = "all";
    }
  }, [opensignOut]);

  //active Page
  let League,
    FAQ_ContactUS,
    createvent,
    createleague,
    Home,
    teams,
    secondary_Ticket = "headerText";
  const pageName = window.location.pathname.split("/");
  if (pageName[1] === "") {
    Home = "active1";
    League = "headerText";
    FAQ_ContactUS = "headerText";
    createvent = "headerText";
    createleague = "headerText";
    secondary_Ticket = "headline";
    teams = "headerText";
  } else if (pageName[1] === "league-page") {
    Home = "headerText";
    League = "active1";
    FAQ_ContactUS = "headerText";
    createvent = "headerText";
    secondary_Ticket = "headline";
    createleague = "headerText";
    teams = "headerText";
  } else if (pageName[1] === "contact-us" || pageName[1] === "faq") {
    Home = "headerText";
    League = "headerText";
    FAQ_ContactUS = "active1";
    createvent = "headerText";
    secondary_Ticket = "headline";
    createleague = "headerText";
    teams = "headerText";
  } else if (pageName[1] === "create-event") {
    Home = "headerText";
    League = "headerText";
    FAQ_ContactUS = "headerText";
    createvent = "activeCreateEvent";
    secondary_Ticket = "headline";
    createleague = "headerText";
    teams = "headerText";
  } else if (pageName[1] === "resell-event") {
    Home = "headerText";
    League = "headerText";
    FAQ_ContactUS = "headerText";
    createvent = "headerText";
    secondary_Ticket = "active1";
    createleague = "headerText";
    teams = "headerText";
  } else if (pageName[1] === "create-league") {
    Home = "headerText";
    League = "headerText";
    FAQ_ContactUS = "headerText";
    createvent = "headerText";
    secondary_Ticket = "headerText";
    createleague = "activeCreateEvent";
    teams = "headerText";
  } else if (pageName[1] === "team-page") {
    Home = "headerText";
    League = "headerText";
    FAQ_ContactUS = "headerText";
    createvent = "headerText";
    secondary_Ticket = "headerText";
    createleague = "headerText";
    teams = "active1";
  }

  const handleSignout = () => {
    navigate("/signin", {});
    localStorage.clear();
    sessionStorage.clear();
  };
  useEffect(() => {
    const orgHeader = [
      "/org-report",
      "/org-dashboard",
      "/org-events",
      "/org-payout",
      "/org-myteam",
      "/org-landing/info",
      "/org-about",
      "/org-league",
      "/checkin-tickets",
    ];
    if (orgHeader.includes(window.location.pathname)) {
      setOrgHeadershow(true);
    } else {
      setOrgHeadershow(false);
    }
  }, []);

  useEffect(() => {
    if (document.getElementById("vertical_nav") === null) {
      console.log("No Class Find");
    } else {
      if (LeftMenu === true) {
        var element = document.getElementById("vertical_nav");
        element.classList.add("vertical_nav__opened");
        if (
          document.getElementById("vertical_nav").classList[0] ===
          "vertical_nav"
        ) {
          element.classList.add("vertical_nav__minify");
        }
        var bodyClass = document.getElementById("wrapper-body");
        if (
          document.getElementById("vertical_nav").classList[0] === "wrapper"
        ) {
          bodyClass.classList.add("wrapper__minify");
        }
        bodyClass.classList.add("toggle-content");
      }

      if (LeftMenu === false) {
        var element = document.getElementById("vertical_nav");
        element.classList.remove("vertical_nav__opened");
        element.classList.remove("vertical_nav__minify");
        var bodyClass = document.getElementById("wrapper-body");
        bodyClass.classList.remove("toggle-content");
        bodyClass.classList.remove("wrapper__minify");
      }
    }
  });
  const getProfielInfo = () => {
    var API_URL = API_DOMAIN + "user/profile/get";

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        var data = response?.data?.data;
        console.log("profile data", data);
        setProfileImgUrl(data?.ProfileURL);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          });
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  useEffect(() => {
    if (sessionStorage.getItem("token") !== null) {
      getProfielInfo();
    }
  }, []);
  const languageHandle = (e) => {
    const languageCode = e;
    console.log(languageCode);

    var apiUrl =
      API_DOMAIN +
      "language/defaultlanguage/selectdefaultlanguage?languageCode=" +
      languageCode;
    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    axios
      .get(apiUrl, headerConfig)
      .then(function (response) {
        console.log(response);
        var res = response.data.data[0].defaultlanguagescreenlist;
        let xml = "<resources>";

        for (let i = 0; i < res.length; i++) {
          let d = res[i];
          for (let j = 0; j < d.defaultscreenfieldslist.length; j++) {
            var kv = d.defaultscreenfieldslist[j];

            var s = `<string name="${kv.fieldKey}"> ${kv.fieldValue}</string>`;
            xml += s;
          }
        }
        xml += "</resources>";
        GlobalConstants.appXml = xml;
        localStorage.setItem(GlobalConstants.session_lang_xml, xml);
        localStorage.setItem(
          GlobalConstants.session_default_language,
          languageCode
        );
        console.log(languageCode, "-----------");

        if (response.status === 200) {
          window.location.reload();
        }
      })
      .catch(function (error) {
        toast.error(error.massage);
      })
      .then(function () {
        // always executed
      });
  };

  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);

  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_languagelabel(
        xpath.select1("/resources/string[@name='text_languagelable']", doc)
          .firstChild.data
      );
      setText_contactus(
        xpath.select1("/resources/string[@name='text_contactus']", doc)
          .firstChild.data
      );
      setText_myhome(
        xpath.select1("/resources/string[@name='text_myhome']", doc).firstChild
          .data
      );
      setText_exploreevent(
        xpath.select1("/resources/string[@name='text_exploreevent']", doc)
          .firstChild.data
      );
      setText_myorg(
        xpath.select1("/resources/string[@name='text_myorg']", doc).firstChild
          .data
      );
      setText_myprofile(
        xpath.select1("/resources/string[@name='text_myprofile']", doc)
          .firstChild.data
      );
      setText_signout(
        xpath.select1("/resources/string[@name='text_signout']", doc).firstChild
          .data
      );
      setText_areyousure(
        xpath.select1("/resources/string[@name='text_areyousure']", doc)
          .firstChild.data
      );
      setText_yes(
        xpath.select1("/resources/string[@name='text_yes']", doc).firstChild
          .data
      );
      setText_no(
        xpath.select1("/resources/string[@name='text_no']", doc).firstChild.data
      );
      setText_teams(
        xpath.select1("/resources/string[@name='text_teams']", doc).firstChild
          .data
      );
      setText_signin(
        xpath.select1("/resources/string[@name='text_signin']", doc).firstChild
          .data
      );
      setText_leagues(
        xpath.select1("/resources/string[@name='text_leagues']", doc).firstChild
          .data
      );
      setText_partner_withus(
        xpath.select1("/resources/string[@name='text_partner_withus']", doc)
          .firstChild.data
      );
    } catch (ert) {}
  };
  const [TeamList, setTeamList] = useState([]);
  const [LeagueList, setLeagueList] = useState([]);

  const getTeamList = () => {
    var API_URL = API_DOMAIN + "team/getall";
    axios
      .get(API_URL)
      .then(function (response) {
        var res = response.data.data;
        console.log("Team data", res);
        setTeamList(res);
      })
      .catch(function (error) {
        console.log(error);
        // errorToast(err);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          });
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  const getLeagueList = () => {
    var API_URL = API_DOMAIN + "league/getall";
    axios
      .get(API_URL)
      .then(function (response) {
        var res = response.data.data;
        console.log("League data", res);
        setLeagueList(res);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          });
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  useEffect(() => {
    getTeamList();
    getLeagueList();
  }, []);
  return (
    <>
      <div>
        <header className="header">
          <div
            className={opensignOut ? "blurBg header-inner" : "header-inner"}
            id="MainDiv"
          >
            <nav className="navbar navbar-expand-lg bg-barren barren-head navbar fixed-top justify-content-sm-start pt-0 pb-0 ps-lg-0 pe-2n">
              <div
                className={
                  orgHeadershow
                    ? "container-fluid ps-0  header-font"
                    : "container  header-font"
                }
              >
                {orgHeadershow ? (
                  <>
                    <button
                      type="button"
                      id="toggleMenu"
                      className="toggle_menu"
                      onClick={() => [setLeftMenu(!LeftMenu)]}
                    >
                      <i>
                        <FaBarsStaggered />
                      </i>
                    </button>
                    <button
                      id="collapse_menu"
                      className="collapse_menu me-4"
                      onClick={() => setLeftMenu(!LeftMenu)}
                    >
                      <i className="fa-solid fa-bars collapse_menu--icon ">
                        <FaBars />
                      </i>
                      <span className="collapse_menu--label"></span>
                    </button>
                    <button
                      className="navbar-toggler order-3 ms-2 pe-0"
                      type="button"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasNavbar"
                      aria-controls="offcanvasNavbar"
                    >
                      <span className="navbar-toggler-icon">
                        <FaBars />
                      </span>
                    </button>
                    <Link
                      className="navbar-brand order-1 order-lg-0 ml-lg-0 ml-2 me-auto"
                      to="/"
                    >
                      <div className="res-main-logo">
                        <img src={mainlogo} alt="" />
                      </div>
                      <div className="main-logo show-main-logo" id="logo">
                        <img src={mainlogo} alt="" />
                        <img className="logo-inverse" src={mainlogo} alt="" />
                      </div>
                    </Link>
                  </>
                ) : (
                  <>
                    <button
                      className="navbar-toggler"
                      type="button"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasNavbar"
                      aria-controls="offcanvasNavbar"
                    >
                      <span className="navbar-toggler-icon">
                        <FaBars />
                      </span>
                    </button>
                    <Link
                      className="navbar-brand order-1 order-lg-0 ml-lg-0 ml-2 me-auto"
                      to="/"
                    >
                      <div className="res-main-logo">
                        <img src={mainlogo} alt="" />
                      </div>
                      <div className="main-logo" id="logo">
                        <img src={mainlogo} alt="" />
                        <img className="logo-inverse" src={mainlogo} alt="" />
                      </div>
                    </Link>
                  </>
                )}

                <div
                  className="offcanvas offcanvas-start"
                  tabindex="-1"
                  id="offcanvasNavbar"
                  aria-labelledby="offcanvasNavbarLabel"
                >
                  <div className="offcanvas-header">
                    <div className="offcanvas-logo" id="offcanvasNavbarLabel">
                      <Link
                        className="navbar-brand order-1 order-lg-0 ml-lg-0 ml-2 me-auto"
                        to="/"
                      >
                        <img src={mainlogo} alt="" />
                      </Link>
                    </div>
                    <button
                      type="button"
                      className="close-btn"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      <RxCross2 />
                    </button>
                  </div>
                  <div className="offcanvas-body">
                    {orgHeadershow ? (
                      <>
                        <ul className="navbar-nav justify-content-end flex-grow-1 pe_5">
                          <li className="nav-item">
                          <div className="lang-select-box">
                              <span>
                                <TbWorld />
                              </span>
                            <select
                              className="lang-select-input"
                              onChange={(e) => [languageHandle(e.target.value)]}
                              defaultValue={localStorage.getItem(
                                "default_language"
                              )}
                            >
                              {/* <option disabled>{text_languagelable}</option> */}
                              <option value="en">{text_eng}</option>
                              {/* <option value="hi">{text_hin}</option>
                              <option value="guj">{text_guj}</option>
                              <option value="ar">{text_arabic}</option> */}
                              <option value="hrv">{text_croation}</option>{" "}
                              <option value="es">{text_spanish}</option>
                              <option value="rom">{text_romanian}</option>
                              <option value="ita">{text_italian}</option>
                            </select>
                            </div>
                          </li>
                          <li className="nav-item">
                            <Link className="nav-link" to="/">
                              <FaArrowRightArrowLeft className="me-2" />
                              {text_myhome}
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link className="nav-link" to="/all-event">
                              <IoCompass className="me-2 explore-icon" />
                              {text_exploreevent}
                            </Link>
                          </li>
                        </ul>
                      </>
                    ) : (
                      <>
                        {" "}
                        <ul
                          className="navbar-nav justify-content-end flex-grow-1 pe_5"
                          id="headerText"
                        >
                          <li className="nav-item dropdown">
                            <Link
                              className="nav-link "
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              id={teams}
                            >
                              {text_teams}
                            </Link>
                            <ul className="dropdown-menu dropdown-submenu team-dropdown">
                              {TeamList.map((v) => {
                                return (
                                  <>
                                    <li>
                                      <Link
                                        className="dropdown-item"
                                        to={`/team-page?teamid=${v.teamid}`}
                                      >
                                        {v.teamname}
                                      </Link>
                                    </li>
                                  </>
                                );
                              })}
                            </ul>
                          </li>
                          <li className="nav-item dropdown">
                            <Link
                              className="nav-link "
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              id={League}
                            >
                              {text_leagues}
                            </Link>
                            <ul className="dropdown-menu dropdown-submenu team-dropdown">
                              {LeagueList.map((v) => {
                                return (
                                  <>
                                    <li>
                                      <Link
                                        className="dropdown-item"
                                        to={`/league-page?leagueid=${v.leagueid}`}
                                      >
                                        {v.leaguename}
                                      </Link>
                                    </li>
                                  </>
                                );
                              })}
                            </ul>
                          </li>{" "}
                          <li className="nav-item dropdown">
                            <Link
                              className="nav-link "
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              id={FAQ_ContactUS}
                            >
                              {text_partner_withus}
                            </Link>
                            <ul className="dropdown-menu dropdown-submenu">
                              {/* <li>
                                <Link className="dropdown-item" to="/faq">
                                  FAQ
                                </Link>
                              </li> */}

                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/contact-us"
                                >
                                  {text_contactus}
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li className="nav-item ">
                            <div className="lang-select-box">
                              <span>
                                <TbWorld />
                              </span>
                              <select
                                className="lang-select-input"
                                onChange={(e) => [
                                  languageHandle(e.target.value),
                                ]}
                                defaultValue={localStorage.getItem(
                                  "default_language"
                                )}
                              >
                                {/* <option disabled>{text_languagelable}</option> */}
                                <option value="en">{text_eng}</option>
                                {/* <option value="hi">{text_hin}</option>
                              <option value="guj">{text_guj}</option>
                              <option value="ar">{text_arabic}</option> */}
                                <option value="hrv">{text_croation}</option>{" "}
                                <option value="es">{text_spanish}</option>
                                <option value="rom">{text_romanian}</option>
                                <option value="ita">{text_italian}</option>
                              </select>
                            </div>
                          </li>
                        </ul>
                      </>
                    )}
                  </div>
                </div>
                <div className="right-header order-2">
                  <ul className="align-self-stretch">
                    {sessionStorage.getItem("token") === null ? (
                      <>
                        <li>
                          <button
                            onClick={() =>
                              navigate("/signin", {
                                state: { pageLand: window.location.pathname },
                              })
                            }
                            className="create-btn btn-hover  Login-btn-mob Login-btn"
                          >
                            <IoMdPerson className="calendericon" />
                            <span className="sigin-text">
                              <>{text_signin}</>
                            </span>
                          </button>
                        </li>
                      </>
                    ) : (
                      <>
                        <li className="dropdown account-dropdown">
                          <Link
                            className="account-link"
                            role="button"
                            id="accountClick"
                            data-bs-auto-close="outside"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img
                              src={
                                profileImgUrl === ""
                                  ? profileimg
                                  : profileImgUrl
                              }
                              alt=""
                            />
                            <TiArrowSortedDown
                              className={active ? "text-muted" : "downarrow"}
                            />
                          </Link>
                          <ul
                            className="dropdown-menu dropdown-menu-account dropdown-menu-end res-dropdown-header"
                            aria-labelledby="accountClick"
                          >
                            {opensignOut ? (
                              ""
                            ) : (
                              <>
                                <li>
                                  <div className="dropdown-account-header">
                                    <div className="account-holder-avatar">
                                      <img
                                        src={
                                          profileImgUrl === ""
                                            ? profileimg
                                            : profileImgUrl
                                        }
                                        alt=""
                                      />
                                    </div>
                                    <h5>
                                      {userFirstName + " " + userLastName}
                                    </h5>
                                    <p>{userEmail}</p>
                                  </div>
                                </li>
                                <li className="profile-link">
                                  {orgHeadershow ? (
                                    ""
                                  ) : (
                                    <>
                                      {isOrg === "true" ? (
                                        <>
                                          <Link
                                            to="/org-dashboard"
                                            className="link-item"
                                          >
                                            {text_myorg}
                                          </Link>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  )}
                                  <Link to="/profile" className="link-item">
                                    {text_myprofile}
                                  </Link>
                                  <Link className="link-item ">
                                    {" "}
                                    <button
                                      onClick={() => [
                                        setOpenSignout(!opensignOut),
                                      ]}
                                      className="signoutbtn-haeder"
                                    >
                                      {text_signout}
                                    </button>
                                  </Link>
                                </li>
                              </>
                            )}
                          </ul>
                        </li>
                      </>
                    )}
                    {/* <li>
                      <div
                        className="night_mode_switch__btn"
                        onClick={(e) => [
                          handlenightmode(e),
                          setActive(!active),
                        ]}
                      >
                        <div id="night-mode" className="fas fa-moon fa-sun">
                          {active ? <FaMoon /> : <FaSun />}
                        </div>
                      </div>
                    </li> */}
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </header>
      </div>
      <div>
        <Dialog
          fullScreen={fullScreen}
          open={opensignOut}
          id="MainDiv"
          className="signout-modal"
          onClose={() => setOpenSignout(false)}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title" className="text-end">
            <CgCloseO
              className="closeIconSty hometextnight"
              onClick={() => [setOpenSignout(false)]}
            />
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <h3 className="text-center hometextnight">{text_areyousure}</h3>
            </DialogContentText>
            <Divider />
          </DialogContent>
          <div className="d-flex justify-content-center gap-4 mb-3">
            <button className="popup_btn_yes" onClick={handleSignout}>
              {text_yes}
            </button>
            <button
              className="popup_btn_No"
              onClick={() => setOpenSignout(false)}
            >
              {text_no}
            </button>
          </div>
        </Dialog>
      </div>
    </>
  );
}

export default Header;

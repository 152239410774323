import React, { useEffect, useState } from "react";
import "../SupportChat/SupportChat.css";
import { IoIosHelp } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { API_DOMAIN, GlobalConstants } from "../../utils/GlobalConst";
import { EMAIL_REGEX } from "../../utils/Validation";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;

function SupportChat() {
  const navigate = useNavigate();
  const [Email, setEmail] = useState("");
  const [Message, setMessage] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [messageErr, setMessageErr] = useState("");
  //   language variable
  const [text_help, setText_help] = useState("How Can We Help");
  const [text_email, setText_email] = useState("Email");
  const [text_message, setText_message] = useState("Message");
  const [text_ph_email, setText_ph_email] = useState("Enter Email");
  const [text_ph_type_msg, setText_ph_type_msg] = useState("Type message..");
  const [text_submit, setText_submit] = useState("Submit");
  const [text_close, setText_close] = useState("Close");
  const [text_err_email, setText_err_email] = useState("Please Enter Email");
  const [text_err_valid_email, setText_err_valid_email] = useState(
    "Please Enter Valid Email"
  );
  const [text_entermessage, setText_entermessage] = useState(
    "Please Enter Message"
  );

  const openForm = () => {
    document.getElementById("myForm").style.display = "block";
  };

  const closeForm = () => {
    document.getElementById("myForm").style.display = "none";
    setEmail("");
    setMessage("");
    setEmailErr("");
    setMessageErr("");
  };
  const ContactValidationHandler = () => {
    var isValidcontact = true;

    if (!Email) {
      isValidcontact = false;
      setEmailErr(<>*{text_err_email}!</>);
    }
    if (Email !== "" && EMAIL_REGEX.test(Email) === false) {
      isValidcontact = false;
      setEmailErr(<>*{text_err_valid_email}!</>);
    }
    if (!Message) {
      isValidcontact = false;
      setMessageErr(<>*{text_entermessage}!</>);
    }
    return isValidcontact;
  };

  const ContactHandle = () => {
    if (ContactValidationHandler()) {
      var API_URL = API_DOMAIN + "contact/contactus";
      var data = {
        firstname: "",
        lastname: "",
        email: Email,
        phone: "",
        msg: Message,
      };
      axios
        .post(API_URL, data)
        .then(function (response) {
          console.log("response***", response);
          toast.success(response?.data?.message, {
            autoClose: 2000,
            theme: "light",
          });
          setTimeout(() => {
            closeForm();
          }, 1000);
        })
        .catch(function (error) {
          console.log("catch block err***", error.response.data);
          if (error.response.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            setTimeout(() => {
              navigate("/");
            }, 1000);
          } else {
          }
        })
        .then(function () {});
    }
  };

  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);

  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_help(
        xpath.select1("/resources/string[@name='text_help']", doc).firstChild
          .data
      );
      setText_ph_email(
        xpath.select1("/resources/string[@name='text_ph_email']", doc)
          .firstChild.data
      );
      setText_email(
        xpath.select1("/resources/string[@name='text_email']", doc).firstChild
          .data
      );
      setText_message(
        xpath.select1("/resources/string[@name='text_message']", doc).firstChild
          .data
      );
      setText_submit(
        xpath.select1("/resources/string[@name='text_submit']", doc).firstChild
          .data
      );
      setText_close(
        xpath.select1("/resources/string[@name='text_close']", doc).firstChild
          .data
      );
      setText_err_email(
        xpath.select1("/resources/string[@name='text_err_email']", doc)
          .firstChild.data
      );
      setText_err_valid_email(
        xpath.select1("/resources/string[@name='text_err_valid_email']", doc)
          .firstChild.data
      );
      setText_entermessage(
        xpath.select1("/resources/string[@name='text_entermessage']", doc)
          .firstChild.data
      );
      setText_ph_type_msg(
        xpath.select1("/resources/string[@name='text_ph_type_msg']", doc)
          .firstChild.data
      );
    } catch (ert) {}
  };
  return (
    <>
      <div>
        <button className="open-button" onClick={openForm}>
          <IoIosHelp className="supporticon" />
        </button>
        <div className="chat-popup" id="myForm">
          <div className="form-container">
            <h3>{text_help}?</h3>
            <label for="email" className="mt-2 fs-6">
              <b>{text_email}</b>
              <span className="starsty">*</span>
            </label>
            <input
              type="text"
              name=""
              id=""
              value={Email}
              placeholder={text_ph_email}
              required
              onChange={(e) => [setEmail(e.target.value), setEmailErr("")]}
            />
            <span className="starsty">{emailErr}</span>
            <br />
            <label for="msg" className="fs-6">
              <b>{text_message}</b>
              <span className="starsty">*</span>
            </label>
            <textarea
              placeholder={text_ph_type_msg}
              name="msg"
              value={Message}
              required
              onChange={(e) => [setMessage(e.target.value), setMessageErr("")]}
            ></textarea>
            <span className="starsty">{messageErr}</span>

            <button type="submit" className="btn mt-2" onClick={ContactHandle}>
              {text_submit}
            </button>
            <button type="button" className="btn cancel" onClick={closeForm}>
              {text_close}
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default SupportChat;

import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import OrgDashboardSidebar from "./OrgDashboardSidebar";
import { RxCross1 } from "react-icons/rx";
import { API_DOMAIN, GlobalConstants } from "../../utils/GlobalConst";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { IoIosSend } from "react-icons/io";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function OrgMyTeam() {
  const navigate = useNavigate();
  const userid = sessionStorage.getItem("userid");

  const [text_invite, setText_invite] = useState("Invite");
  const [text_TeamList, setText_TeamList] = useState("Team List");
  const [text_active, setText_active] = useState("Active");
  const [text_deactive, setText_deactive] = useState("Deactive");
  const [text_adminemail, setText_adminemail] = useState("Admin Email");

  const [text_resendInvite, setText_resendInvite] = useState("Resend Invite");
  const [text_removeAdmin, setText_removeAdmin] = useState("Remove Admin");
  const [text_resend, setText_resend] = useState("Resend");
  const [text_accepted, setText_accepted] = useState("Accepted");
  const [text_remove, setText_remove] = useState("Remove");
  const [text_pending, setText_pending] = useState("Pending");

  const [teamList, setTeamList] = useState([]);
  const getTeamDetails = () => {
    var API_URL = API_DOMAIN + `team/selectall?userid=${userid}`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        console.log("Team details", response);
        setTeamList(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  useEffect(() => {
    getTeamDetails();
  }, []);
  const resendInvite = (teamid, uid, teamname) => {
    var API_URL =
      API_DOMAIN +
      `team/resendverify?teamid=${teamid}&uid=${uid}&teamname=${teamname}`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        console.log("Resend Invite", response);
        if (response.status === 200) {
          toast.info("Email sent successfully!");
          getTeamDetails();
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  const removeAdmin = (teamid, uid) => {
    var API_URL = API_DOMAIN + `team/admindata?teamid=${teamid}`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    let data = {
      teamid: teamid,
      action: "active",
      payload: {
        value: "false",
        uid: uid,
      },
    };

    axios
      .put(API_URL, data, headerConfig)
      .then(function (response) {
        console.log("Remove Admin", response);
        toast.success("Admin Deactivated Successfully");
        getTeamDetails();
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  const ActivateTeam = (teamid) => {
    var API_URL = API_DOMAIN + `team/status/update`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    let data = {
      teamid: teamid,
      isteamactive: true,
    };

    axios
      .put(API_URL, data, headerConfig)
      .then(function (response) {
        console.log(response);
        if (response.data.status === true) {
          getTeamDetails();
        }
        toast.info("Team Activated Successfully");
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  const DeActivateTeam = (teamid) => {
    var API_URL = API_DOMAIN + `team/status/update`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    let data = {
      teamid: teamid,
      isteamactive: false,
    };

    axios
      .put(API_URL, data, headerConfig)
      .then(function (response) {
        console.log(response);
        if (response.data.status === true) {
          getTeamDetails();
        }
        toast.info("Team Deactivated Successfully");
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);

  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_active(
        xpath.select1("/resources/string[@name='text_active']", doc).firstChild
          .data
      );
      setText_deactive(
        xpath.select1("/resources/string[@name='text_deactive']", doc)
          .firstChild.data
      );
      setText_adminemail(
        xpath.select1("/resources/string[@name='text_adminemail']", doc)
          .firstChild.data
      );
      setText_remove(
        xpath.select1("/resources/string[@name='text_remove']", doc).firstChild
          .data
      );
      setText_accepted(
        xpath.select1("/resources/string[@name='text_accepted']", doc)
          .firstChild.data
      );
      setText_resend(
        xpath.select1("/resources/string[@name='text_resend']", doc).firstChild
          .data
      );
      setText_removeAdmin(
        xpath.select1("/resources/string[@name='text_removeAdmin']", doc)
          .firstChild.data
      );
      setText_resendInvite(
        xpath.select1("/resources/string[@name='text_resendInvite']", doc)
          .firstChild.data
      );
      setText_TeamList(
        xpath.select1("/resources/string[@name='text_TeamList']", doc)
          .firstChild.data
      );
      setText_invite(
        xpath.select1("/resources/string[@name='text_invite']", doc).firstChild
          .data
      );
      setText_pending(
              xpath.select1("/resources/string[@name='text_pending']", doc).firstChild
                .data
            );
    } catch (ert) {}
  };
  return (
    <>
      <Header />
      <OrgDashboardSidebar />

      <div id="otherPages">
        <div className="wrapper wrapper-body" id="wrapper-body">
          <div className="dashboard-body">
            <h2 className="text-center text-decoration-underline">
              <b>{text_TeamList}</b>
            </h2>

            <div className="container-fluid">
              {teamList.map((v, i) => {
                return (
                  <>
                    <div className="row my-3" key={i}>
                      <div className="col-md-12">
                        <div className="d-main-title">
                          <h3>
                            <i className="fa-solid fa-user-group me-3"></i>
                            {v?.teamname}{" "}
                            {v?.isteamactive === true ? (
                              <>
                                <button
                                  className="teamActiveSty"
                                  onClick={() => DeActivateTeam(v.teamid)}
                                >
                                  {text_active}
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  className="teamDeactiveSty"
                                  onClick={() => ActivateTeam(v.teamid)}
                                >
                                  {text_deactive}
                                </button>
                              </>
                            )}
                          </h3>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="conversion-setup">
                          <div className="tab-content">
                            <div
                              className="tab-pane fade active show"
                              id="overview-tab"
                              role="tabpanel"
                            >
                              <div className="table-card mt-2">
                                <div className="main-table">
                                  <div className="table-responsive">
                                    <table className="table">
                                      <thead className="thead-dark">
                                        <tr>
                                          <th scope="col">{text_adminemail}</th>
                                          <th scope="col">{text_invite}</th>
                                          <th scope="col">
                                            {text_resendInvite}
                                          </th>
                                          <th scope="col">
                                            {text_removeAdmin}
                                          </th>
                                        </tr>
                                      </thead>

                                      <tbody>
                                        {teamList[i].adminlist.map((admin) => {
                                          return (
                                            <>
                                              <tr>
                                                <td style={{ width: "400px" }}>
                                                  {admin.email}
                                                </td>
                                                <td
                                                  className={
                                                    admin.isaccept === false
                                                      ? "pendingsty"
                                                      : "acceptedsty"
                                                  }
                                                >
                                                  {admin.isaccept === false ? (
                                                    <>{text_pending}</>
                                                  ) : (
                                                    <>{text_accepted}</>
                                                  )}
                                                </td>
                                                <td
                                                  className={
                                                    admin.isaccept === false
                                                      ? ""
                                                      : "acceptedsty"
                                                  }
                                                >
                                                  {" "}
                                                  {admin.isaccept === false ||
                                                  admin.active === false ? (
                                                    <>
                                                      <button
                                                        onClick={() => [
                                                          resendInvite(
                                                            v.teamid,
                                                            admin.uid,
                                                            v.teamname
                                                          ),
                                                        ]}
                                                        className=" resendBtnSty"
                                                      >
                                                        {text_resend}{" "}
                                                        <IoIosSend />
                                                      </button>
                                                    </>
                                                  ) : (
                                                    <>{text_accepted}</>
                                                  )}
                                                </td>
                                                <td>
                                                  <button
                                                    onClick={() =>
                                                      removeAdmin(
                                                        v.teamid,
                                                        admin.uid
                                                      )
                                                    }
                                                    className="removeAdminBtn"
                                                    disabled={
                                                      admin.active === false
                                                        ? true
                                                        : false
                                                    }
                                                  >
                                                    {text_remove}
                                                  </button>
                                                </td>
                                              </tr>
                                            </>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default OrgMyTeam;

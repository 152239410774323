import React, { useState, useEffect } from "react";
import "../HomePage/HomePage.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import AllEventCard from "../AllEvent/AllEventCard";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link, useNavigate } from "react-router-dom";
import { FaBookmark, FaSearch } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import { API_DOMAIN, GlobalConstants } from "../../utils/GlobalConst";
import "react-toastify/dist/ReactToastify.css";
import venueEventsImg from "../../assets/img/icons/venue-events.png";
import webinarImg from "../../assets/img/icons/webinar.png";
import trainingImg from "../../assets/img/icons/training-workshop.png";
import onlineClassImg from "../../assets/img/icons/online-class.png";
import featurIcon1 from "../../assets/img/icons/feature-icon-1.png";
import featurIcon2 from "../../assets/img/icons/feature-icon-2.png";
import featurIcon3 from "../../assets/img/icons/feature-icon-3.png";
import PopularEvent from "../AllEvent/PopularEvent";
import UpcomingEvent from "../AllEvent/UpcomingEvent";
import moment from "moment";
import axios from "axios";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import img1 from "../../assets/img/event-imgs/big-1.jpg";
import { IoLocationOutline } from "react-icons/io5";
import { BsDot } from "react-icons/bs";
import { FiBookmark } from "react-icons/fi";
import NearMe from "../AllEvent/NearMe";
import { MdDateRange } from "react-icons/md";
import DatePicker from "react-datepicker";

const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function HomePage() {
  const [text_exploreevent, setText_exploreevent] = useState("Explore Events");
  const [test_foreventatt, setText_foreventatt] = useState(
    "For Event Attendees"
  );
  const [text_foreventorg, setText_foreventorg] = useState(
    "For Event Organizers"
  );
  const [text_noticehomepage, setText_noticehomepage] = useState(
    "Welcome to TiQ8.com "
  );

  const [text_noticehomepage2, setText_noticehomepage2] = useState(
    "Elevate Your Event Experience"
  );
  const [text_noticehomepage3, setText_noticehomepage3] = useState(
    "Power Your Events, Engage Your Audience"
  );
  const [text_noticehomepage4, setText_noticehomepage4] = useState(
    "Why Choose TiQ8.com"
  );
  const [text_noticehomepage5, setText_noticehomepage5] = useState(
    "Partner with TiQ8.com to take your events to new heights. Our platform offers not just ticket sales but a comprehensive suite of tools to enhance fan engagement and overall event success."
  );
  const [text_noticehomepage6, setText_noticehomepage6] = useState(
    "Fan Engagement Tools"
  );
  const [text_noticehomepage7, setText_noticehomepage7] = useState(
    "Utilize our innovative fan engagement tools to connect with your audience on a deeper level. From personalized promotions to interactive content, we provide the tools to turn attendees into lifelong fans."
  );
  const [text_noticehomepage8, setText_noticehomepage8] =
    useState("Expand Your Reach");
  const [text_noticehomepage9, setText_noticehomepage9] = useState(
    "Join the TiQ8.com family and expand your event's reach. Benefit from our growing user base and amplify your event's visibility to a broader audience."
  );
  const [text_title1, setText_title1] = useState("Discover Exclusive Events");
  const [text_title2, setText_title2] = useState(
    "Explore a diverse range of events, from thrilling sports matches to captivating performances, all in one place."
  );
  const [text_title3, setText_title3] = useState("Seamless Ticketing");
  const [text_title4, setText_title4] = useState(
    "Purchase tickets effortlessly and securely through our user-friendly platform"
  );
  const [text_title5, setText_title5] = useState("Unlock Digital Experiences");
  const [text_title6, setText_title6] = useState(
    "Each ticket opens the door to a world of personalized digital content, including behind-the-scenes access, virtual meet-and-greets, and more."
  );
  const [text_title7, setText_title7] = useState("Memorable Journeys Await");
  const [text_title8, setText_title8] = useState(
    "Elevate your event experience with TiQ8.com, where every ticket comes with an invitation to an unforgettable journey."
  );

  // new
  const [text_imageHead1, setText_imageHead1] = useState(
    "Your gateway to unforgettable experiences!"
  );
  const [text_imageHead2, setText_imageHead2] = useState(
    "Revolutionizing digital ticketing for sports and beyond."
  );
  const [text_search, setText_search] = useState("Search");
  const [text_phSearch, setText_phSearch] = useState("Search here");
  const [text_categories, setText_categories] = useState("Categories");
  const [text_popular, setText_popular] = useState("Popular");
  const [text_Upcoming, setText_Upcoming] = useState("Upcoming");
  const [text_nearMe, setText_nearMe] = useState("Near Me");
  const [text_all, setText_all] = useState("All");
  const [text_thisWeek, setText_thisWeek] = useState("This Week");
  const [text_calendarview, setText_calendarview] = useState("Calendar View");
  const [text_calendar, setText_calendar] = useState("Calendar");
  const [text_weekView, setText_weekView] = useState("Week view");
  const [text_viewMore, setText_viewMore] = useState("View More");
  const [text_upcomingevents, setText_upcomingevents] =
    useState("Upcoming Events");
  const [text_popularevents, setText_popularevents] =
    useState("Popular Events");
  const [text_location, setText_location] = useState("Location");

  const navigate = useNavigate();
  const [popularEventData, setPopularEventData] = useState(false);
  const [upcomingEventData, setUpcomingEventData] = useState(false);
  const [nearmeData, setNearmeData] = useState(false);
  const [events, setevents] = useState([]);
  const [monthview, setMonthview] = useState(false);
  const [weekview, setWeekview] = useState(true);
  const [allEventData, setAllEventData] = useState([]);
  const [allPopularEventData, setAllPopularEventData] = useState([]);
  const [indexBookmark, setIndexBookmark] = useState("");
  const [startDate, setStartDate] = useState(new Date());

  const sliderDetails = [
    {
      img: venueEventsImg,
      title: <>{text_title1}</>,
      para: <>{text_title2}</>,
    },
    {
      img: webinarImg,
      title: <>{text_title3}</>,
      para: <>{text_title4}</>,
    },
    {
      img: trainingImg,
      title: <>{text_title5}</>,
      para: <>{text_title6}</>,
    },
    {
      img: onlineClassImg,
      title: <>{text_title7}</>,
      para: <>{text_title8}</>,
    },
  ];

  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);

  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);

      setText_exploreevent(
        xpath.select1("/resources/string[@name='text_exploreevent']", doc)
          .firstChild.data
      );
      setText_foreventatt(
        xpath.select1("/resources/string[@name='test_foreventatt']", doc)
          .firstChild.data
      );
      setText_foreventorg(
        xpath.select1("/resources/string[@name='text_foreventorg']", doc)
          .firstChild.data
      );
      setText_noticehomepage(
        xpath.select1("/resources/string[@name='text_noticehomepage']", doc)
          .firstChild.data
      );
      setText_noticehomepage2(
        xpath.select1("/resources/string[@name='text_noticehomepage2']", doc)
          .firstChild.data
      );
      setText_noticehomepage3(
        xpath.select1("/resources/string[@name='text_noticehomepage3']", doc)
          .firstChild.data
      );
      setText_noticehomepage4(
        xpath.select1("/resources/string[@name='text_noticehomepage4']", doc)
          .firstChild.data
      );
      setText_noticehomepage5(
        xpath.select1("/resources/string[@name='text_noticehomepage5']", doc)
          .firstChild.data
      );
      setText_noticehomepage6(
        xpath.select1("/resources/string[@name='text_noticehomepage6']", doc)
          .firstChild.data
      );
      setText_noticehomepage7(
        xpath.select1("/resources/string[@name='text_noticehomepage7']", doc)
          .firstChild.data
      );

      setText_noticehomepage9(
        xpath.select1("/resources/string[@name='text_noticehomepage9']", doc)
          .firstChild.data
      );
      setText_title1(
        xpath.select1("/resources/string[@name='text_title1']", doc).firstChild
          .data
      );
      setText_title2(
        xpath.select1("/resources/string[@name='text_title2']", doc).firstChild
          .data
      );
      setText_title3(
        xpath.select1("/resources/string[@name='text_title3']", doc).firstChild
          .data
      );
      setText_title4(
        xpath.select1("/resources/string[@name='text_title4']", doc).firstChild
          .data
      );
      setText_title5(
        xpath.select1("/resources/string[@name='text_title5']", doc).firstChild
          .data
      );
      setText_title6(
        xpath.select1("/resources/string[@name='text_title6']", doc).firstChild
          .data
      );
      setText_title7(
        xpath.select1("/resources/string[@name='text_title7']", doc).firstChild
          .data
      );
      setText_title8(
        xpath.select1("/resources/string[@name='text_title8']", doc).firstChild
          .data
      );
      setText_noticehomepage8(
        xpath.select1("/resources/string[@name='text_noticehomepage8']", doc)
          .firstChild.data
      );
      setText_imageHead1(
        xpath.select1("/resources/string[@name='text_imageHead1']", doc)
          .firstChild.data
      );
      setText_imageHead2(
        xpath.select1("/resources/string[@name='text_imageHead2']", doc)
          .firstChild.data
      );
      setText_search(
        xpath.select1("/resources/string[@name='text_search']", doc).firstChild
          .data
      );
      setText_phSearch(
        xpath.select1("/resources/string[@name='text_phSearch']", doc)
          .firstChild.data
      );
      setText_categories(
        xpath.select1("/resources/string[@name='text_categories']", doc)
          .firstChild.data
      );
      setText_popular(
        xpath.select1("/resources/string[@name='text_popular']", doc).firstChild
          .data
      );
      setText_Upcoming(
        xpath.select1("/resources/string[@name='text_Upcoming']", doc)
          .firstChild.data
      );
      setText_nearMe(
        xpath.select1("/resources/string[@name='text_nearMe']", doc).firstChild
          .data
      );
      setText_all(
        xpath.select1("/resources/string[@name='text_all']", doc).firstChild
          .data
      );
      setText_thisWeek(
        xpath.select1("/resources/string[@name='text_thisWeek']", doc)
          .firstChild.data
      );
      setText_calendarview(
        xpath.select1("/resources/string[@name='text_calendarview']", doc)
          .firstChild.data
      );
      setText_calendar(
        xpath.select1("/resources/string[@name='text_calendar']", doc)
          .firstChild.data
      );
      setText_weekView(
        xpath.select1("/resources/string[@name='text_weekView']", doc)
          .firstChild.data
      );
      setText_viewMore(
        xpath.select1("/resources/string[@name='text_viewMore']", doc)
          .firstChild.data
      );
      setText_upcomingevents(
        xpath.select1("/resources/string[@name='text_upcomingevents']", doc)
          .firstChild.data
      );
      setText_popularevents(
        xpath.select1("/resources/string[@name='text_popularevents']", doc)
          .firstChild.data
      );
      setText_location(
        xpath.select1("/resources/string[@name='text_location']", doc)
          .firstChild.data
      );
    } catch (ert) {}
  };
  const handleDatesSet = (dateInfo) => {
    const startDate = new Date(dateInfo.start); // Start of visible range
    const formattedMonth = moment(startDate).format("MM YYYY"); // Format for the API
    console.log(formattedMonth);
    var type = "selectall";

    const url = API_DOMAIN + "event/public?type=" + type;
    console.log(url);

    getCalendarData(url);
    console.log("helllooooo");
  };
  const getCalendarData = (url) => {
    var API_URL = url;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    axios
      .get(API_URL, headerConfig)
      .then((response) => {
        console.log("month wise events ", response.data.data.event);

        var res = response.data.data.event;
        const formattedEvents = res.map((event) => ({
          id: event.event_unique_id,
          img: event.EventImagePath,
          title: event.EventTitle, // Adjust based on your API response
          start: event.StartDate, // Ensure date is in ISO 8601 format
          end: event.EndDate,
          starttime: event.StartTime, // Ensure date is in ISO 8601 format
          endtime: event.EndTime, // Optional
          address2: ` ${event.venuedetails[0].state},${event.venuedetails[0].country},${event.venuedetails[0].zipcode}`,
          url: `/event-details/${event.event_unique_id}`,
        }));
        console.log(formattedEvents.address);

        setevents(formattedEvents);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  const handleEventClick = (info) => {
    info.jsEvent.preventDefault(); // Prevent default behavior
    const eventUrl = info.event.url; // Access the custom URL
    console.log(eventUrl);

    if (eventUrl) {
      navigate(eventUrl); // Redirect to the event details page
    }
  };

  const getUpcomingEvent = () => {
    var API_URL = API_DOMAIN + "event/get/futureevent";
    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    axios
      .get(API_URL, headerConfig)
      .then((response) => {
        console.log("upcomingevents ", response.data.data.event);
        var res = response.data.data;
        const sortdata = res.event;
        sortdata.sort(
          (a, b) => new Date(a?.StartDateSort) - new Date(b?.StartDateSort)
        );
        console.log("sortdata", sortdata);
        setAllEventData(sortdata);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  const getPopularEvents = () => {
    var API_URL = API_DOMAIN + "event/get/popularevent";
    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    axios
      .get(API_URL, headerConfig)
      .then((response) => {
        console.log("popularevent ", response.data.data.event);
        var res = response.data.data;
        const sortdata = res.event;
        sortdata.sort(
          (a, b) => new Date(a?.StartDateSort) - new Date(b?.StartDateSort)
        );
        console.log("sortdata", sortdata);
        setAllPopularEventData(sortdata);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };
  useEffect(() => {
    getUpcomingEvent();
    getPopularEvents();
    if (sessionStorage.getItem("token") !== null) {
      console.log("in if", sessionStorage.getItem("token"));
      getBookmarkedEvents();
    }
  }, []);

  const AddBookmarkHandle = (eveID, eveData) => {
    var API_URL = API_DOMAIN + `event/bookmark`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    var data = {
      event_unique_id: eveID,
      seat_unique_id: "seat_unique_id",
      seat_category_id: "seat_category_id",
      isbookmark: true,
      event_details: [eveData],
    };
    axios
      .post(API_URL, data, headerConfig)
      .then(function (response) {
        console.log(response);
        if (response.data.message === "Bookmark created") {
          getBookmarkedEvents();
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  const RemoveBookmarkHandle = (eveID) => {
    var API_URL = API_DOMAIN + `event/uncheck/bookmark`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    var data = {
      event_unique_id: eveID,
      seat_unique_id: "seat_unique_id",
      seat_category_id: "seat_category_id",
      isbookmark: false,
    };
    axios
      .patch(API_URL, data, headerConfig)
      .then(function (response) {
        console.log("Remove", response);
        if (response.data.message === "Bookmark updated") {
          getBookmarkedEvents();
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  const [bookmaredEventIdList, setBookmaredEventIdList] = useState([]);

  const getBookmarkedEvents = () => {
    var API_URL = API_DOMAIN + `event/get/bookmarks`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        // var res = response?.data?.data?.event;
        console.log("Bookmarked Events", response.data.data);
        setBookmaredEventIdList(response.data.data);
        var res = response.data.data;
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  const isBookmarked = (eventId) => {
    return bookmaredEventIdList.some(
      (event) => event.event_unique_id === eventId
    );
  };
  return (
    <>
      <Header />
      <div className="wrapper-home" id="otherPages">
        <div className="hero-banner-home">
          <div className="container">
            <div className="row justify-content-start">
              <div className="">
                <div className="hero-banner-content">
                  <h2 className="hometextnight text-start">
                    {text_noticehomepage}
                  </h2>
                  <p className="text-start mx-1 res-home-banner-text">
                    {text_imageHead1}
                    <br />
                    {text_imageHead2}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="home-search container">
          <div className="inputGroup">
            {/* Location Input */}
            <div className="inputWrapper gap-2">
              <span className="icon">
                {" "}
                <IoLocationOutline />
              </span>
              <input type="text" placeholder={text_location} className="" />
            </div>

            {/* Date Input */}
            <div className="inputWrapper gap-2">
              <span className="icon">
                <MdDateRange />
              </span>
              <DatePicker
                className=" mx-1 "
                selected={startDate}
                dateFormat="dd MMM,yyyy"
                onChange={(date) => setStartDate(date)}
              />
            </div>

            {/* Search Button */}
            <div className="d-flex justify-content-between w-100 home-res-search">
              <div className="d-flex">
                <span className="px-2 icon">
                  <FaSearch className="mt-3" />
                </span>
                <input
                  type="text"
                  className="search-input-home"
                  placeholder={`${text_phSearch}...`}
                />
              </div>
              <button>
                <span className="home-search-btn">{text_search}</span>
              </button>
            </div>
          </div>
        </div>
        <div className="explore-events p-80">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="event-filter-items">
                  <h3>{text_categories}</h3>
                  <div className="featured-controls">
                    <div className="d-flex flex-wrap gap-3">
                      <button
                        className={
                          popularEventData === true
                            ? "home-main-tab selected"
                            : "home-main-tab "
                        }
                        onClick={() => [
                          setPopularEventData(true),
                          setUpcomingEventData(false),
                          setNearmeData(false),
                        ]}
                      >
                        {text_popular}
                      </button>
                      <button
                        className={
                          upcomingEventData === true
                            ? "home-main-tab selected"
                            : "home-main-tab "
                        }
                        onClick={() => [
                          setPopularEventData(false),
                          setUpcomingEventData(true),
                          setNearmeData(false),
                        ]}
                      >
                        {text_Upcoming}
                      </button>
                      <button
                        className={
                          nearmeData === true
                            ? "home-main-tab selected"
                            : "home-main-tab "
                        }
                        onClick={() => [
                          setNearmeData(true),
                          setPopularEventData(false),
                          setUpcomingEventData(false),
                        ]}
                      >
                        {text_nearMe}
                      </button>
                      <button className="home-main-tab ">
                        Croatian League
                      </button>
                      <button className="home-main-tab ">ABA League</button>
                      <button className="home-main-tab ">{text_all}</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12 mt-4">
                {popularEventData === true ? (
                  <>
                    <PopularEvent />
                  </>
                ) : (
                  <>
                    {upcomingEventData === true ? (
                      <>
                        <UpcomingEvent />
                      </>
                    ) : (
                      <>
                        {nearmeData === true ? (
                          <>
                            <NearMe />
                          </>
                        ) : (
                          <>
                            <div className="d-flex justify-content-between">
                              {monthview === false ? (
                                <>
                                  <div className="main-title">
                                    <h3 className="">{text_thisWeek}</h3>
                                  </div>
                                  <h5
                                    className="text-decoration-underline home-cal-text"
                                    onClick={() => [
                                      setMonthview(true),
                                      setWeekview(false),
                                    ]}
                                  >
                                    {text_calendarview}{" "}
                                  </h5>
                                </>
                              ) : (
                                <>
                                  <div className="main-title">
                                    <h3 className="">{text_calendar}</h3>
                                  </div>

                                  <h5
                                    className="text-decoration-underline home-cal-text"
                                    onClick={() => [
                                      setWeekview(true),
                                      setMonthview(false),
                                    ]}
                                  >
                                    {text_weekView}
                                  </h5>
                                </>
                              )}
                            </div>

                            {monthview === true ? (
                              <>
                                <div className="month-calendar-style">
                                  <div>
                                    <FullCalendar
                                      timeZone="UTC"
                                      plugins={[
                                        dayGridPlugin,
                                        interactionPlugin,
                                      ]}
                                      initialView="dayGridMonth"
                                      events={events}
                                      eventClick={handleEventClick}
                                      datesSet={handleDatesSet}
                                      dayHeaderContent={(args) => {
                                        // Format weekdays as 'Mon', 'Tue', etc.
                                        return new Intl.DateTimeFormat("en", {
                                          weekday: "short",
                                        }).format(args.date);
                                      }}
                                      dayCellContent={(args) => {
                                        // Format and display only the day number
                                        return args.date.getDate(); // Get only the day (e.g., 1, 2, 3, etc.)
                                      }}
                                      weekends={true} // Show weekends
                                      eventContent={(eventInfo) => (
                                        <div className="calendar-event">
                                          <h4 className="calendar-event-title">
                                            {eventInfo.event.title}
                                            <br />
                                            <p>
                                              {" "}
                                              {moment(
                                                eventInfo.event.starttime
                                              ).format("hh:mm A")}
                                            </p>
                                          </h4>
                                        </div>
                                      )}
                                    />
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="home-calendar new-home-calendar">
                                  <FullCalendar
                                    timeZone="UTC"
                                    plugins={[dayGridPlugin, interactionPlugin]}
                                    initialView="dayGridWeek"
                                    events={events}
                                    eventClick={handleEventClick}
                                    datesSet={handleDatesSet}
                                    dayHeaderContent={(args) => {
                                      // Format weekdays as 'Mon', 'Tue', etc.
                                      return new Intl.DateTimeFormat("en", {
                                        weekday: "short",
                                      }).format(args.date);
                                    }}
                                    dayCellContent={(args) => {
                                      // Format and display only the day number
                                      return args.date.getDate(); // Get only the day (e.g., 1, 2, 3, etc.)
                                    }}
                                    weekends={true} // Show weekends
                                    eventContent={(eventInfo) => (
                                      <div className=" new-calendar-event">
                                        {eventInfo.event.extendedProps.img ===
                                        "path" ? (
                                          <>
                                            <img
                                              src={img1}
                                              alt=""
                                              className="thumbnail-img calendar-img"
                                            />
                                          </>
                                        ) : (
                                          <>
                                            <img
                                              src={
                                                eventInfo.event.extendedProps
                                                  .img
                                              }
                                              alt=""
                                              className="thumbnail-img calendar-img"
                                            />
                                          </>
                                        )}
                                        <div id="calendar-date"></div>
                                        <h4 className="calendar-event-title mt-1">
                                          {eventInfo.event.title}
                                        </h4>
                                        <p>
                                          {" "}
                                          {moment(
                                            eventInfo.event.starttime
                                          ).format("hh:mm A")}
                                          <br />
                                          {
                                            eventInfo.event.extendedProps
                                              .address2
                                          }
                                        </p>
                                      </div>
                                    )}
                                  />
                                </div>
                              </>
                            )}
                            <div className="d-flex justify-content-between mt-5">
                              <div className="main-title ">
                                <h3>{text_exploreevent}</h3>
                              </div>
                              <h5
                                className="text-decoration-underline home-cal-text mt-3"
                                onClick={() => navigate("/all-event")}
                              >
                                {text_viewMore}
                              </h5>
                            </div>
                            <AllEventCard />
                            <div className="d-flex justify-content-between mt-4">
                              <div className="main-title ">
                                <h3>{text_upcomingevents}</h3>
                              </div>
                              <h5
                                className="text-decoration-underline home-cal-text mt-3"
                                onClick={() => setUpcomingEventData(true)}
                              >
                                {text_viewMore}
                              </h5>
                            </div>
                            <div className="scroll_comp">
                              {allEventData.length > 0 &&
                                allEventData.map((e, index) => {
                                  return (
                                    <>
                                      <div
                                        className=" mix arts concert workshops volunteer sports health_Wellness"
                                        data-ref="mixitup-target"
                                        id={index}
                                      >
                                        <div className="main-card home-scroll mt-4">
                                          <span
                                            className="bookmark-icon bookmark-content"
                                            title=""
                                            onClick={() => [
                                              setIndexBookmark(index),
                                            ]}
                                          >
                                            {isBookmarked(e.event_unique_id) ? (
                                              <>
                                                <FaBookmark
                                                  className="bookmarkicon-fill"
                                                  onClick={() =>
                                                    RemoveBookmarkHandle(
                                                      e?.event_unique_id
                                                    )
                                                  }
                                                />
                                              </>
                                            ) : (
                                              <>
                                                <FiBookmark
                                                  className="bookmarkicon"
                                                  onClick={() =>
                                                    AddBookmarkHandle(
                                                      e?.event_unique_id,
                                                      e
                                                    )
                                                  }
                                                />
                                              </>
                                            )}
                                          </span>
                                          <div
                                            className="event-thumbnail"
                                            onClick={() =>
                                              navigate(
                                                `/event-details/${e?.event_unique_id}`
                                              )
                                            }
                                          >
                                            <Link
                                              to={`/event-details/${e?.event_unique_id}`}
                                              className="thumbnail-img"
                                            >
                                              <img
                                                src={
                                                  e.EventImagePath === "path"
                                                    ? img1
                                                    : e.EventImagePath
                                                }
                                                alt=""
                                              />
                                            </Link>
                                          </div>
                                          <div className="event-content">
                                            <Link
                                              to={`/event-details/${e?.event_unique_id}`}
                                              className="event-title event-css"
                                            >
                                              {e.EventTitle}
                                            </Link>
                                            <div className="publish-date mb-2">
                                              <span>
                                                {moment
                                                  .utc(e?.StartDate)
                                                  .format("DD MMM, yyyy")}
                                                &nbsp;
                                              </span>
                                              <span className="dot dot-size-allevent">
                                                <BsDot
                                                  style={{
                                                    width: "23px",
                                                    height: "23px",
                                                  }}
                                                />
                                              </span>
                                              <span>
                                                {moment
                                                  .utc(e.StartDate)
                                                  .format("ddd")}
                                                ,{" "}
                                                {moment
                                                  .utc(e?.StartTime)
                                                  .format("hh:mm a")}{" "}
                                              </span>
                                            </div>
                                            <div className="duration-price-remaining">
                                              <IoLocationOutline className="location-icon" />
                                              <span>
                                                {e?.venuedetails[0].venuename} ({" "}
                                                {e?.venuedetails[0].address},{" "}
                                                {e?.venuedetails[0].city},{" "}
                                                {e?.venuedetails[0].state},{" "}
                                                {e?.venuedetails[0].country},{" "}
                                                {e?.venuedetails[0].zipcode})
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>{" "}
                                    </>
                                  );
                                })}
                            </div>
                            <div className="d-flex justify-content-between mt-4">
                              <div className="main-title ">
                                <h3>{text_popularevents}</h3>
                              </div>
                              <h5
                                className="text-decoration-underline home-cal-text mt-3"
                                onClick={() => setPopularEventData(true)}
                              >
                                {text_viewMore}
                              </h5>
                            </div>
                            <div className="scroll_comp">
                              {allPopularEventData.length > 0 &&
                                allPopularEventData.map((e, index) => {
                                  return (
                                    <>
                                      <div
                                        className="mix arts concert workshops volunteer sports health_Wellness"
                                        data-ref="mixitup-target"
                                        id={index}
                                      >
                                        <div className="main-card home-scroll mt-4">
                                          <span
                                            className="bookmark-icon bookmark-content"
                                            title=""
                                            onClick={() => [
                                              setIndexBookmark(index),
                                            ]}
                                          >
                                            {isBookmarked(e.event_unique_id) ? (
                                              <>
                                                <FaBookmark
                                                  className="bookmarkicon-fill"
                                                  onClick={() =>
                                                    RemoveBookmarkHandle(
                                                      e?.event_unique_id
                                                    )
                                                  }
                                                />
                                              </>
                                            ) : (
                                              <>
                                                <FiBookmark
                                                  className="bookmarkicon"
                                                  onClick={() =>
                                                    AddBookmarkHandle(
                                                      e?.event_unique_id,
                                                      e
                                                    )
                                                  }
                                                />
                                              </>
                                            )}
                                          </span>
                                          <div
                                            className="event-thumbnail"
                                            onClick={() =>
                                              navigate(
                                                `/event-details/${e?.event_unique_id}`
                                              )
                                            }
                                          >
                                            <Link
                                              to={`/event-details/${e?.event_unique_id}`}
                                              className="thumbnail-img"
                                            >
                                              <img
                                                src={
                                                  e.EventImagePath === "path"
                                                    ? img1
                                                    : e.EventImagePath
                                                }
                                                alt=""
                                              />
                                            </Link>
                                          </div>
                                          <div className="event-content">
                                            <Link
                                              to={`/event-details/${e?.event_unique_id}`}
                                              className="event-title event-css"
                                            >
                                              {e.EventTitle}
                                            </Link>
                                            <div className="publish-date mb-2">
                                              <span>
                                                {moment
                                                  .utc(e?.StartDate)
                                                  .format("DD MMM, yyyy")}
                                                &nbsp;
                                              </span>
                                              <span className="dot dot-size-allevent">
                                                <BsDot
                                                  style={{
                                                    width: "23px",
                                                    height: "23px",
                                                  }}
                                                />
                                              </span>
                                              <span>
                                                {moment
                                                  .utc(e.StartDate)
                                                  .format("ddd")}
                                                ,{" "}
                                                {moment
                                                  .utc(e?.StartTime)
                                                  .format("hh:mm a")}{" "}
                                              </span>
                                            </div>
                                            <div className="duration-price-remaining">
                                              <IoLocationOutline className="location-icon" />
                                              <span>
                                                {e?.venuedetails[0].venuename} ({" "}
                                                {e?.venuedetails[0].address},{" "}
                                                {e?.venuedetails[0].city},{" "}
                                                {e?.venuedetails[0].state},{" "}
                                                {e?.venuedetails[0].country},{" "}
                                                {e?.venuedetails[0].zipcode})
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>{" "}
                                    </>
                                  );
                                })}
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="host-engaging-event-block p-80">
          <div className="container">
            <div className="row">
              <div className="col-lg-10">
                <div className="main-title">
                  <h3>{test_foreventatt}:</h3>
                  <p>{text_noticehomepage2}</p>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="engaging-block">
                  <OwlCarousel className="owl-theme" loop margin={10} nav>
                    {sliderDetails.map((e) => (
                      <div className="item">
                        <div className="main-card attendees-card">
                          <div className="host-item">
                            <div className="host-img">
                              <img src={e.img} alt="" />
                            </div>
                            <h4>{e.title}</h4>
                            <p>{e.para}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="feature-block p-80">
          <div className="container">
            <div className="row">
              <div className="col-lg-10">
                <div className="main-title">
                  <h3>{text_foreventorg}:</h3>
                  <p></p>
                </div>
                {text_noticehomepage3}
              </div>
              <div className="col-lg-12">
                <div className="feature-group-list">
                  <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-6">
                      <div className="feature-item mt-46">
                        <div className="feature-icon">
                          <img src={featurIcon1} alt="" />
                        </div>
                        <h4>{text_noticehomepage4}:</h4>
                        <p>{text_noticehomepage5}</p>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6">
                      <div className="feature-item mt-46">
                        <div className="feature-icon">
                          <img src={featurIcon2} alt="" />
                        </div>
                        <h4>{text_noticehomepage6}:</h4>
                        <p>{text_noticehomepage7}</p>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6">
                      <div className="feature-item mt-46">
                        <div className="feature-icon">
                          <img src={featurIcon3} alt="" />
                        </div>
                        <h4>{text_noticehomepage8}:</h4>
                        <p>{text_noticehomepage9}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </>
  );
}

export default HomePage;

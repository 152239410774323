import React, { useEffect, useState } from "react";
import OrgDashboardSidebar from "./OrgDashboardSidebar";
import Header from "../Header/Header";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import axios from "axios";
import { API_DOMAIN, GlobalConstants } from "../../utils/GlobalConst";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import img1 from "../../assets/img/event-imgs/big-1.jpg";
import moment from "moment";
import { IoLocationOutline } from "react-icons/io5";
import { BsDot } from "react-icons/bs";
import { CiCircleCheck } from "react-icons/ci";
import { IoIosCloseCircleOutline, IoMdRefresh } from "react-icons/io";
import QrReader from "modern-react-qr-reader";
import { Box, Divider, Modal } from "@mui/material";
import { MdVerified } from "react-icons/md";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function VerifyTickets() {
  const navigate = useNavigate();
  const [eventData, setEventData] = useState([]);

  const [text_orgreport_refresh, setText_orgreport_refresh] =
    useState("Refresh");
  const [text_cancel, setText_cancel] = useState("Cancel");
  // new
  const [text_checkInEvent, setText_checkInEvent] =
    useState("Check In Tickets");
  const [text_tickNum, setText_tickNum] = useState("Ticket Number");

  const [text_ph_tickNum, setText_ph_tickNum] = useState("Enter Ticket Number");
  const [text_checkIn, setText_checkIn] = useState("Check In");
  const [text_selectevent, setText_selectevent] = useState("Select Event");

  const [selectedEventData, setSelectedEventData] = useState("");
  const [eventSelected, setEventSelected] = useState(false);
  const [seatUniqueID, setSeatUniqueID] = useState("");
  const [eventUniqueID, setEventUniqueID] = useState("");
  const [Loading, setLoading] = useState(false);
  const [scanSuccess, setScanSuccess] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const handleOpenPopup = () => setOpenPopup(true);
  const handleClosePopup = () => [
    setOpenPopup(false),
    setScanSuccess(false),
    setLoading(false),
    setSeatUniqueID(""),
    setScannedSeatID(""),
    setScannedEventID(""),
    setScannedEventName(""),
    setScannedEventDate(""),
    setScannedEventTime(""),
    setScannedSeat(""),
    setScannedZone(""),
  ];
  const styleTwo = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    borderRadius: "20px",
  };
  const getEventsCard = () => {
    var type = "selectall";
    var API_URL = API_DOMAIN + "event/my?type=" + type;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        var res = response?.data?.data;
        console.log(res);
        setEventData(res);
      })
      .catch(function (error) {
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  useEffect(() => getEventsCard(), []);
  const selectEventHandle = (selectedEveID) => {
    const arr = eventData.filter((e) => e?.event_unique_id === selectedEveID);
    console.log(arr);
    setSelectedEventData(arr);
    setEventUniqueID(selectedEveID);
    setEventSelected(true);
  };
  const verifySeat = (eventId, seatId) => {
    setLoading(true);
    var API_URL = API_DOMAIN + "seat/admin/status";

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    var data = {
      seat_unique_id: seatId,
      event_unique_id: eventId,
      isentry: true,
    };
    axios
      .put(API_URL, data, headerConfig)
      .then(function (response) {
        console.log("verify", response);
        if (response.data.message === "seat status updated") {
          setScanSuccess(true);
          toast.success("Check In Successfull");
          setTimeout(() => {
            handleClosePopup();
          }, 5000);
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          if (error.response.data.message === "user already entered") {
            handleClosePopup();
          }
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  const [scannedSeatID, setScannedSeatID] = useState("");
  const [scannedEventID, setScannedEventID] = useState("");
  const [scannedEventName, setScannedEventName] = useState("");
  const [scannedEventDate, setScannedEventDate] = useState("");
  const [scannedEventTime, setScannedEventTime] = useState("");
  const [scannedZone, setScannedZone] = useState("");
  const [scannedSeat, setScannedSeat] = useState("");
  const [disableCheckIn, setDisableCheckIn] = useState(false);
  const [showEventErrorMsg, setShowEventErrorMsg] = useState(false);
  const handleScan = (data) => {
    if (data) {
      console.log(data);
      // Regular expressions to extract Seat ID and Event ID
      const seatIdMatch = data.match(/Seat ID:-- ([a-f0-9-]+)/);
      const eventIdMatch = data.match(/Event ID:-- ([a-f0-9-]+)/);
      const eventNameMatch = data.match(/Event Name:-- ([A-Za-z0-9\s]+)/);
      const seatMatch = data.match(/Seat:-- ([A-Za-z0-9_-]+)/);
      const zoneMatch = data.match(/zone:-- ([A-Za-z\s]+)/);
      const eventDateMatch = data.match(/Event Date:-- ([\d-T:.Z]+)/);
      const eventTimeMatch = data.match(/Event Time:-- ([\d-T:.Z]+)/);

      // Extract only the IDs
      const seatId = seatIdMatch ? seatIdMatch[1] : null;
      const eventId = eventIdMatch ? eventIdMatch[1] : null;
      const eventName = eventNameMatch ? eventNameMatch[1].trim() : null;
      const seat = seatMatch ? seatMatch[1] : null;
      const zone = zoneMatch ? zoneMatch[1].trim() : null;
      const eventDate = eventDateMatch ? eventDateMatch[1] : null;
      const eventTime = eventTimeMatch ? eventTimeMatch[1] : null;
      if (eventId === eventUniqueID) {
        setScannedSeatID(seatId);
        setScannedEventID(eventId);
        setScannedEventName(eventName);
        setScannedEventDate(eventDate);
        setScannedEventTime(eventTime);
        setScannedSeat(seat);
        setScannedZone(zone);

        handleOpenPopup();
        setDisableCheckIn(false);
        setShowEventErrorMsg(false);
      } else {
        handleOpenPopup();
        setDisableCheckIn(true);
        setScannedSeatID(seatId);
        setScannedEventID(eventId);
        setScannedEventName(eventName);
        setScannedEventDate(eventDate);
        setScannedEventTime(eventTime);
        setScannedSeat(seat);
        setScannedZone(zone);

        setShowEventErrorMsg(true);
      }
      console.log("Seat ID:", seatId);
      console.log("Event ID:", eventId);
      // Close the scanner after a successful scan
    }
  };

  const handleError = (err) => {
    console.error(err);
  };
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);
  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);

      setText_orgreport_refresh(
        xpath.select1("/resources/string[@name='text_orgreport_refresh']", doc)
          .firstChild.data
      );
      setText_cancel(
        xpath.select1("/resources/string[@name='text_cancel']", doc).firstChild
          .data
      );
      setText_checkInEvent(
        xpath.select1("/resources/string[@name='text_checkInEvent']", doc).firstChild
          .data
      );
      setText_tickNum(
        xpath.select1("/resources/string[@name='text_tickNum']", doc).firstChild
          .data
      );
      setText_ph_tickNum(
        xpath.select1("/resources/string[@name='text_ph_tickNum']", doc).firstChild
          .data
      );
      setText_checkIn(
        xpath.select1("/resources/string[@name='text_checkIn']", doc).firstChild
          .data
      );
      setText_selectevent(
        xpath.select1("/resources/string[@name='text_selectevent']", doc).firstChild
          .data
      );
    } catch (ert) {}
  };
  return (
    <>
      <Header />
      <OrgDashboardSidebar />
      <div id="otherPages">
        <div className="wrapper wrapper-body" id="wrapper-body">
          <div className="dashboard-body">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-main-title">
                    <h3>
                      <RiVerifiedBadgeFill className="me-3" />
                      {text_checkInEvent}{" "}
                      {selectedEventData.length > 0 ? (
                        <>- {selectedEventData[0]?.EventTitle}</>
                      ) : (
                        <></>
                      )}
                    </h3>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="main-card mt-5 p-4">
                    <div className="conversion-setup">
                      <div className="contact-list">
                        <div className="row">
                          {eventSelected ? (
                            <>
                              {selectedEventData.length > 0 &&
                                selectedEventData.map((e, index) => {
                                  return (
                                    <>
                                      <div
                                        className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mix arts concert workshops volunteer sports health_Wellness"
                                        data-ref="mixitup-target"
                                        id={index}
                                      >
                                        <div className="main-card mt-4">
                                          <div className="event-thumbnail">
                                            <Link
                                              to={`/event-details/${e?.event_unique_id}`}
                                              className="thumbnail-img"
                                            >
                                              <img
                                                src={
                                                  e.EventImagePath === "path"
                                                    ? img1
                                                    : e.EventImagePath
                                                }
                                                alt=""
                                              />
                                            </Link>
                                          </div>
                                          <div className="event-content">
                                            <Link
                                              to={`/event-details/${e?.event_unique_id}`}
                                              className="event-title event-css"
                                            >
                                              {e.EventTitle}
                                            </Link>
                                            <div className="publish-date mb-2">
                                              <span>
                                                {moment
                                                  .utc(e?.StartDate)
                                                  .format("DD MMM, yyyy")}
                                                &nbsp;
                                              </span>
                                              <span className="dot dot-size-allevent">
                                                <BsDot
                                                  style={{
                                                    width: "23px",
                                                    height: "23px",
                                                  }}
                                                />
                                              </span>
                                              <span>
                                                {moment
                                                  .utc(e.StartDate)
                                                  .format("ddd")}
                                                ,{" "}
                                                {moment
                                                  .utc(e?.StartTime)
                                                  .format("hh:mm a")}{" "}
                                              </span>
                                            </div>
                                            <div className="duration-price-remaining">
                                              <IoLocationOutline className="location-icon" />
                                              <span>
                                                {e?.venuedetails[0].venuename} ({" "}
                                                {e?.venuedetails[0].address},{" "}
                                                {e?.venuedetails[0].city},{" "}
                                                {e?.venuedetails[0].state},{" "}
                                                {e?.venuedetails[0].country},{" "}
                                                {e?.venuedetails[0].zipcode})
                                              </span>
                                            </div>

                                            <button
                                              className="sv-btn w-75 mt-4 "
                                              onClick={() => [
                                                setEventSelected(false),
                                                setSelectedEventData([]),
                                              ]}
                                            >
                                              <IoMdRefresh className="icon me-2" />
                                              {text_orgreport_refresh}
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="main-card mt-2">
                                        <div className="p-4 bp-form main-form">
                                          <div className="row p-4">
                                            <div className="col-lg-4 col-md-12 mb-3">
                                              <div className="form-group border_bottom-create profile-night-form pb_30">
                                                <label className="form-label fs-16">
                                                  {text_tickNum}
                                                </label>
                                                <input
                                                  className="form-control h_50 inputstylenightmode"
                                                  type="text"
                                                  placeholder={text_ph_tickNum}
                                                  onChange={(e) =>
                                                    setSeatUniqueID(
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </div>
                                              <div className="d-flex justify-content-center gap-4 mt-5">
                                                <button
                                                  className="cancelBtn"
                                                  onClick={() => [
                                                    setEventSelected(false),
                                                    selectedEventData([]),
                                                  ]}
                                                >
                                                  {text_cancel}
                                                </button>{" "}
                                                <button className="saveBtn">
                                                  {text_checkIn}
                                                </button>
                                              </div>
                                            </div>
                                            <div className="col-lg-4 col-md-12">
                                              <QrReader
                                                delay={300}
                                                onError={handleError}
                                                onScan={handleScan}
                                                style={{ width: "100%" }}
                                              />
                                            </div>
                                          </div>

                                          <br />
                                        </div>
                                      </div>
                                    </>
                                  );
                                })}
                            </>
                          ) : (
                            <>
                              {eventData.length > 0 &&
                                eventData.map((e, index) => {
                                  return (
                                    <>
                                      <div
                                        className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mix arts concert workshops volunteer sports health_Wellness"
                                        data-ref="mixitup-target"
                                        id={index}
                                      >
                                        <div className="main-card mt-4">
                                          <div className="event-thumbnail">
                                            <Link
                                              to={`/event-details/${e?.event_unique_id}`}
                                              className="thumbnail-img"
                                            >
                                              <img
                                                src={
                                                  e.EventImagePath === "path"
                                                    ? img1
                                                    : e.EventImagePath
                                                }
                                                alt=""
                                              />
                                            </Link>
                                          </div>
                                          <div className="event-content">
                                            <Link
                                              to={`/event-details/${e?.event_unique_id}`}
                                              className="event-title event-css"
                                            >
                                              {e.EventTitle}
                                            </Link>
                                            <div className="publish-date mb-2">
                                              <span>
                                                {moment
                                                  .utc(e?.StartDate)
                                                  .format("DD MMM, yyyy")}
                                                &nbsp;
                                              </span>
                                              <span className="dot dot-size-allevent">
                                                <BsDot
                                                  style={{
                                                    width: "23px",
                                                    height: "23px",
                                                  }}
                                                />
                                              </span>
                                              <span>
                                                {moment
                                                  .utc(e.StartDate)
                                                  .format("ddd")}
                                                ,{" "}
                                                {moment
                                                  .utc(e?.StartTime)
                                                  .format("hh:mm a")}{" "}
                                              </span>
                                            </div>
                                            <div className="duration-price-remaining">
                                              <IoLocationOutline className="location-icon" />
                                              <span>
                                                {e?.venuedetails[0].venuename} ({" "}
                                                {e?.venuedetails[0].address},{" "}
                                                {e?.venuedetails[0].city},{" "}
                                                {e?.venuedetails[0].state},{" "}
                                                {e?.venuedetails[0].country},{" "}
                                                {e?.venuedetails[0].zipcode})
                                              </span>
                                            </div>

                                            <button
                                              className="sv-btn w-75 mt-4 "
                                              onClick={() =>
                                                selectEventHandle(
                                                  e?.event_unique_id
                                                )
                                              }
                                            >
                                              <CiCircleCheck className="icon me-2" />
                                              {text_selectevent}
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  );
                                })}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={openPopup}
        onClose={handleClosePopup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Upload-image-Modal"
      >
        <Box
          sx={styleTwo}
          id="header-registermodal"
          className="Upload-image-event"
        >
          <div>
            <div className="d-flex justify-content-end">
              <IoIosCloseCircleOutline
                className="closeIcon-venue"
                onClick={() => [handleClosePopup()]}
              />
            </div>
            <h2 className="popup-main-title text-center">
              {selectedEventData[0]?.EventTitle}
            </h2>
            <Divider />
            {Loading === true ? (
              <>
                <center className=" mt-3 mb-3">
                  {scanSuccess === true ? (
                    <>
                      <MdVerified className="verify-success-icon" />
                    </>
                  ) : (
                    <>
                      <div className="booking-confirmed-img mt-4">
                        <div className="loader mt-4">
                          <div className="circleLoading"></div>
                          <div className="circleLoading"></div>
                          <div className="circleLoading"></div>
                          <div className="circleLoading"></div>
                        </div>
                      </div>
                    </>
                  )}
                </center>
              </>
            ) : (
              <>
                <div className="container">
                  <div className="text-center form-group mt-3">
                    {showEventErrorMsg === true ? (
                      <>
                        <h2 className="starsty">
                          <b>Event does not match</b>
                        </h2>
                      </>
                    ) : (
                      <></>
                    )}
                    <h2>Ticket Details</h2>
                    <div className="d-flex">
                      <h3>Ticket Event Name :</h3>
                      <p>{scannedEventName}</p>
                    </div>
                    <div className="d-flex">
                      <h3>Ticket Event ID :</h3>
                      <p>{scannedEventID}</p>
                    </div>
                    <div className="d-flex">
                      <h3>Ticket Number:</h3>
                      <p>{scannedSeatID}</p>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="d-flex">
                          <h3>Seat :</h3>
                          <p>{scannedSeat}</p>
                        </div>
                        <div className="d-flex">
                          <h3>Seat Zone :</h3>
                          <p>{scannedZone}</p>
                        </div>
                        <div className="d-flex">
                          <h3>Event Date :</h3>
                          <p>
                            {moment(scannedEventDate).format("DD MMM, yyyy")}
                          </p>
                        </div>
                        <div className="d-flex">
                          <h3>Event Time :</h3>
                          <p>{moment(scannedEventTime).format("hh:mm a")}</p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        {showEventErrorMsg === false ? (
                          <>
                            <MdVerified className="verify-success-icon" />
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center gap-3 mt-3">
                  <button
                    onClick={() => handleClosePopup()}
                    className="cancelBtn"
                  >
                    {" "}
                    Cancel
                  </button>

                  <button
                    className="checkin-Btn"
                    disabled={disableCheckIn === true ? true : false}
                    onClick={() => verifySeat(scannedEventID, scannedSeatID)}
                  >
                    {" "}
                    Check In
                  </button>
                </div>
              </>
            )}
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default VerifyTickets;

import React, { useEffect, useState } from "react";
import "../LeagueCreate/LeagueCreate.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { RxCross2 } from "react-icons/rx";
import axios from "axios";
import { API_DOMAIN, GlobalConstants } from "../../utils/GlobalConst";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom/dist/umd/react-router-dom.development";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { GrChapterNext, GrChapterPrevious } from "react-icons/gr";
import { Box, Divider, Modal } from "@mui/material";
import { IoIosCloseCircleOutline } from "react-icons/io";
import Loader from "../../utils/Loader";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function LeagueCreate() {
  const navigate = useNavigate();
  const styleTwo = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    borderRadius: "20px",
  };
  const [leagueName, setLeagueName] = useState("");
  const [leagueuniqueURL, setLeagueuniqueURL] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const filterTime = (time) => {
    const selectedDate = new Date(time);
    return startTime < selectedDate.getTime();
  };
  const [openImageUploadPopup, setOpenImageUploadPopup] = useState(false);
  const handleOpenImagePopup = () => setOpenImageUploadPopup(true);
  const handleCloseImagePopup = () => setOpenImageUploadPopup(false);
  const [Loading, setLoading] = useState(false);
  const [leagueImage, setLeagueImage] = useState("");
  const [FilePath, setFilePath] = useState("");
  const [legueDescription, setLegueDescription] = useState("");
  const [faceBook, setFaceBook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [youtube, setYoutube] = useState("");
  const [twitter, setTwitter] = useState("");
  const [tiktok, setTiktok] = useState("");
  const [website, setWebsite] = useState("");
  const [text_leaguecreation, setText_leaguecreation] =
    useState("League Creation");
  const [text_leagueinfo, setText_leagueinfo] = useState("League Info");
  const [text_leagueteam, setTest_leagueteam] = useState("League Team");
  const [text_seasonschedule, setText_seasonschedule] =
    useState("Season Schedule");
  const [text_gamelist, setText_gamelist] = useState("Game List");
  const [text_leaguename, setText_leaguename] = useState("League Name");
  const [text_enterleaguename, setText_enterleaguename] =
    useState("Enter League Name");
  const [text_leagueadmin, setText_leagueadmin] = useState("League Admin");
  const [text_enterleagueadmin, setText_enterleagueadmin] =
    useState("Enter League Admin");
  const [text_next, setText_next] = useState("Next");
  const [text_previous, setText_previous] = useState("Previous");
  const [text_selectteam, setText_selectteam] = useState("Select Team");
  const [text_admin, setText_admin] = useState("Admin");
  const [text_startDate, setText_startDate] = useState("Start Date");
  const [text_endDate, setText_endDate] = useState("End Date");
  const [text_endTime, setText_endTime] = useState("End Time");
  const [text_startTime, setText_startTime] = useState("Start Time");
  const [text_hometeam, setText_hometeam] = useState("Home Team");
  const [text_selhometeam, setText_selhometeam] = useState("Select Home Team");
  const [text_awayteam, setText_awayteam] = useState("Away Team");
  const [text_selawayteam, setText_selawayteam] = useState("Select Away Team");
  const [text_date, setText_date] = useState("Date");
  const [text_venue, setText_venue] = useState("Venue");
  const [text_selvenue, setText_selvenue] = useState("Select Venue");
  const [text_selgame, setText_selgame] = useState("Select Game");
  const [text_save, setText_save] = useState("Save");
  const [text_cancel, setText_cancel] = useState("Cancel");
  const [text_home, setText_home] = useState("Home");
  const [text_orgdashboard, setText_orgdashboard] = useState(
    "Organization Dashboard"
  );
  const [text_addleagueadmin, setText_addleagueadmin] =
    useState("Add League Admin");
  const [text_addleagueteam, setText_addleagueteam] =
    useState("Add League Team");
  const [text_validationteam, setText_validationteam] = useState(
    "Please select first league team"
  );
  const [text_addgame, setText_addgame] = useState("Add Game");
  const [text_upload_image, setText_upload_image] = useState("Upload Image");

  const [text_upload, setText_upload] = useState("Upload");
  // new
  const [text_leagueLogo, setText_leagueLogo] = useState("League Logo");
  const [text_leagueDesc, setText_leagueDesc] = useState("League Description");
  const [text_twitterID, setText_twitterID] = useState("Twitter ID");
  const [text_facebookID, setText_facebookID] = useState("Facebook ID");
  const [text_youtubeID, setText_youtubeID] = useState("Youtube ID");
  const [text_instagramID, setText_instagramID] = useState("Instagram ID");
  const [text_tiktokID, setText_tiktokID] = useState("Tiktok ID");
  const [text_webURL, setText_webURL] = useState("Website URL");

  const [text_ph_twitterID, setText_ph_twitterID] = useState(
    "Enter Your Twitter ID"
  );
  const [text_ph_facebookID, setText_ph_facebookID] = useState(
    "Enter Your Facebook ID"
  );
  const [text_ph_youtubeID, setText_ph_youtubeID] = useState(
    "Enter Your Youtube ID"
  );
  const [text_ph_instagramID, setText_ph_instagramID] = useState(
    "Enter Your Instagram ID"
  );
  const [text_ph_tiktokID, setText_ph_tiktokID] = useState(
    "Enter Your Tiktok ID"
  );
  const [text_ph_webURL, setText_ph_webURL] = useState(
    "Enter Your Website URL"
  );
  const [text_leagueGame, setText_leagueGame] = useState("league game");
  const [text_knockOut, setText_knockOut] = useState("KnockOut");
  const [text_championshipGame, setText_championshipGame] =
    useState("Championship game");
  const [text_final, setText_final] = useState("Final");
  const [leagueAdminArr, setLeagueAdminArr] = useState([
    {
      userid: uuidv4(),
      leagueadminname: sessionStorage.getItem("useremail"),
    },
  ]);
  const handleChangeLeagueAdmin = (_id, team) => {
    const newleagueAdminArr = leagueAdminArr.map((i) => {
      if (_id === i.userid) {
        i[team.target.name] = team.target.value;
      }
      return i;
    });

    setLeagueAdminArr(newleagueAdminArr);
  };
  const handleAddLeagueAdmin = () => {
    setLeagueAdminArr([
      ...leagueAdminArr,
      {
        userid: uuidv4(),
        leagueadminname: "",
      },
    ]);
  };
  const handleRemoveLeagueAdmin = (userid) => {
    if (leagueAdminArr.length === 1) {
      toast.info("Minimum one admin is required");
    } else {
      const values = [...leagueAdminArr];
      values.splice(
        values.findIndex((value) => value.userid === userid),
        1
      );
      setLeagueAdminArr(values);
    }
  };

  //   MainTeam
  const [leagueteams, setLeagueteams] = useState([
    {
      teamid: uuidv4(),
      leagueteame: "",
      teamLogo: "",
      teamDescription: "",
      admin: [
        {
          userid: uuidv4(),
          adminname: "",
        },
      ],
    },
    {
      teamid: uuidv4(),
      leagueteame: "",
      teamLogo: "",
      teamDescription: "",
      admin: [
        {
          userid: uuidv4(),
          adminname: "",
        },
      ],
    },
  ]);
  console.log(leagueteams);
  const addTeam = () => {
    setLeagueteams([
      ...leagueteams,
      {
        teamid: uuidv4(),
        leagueteame: "",
        admin: [{ userid: uuidv4(), adminname: "" }],
      },
    ]);
  };

  const handleTeamChange = (event, teamIndex) => {
    const selectedData = teamList.filter(
      (item) => item.teamid === event.target.value
    );
    const updatedTeams = [...leagueteams];
    updatedTeams[teamIndex].leagueteame = selectedData[0].teamname;
    updatedTeams[teamIndex].admin = selectedData[0].adminlist;
    updatedTeams[teamIndex].teamid = selectedData[0].teamid;
    updatedTeams[teamIndex].teamLogo = selectedData[0].teamLogo;
    updatedTeams[teamIndex].teamDescription = selectedData[0].teamDescription;

    console.log(updatedTeams);
    console.log(selectedData);
    setLeagueteams(updatedTeams);
  };
  console.log(leagueteams);

  const removeAdmin = (teamIndex, adminIndex) => {
    if (leagueteams[teamIndex].admin.length === 1) {
      toast.info("Minimum One Admin is required");
    } else {
      const updatedTeams = [...leagueteams];
      updatedTeams[teamIndex].admin.splice(adminIndex, 1);
      setLeagueteams(updatedTeams);
    }
  };
  const removeTeam = (teamIndex) => {
    if (leagueteams.length === 2) {
      toast.info("Minimum two teams are required");
    } else {
      const updatedTeams = [...leagueteams];
      updatedTeams.splice(teamIndex, 1);
      setLeagueteams(updatedTeams);
    }
  };

  //   Game List
  const [inputFieldsgame, setInputFieldsgame] = useState([
    {
      game_id: uuidv4(),
      gametype: "",
      home: "",
      away: "",
      startdatetime: new Date(),
      venueid: "",
      venueaddress: "",
      home_teamid: "",
      away_teamid: "",
    },
  ]);
  console.log("game array", inputFieldsgame);
  const handleChangeInputgame = (_id, game) => {
    const newInputFieldsgame = inputFieldsgame.map((i) => {
      if (_id === i.game_id) {
        i[game.target.name] = game.target.value;
      }
      return i;
    });

    setInputFieldsgame(newInputFieldsgame);
  };
  const handleChangehometeamname = (_id, game) => {
    const [teamName, teamId] = game.target.value.split(",");
    const newInputFieldsgame = inputFieldsgame.map((i) => {
      if (_id === i.game_id) {
        i.home = teamName;
      }
      return i;
    });

    setInputFieldsgame(newInputFieldsgame);
  };
  const handleChangehometeamid = (_id, game) => {
    const [teamName, teamId] = game.target.value.split(",");

    const newInputFieldsgame = inputFieldsgame.map((i) => {
      if (_id === i.game_id) {
        i.home_teamid = teamId;
      }
      return i;
    });

    setInputFieldsgame(newInputFieldsgame);
  };
  const handleChangeawayteamname = (_id, game) => {
    const [teamName, teamId] = game.target.value.split(",");
    const newInputFieldsgame = inputFieldsgame.map((i) => {
      if (_id === i.game_id) {
        i.away = teamName;
      }
      return i;
    });

    setInputFieldsgame(newInputFieldsgame);
  };
  const handleChangeawayteamid = (_id, game) => {
    const [teamName, teamId] = game.target.value.split(",");

    const newInputFieldsgame = inputFieldsgame.map((i) => {
      if (_id === i.game_id) {
        i.away_teamid = teamId;
      }
      return i;
    });

    setInputFieldsgame(newInputFieldsgame);
  };
  const handleAddFieldsgame = () => {
    setInputFieldsgame([
      ...inputFieldsgame,
      {
        game_id: uuidv4(),
        gametype: "",
        home: "",
        away: "",
        startdatetime: new Date(),
        venueid: "",
        venueaddress: "",
        home_teamid: "",
        away_teamid: "",
      },
    ]);
  };
  const handleRemoveFieldsgame = (game_id) => {
    const values = [...inputFieldsgame];
    values.splice(
      values.findIndex((value) => value.game_id === game_id),
      1
    );
    setInputFieldsgame(values);
    // }
  };

  const createLeagueHanldle = () => {
    var API_URL = API_DOMAIN + "league/add";

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    var data = {
      leaguename: leagueName,
      leagueuniqueURL: leagueuniqueURL,
      leagueadmins: leagueAdminArr,
      leagueteams: leagueteams,
      gamelist: inputFieldsgame,
      leagueLogo: FilePath,
      leagueDescription: legueDescription,
      Facebook: faceBook,
      Instagram: instagram,
      YouTube: youtube,
      twitter: twitter,
      TikTok: tiktok,
      website: website,
      //change to home and away
      seasonalschedule: [
        {
          startdate: startDate,
          starttime: startTime,
          enddate: endDate,
          endtime: endTime,
        },
      ],
    };
    axios
      .post(API_URL, data, headerConfig)
      .then(function (response) {
        console.log("create league", response);
        if (response.data.status === true) {
          toast.success(response.data.message);
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  const getLeagueDetails = () => {
    var API_URL = API_DOMAIN + "league/getall";

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        console.log("league details", response);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  useEffect(() => {
    getLeagueDetails();
    getTeamDetails();
    getVenueDetails();
  }, []);
  const [activeTab, setActiveTab] = useState(1);

  const showTab = (n) => {
    let newTab = activeTab + n;

    // Wrap around to the first tab if reached the end
    if (newTab > 4) {
      newTab = 1;
    } else if (newTab < 1) {
      newTab = 4;
    }

    setActiveTab(newTab);
  };

  const [teamList, setTeamList] = useState([]);
  const getTeamDetails = () => {
    var API_URL = API_DOMAIN + "team/selectall";

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        console.log("Team details", response.data.data);
        setTeamList(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  const [venueList, setVenueList] = useState([]);

  const getVenueDetails = () => {
    var API_URL = API_DOMAIN + "venue/venue";

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        console.log("venue", response);
        setVenueList(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);
  const ImguplpoadValidation = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type;
      if (fileType === "image/jpeg" || fileType === "image/png") {
        setLeagueImage(file);
        // You can perform further actions with the selected file here
      } else {
        setLeagueImage(null);
        alert("Please upload only JPEG or PNG files.");
      }
    }
  };
  const HandleImgUpload = () => {
    setLoading(true);
    var API_URL = API_DOMAIN + "file/upload";
    var imgdata = new FormData();
    imgdata.append("att_file", leagueImage);
    let headerConfig = {
      headers: {
        "Content-Type": "form-data",
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .post(API_URL, imgdata, headerConfig)
      .then(function (response) {
        console.log("Img Upload", response);
        if (response.status === 200) {
          setLoading(false);
          setFilePath(response.data.data.Location);
          handleCloseImagePopup();
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
          setTimeout(() => {
            setLoading(false);
            handleCloseImagePopup();
            setLeagueImage("");
          }, 1000);
        }
      })
      .then(function () {});
  };
  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_leaguecreation(
        xpath.select1("/resources/string[@name='text_leaguecreation']", doc)
          .firstChild.data
      );

      setText_leagueinfo(
        xpath.select1("/resources/string[@name='text_leagueinfo']", doc)
          .firstChild.data
      );
      setTest_leagueteam(
        xpath.select1("/resources/string[@name='text_leagueteam']", doc)
          .firstChild.data
      );
      setText_seasonschedule(
        xpath.select1("/resources/string[@name='text_seasonschedule']", doc)
          .firstChild.data
      );
      setText_gamelist(
        xpath.select1("/resources/string[@name='text_gamelist']", doc)
          .firstChild.data
      );
      setText_leaguename(
        xpath.select1("/resources/string[@name='text_leaguename']", doc)
          .firstChild.data
      );
      setText_enterleaguename(
        xpath.select1("/resources/string[@name='text_enterleaguename']", doc)
          .firstChild.data
      );
      setText_leagueadmin(
        xpath.select1("/resources/string[@name='text_leagueadmin']", doc)
          .firstChild.data
      );
      setText_enterleagueadmin(
        xpath.select1("/resources/string[@name='text_enterleagueadmin']", doc)
          .firstChild.data
      );
      setText_next(
        xpath.select1("/resources/string[@name='text_next']", doc).firstChild
          .data
      );
      setText_previous(
        xpath.select1("/resources/string[@name='text_previous']", doc)
          .firstChild.data
      );
      setText_selectteam(
        xpath.select1("/resources/string[@name='text_selectteam']", doc)
          .firstChild.data
      );
      setText_admin(
        xpath.select1("/resources/string[@name='text_admin']", doc).firstChild
          .data
      );
      setText_startDate(
        xpath.select1("/resources/string[@name='text_startDate']", doc)
          .firstChild.data
      );
      setText_endDate(
        xpath.select1("/resources/string[@name='text_endDate']", doc).firstChild
          .data
      );
      setText_endTime(
        xpath.select1("/resources/string[@name='text_endTime']", doc).firstChild
          .data
      );
      setText_startTime(
        xpath.select1("/resources/string[@name='text_startTime']", doc)
          .firstChild.data
      );
      setText_hometeam(
        xpath.select1("/resources/string[@name='text_hometeam']", doc)
          .firstChild.data
      );
      setText_selhometeam(
        xpath.select1("/resources/string[@name='text_selhometeam']", doc)
          .firstChild.data
      );
      setText_awayteam(
        xpath.select1("/resources/string[@name='text_awayteam']", doc)
          .firstChild.data
      );
      setText_selawayteam(
        xpath.select1("/resources/string[@name='text_selawayteam']", doc)
          .firstChild.data
      );
      setText_venue(
        xpath.select1("/resources/string[@name='text_venue']", doc).firstChild
          .data
      );
      setText_date(
        xpath.select1("/resources/string[@name='text_date']", doc).firstChild
          .data
      );
      setText_selvenue(
        xpath.select1("/resources/string[@name='text_selvenue']", doc)
          .firstChild.data
      );
      setText_selgame(
        xpath.select1("/resources/string[@name='text_selgame']", doc).firstChild
          .data
      );
      setText_save(
        xpath.select1("/resources/string[@name='text_save']", doc).firstChild
          .data
      );
      setText_cancel(
        xpath.select1("/resources/string[@name='text_cancel']", doc).firstChild
          .data
      );
      setText_home(
        xpath.select1("/resources/string[@name='text_home']", doc).firstChild
          .data
      );
      setText_orgdashboard(
        xpath.select1("/resources/string[@name='text_orgdashboard']", doc)
          .firstChild.data
      );
      setText_addleagueadmin(
        xpath.select1("/resources/string[@name='text_addleagueadmin']", doc)
          .firstChild.data
      );
      setText_addleagueteam(
        xpath.select1("/resources/string[@name='text_addleagueteam']", doc)
          .firstChild.data
      );
      setText_validationteam(
        xpath.select1("/resources/string[@name='text_validationteam']", doc)
          .firstChild.data
      );
      setText_addgame(
        xpath.select1("/resources/string[@name='text_addgame']", doc).firstChild
          .data
      );

      setText_upload(
        xpath.select1("/resources/string[@name='text_upload']", doc).firstChild
          .data
      );
      setText_upload_image(
        xpath.select1("/resources/string[@name='text_upload_image']", doc)
          .firstChild.data
      );
      setText_leagueLogo(
        xpath.select1("/resources/string[@name='text_leagueLogo']", doc)
          .firstChild.data
      );
      setText_leagueDesc(
        xpath.select1("/resources/string[@name='text_leagueDesc']", doc)
          .firstChild.data
      );
      setText_twitterID(
        xpath.select1("/resources/string[@name='text_twitterID']", doc)
          .firstChild.data
      );
      setText_facebookID(
        xpath.select1("/resources/string[@name='text_facebookID']", doc)
          .firstChild.data
      );
      setText_youtubeID(
        xpath.select1("/resources/string[@name='text_youtubeID']", doc)
          .firstChild.data
      );
      setText_instagramID(
        xpath.select1("/resources/string[@name='text_instagramID']", doc)
          .firstChild.data
      );
      setText_tiktokID(
        xpath.select1("/resources/string[@name='text_tiktokID']", doc)
          .firstChild.data
      );
      setText_webURL(
        xpath.select1("/resources/string[@name='text_webURL']", doc)
          .firstChild.data
      );
      setText_ph_twitterID(
        xpath.select1("/resources/string[@name='text_ph_twitterID']", doc)
          .firstChild.data
      );
      setText_ph_facebookID(
        xpath.select1("/resources/string[@name='text_ph_facebookID']", doc)
          .firstChild.data
      );
      setText_ph_youtubeID(
        xpath.select1("/resources/string[@name='text_ph_youtubeID']", doc)
          .firstChild.data
      );
      setText_ph_instagramID(
        xpath.select1("/resources/string[@name='text_ph_instagramID']", doc)
          .firstChild.data
      );
      setText_ph_tiktokID(
        xpath.select1("/resources/string[@name='text_ph_instagramID']", doc)
          .firstChild.data
      );
      setText_ph_webURL(
        xpath.select1("/resources/string[@name='text_ph_webURL']", doc)
          .firstChild.data
      );
      setText_leagueGame(
        xpath.select1("/resources/string[@name='text_leagueGame']", doc)
          .firstChild.data
      );
      setText_knockOut(
        xpath.select1("/resources/string[@name='text_knockOut']", doc)
          .firstChild.data
      );
      setText_championshipGame(
        xpath.select1("/resources/string[@name='text_championshipGame']", doc)
          .firstChild.data
      );
      setText_final(
        xpath.select1("/resources/string[@name='text_final']", doc)
          .firstChild.data
      );
    } catch (ert) {}
  };
  return (
    <>
      <Header />\
      <div
        className="Create-league-wrapper eventdetail-nightmod"
        id="otherPages"
      >
        <div className="breadcrumb-block">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-10">
                <div className="barren-breadcrumb">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">{text_home}</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/org-dashboard">{text_orgdashboard}</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {text_leaguecreation}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="event-dt-block p-80">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12">
                <div className="main-title text-center">
                  <h3>{text_leaguecreation}</h3>
                </div>
              </div>
              <div className="main-card">
                <div className="p-4 bp-form main-form">
                  <div className="profile-tabs">
                    <div className="row p-4">
                      <div className="col-lg-12 col-md-12 text-end"></div>
                    </div>
                    <ul
                      className="nav nav-pills nav-fill p-2 garren-line-tab "
                      id="myTab"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <Link
                          className={`nav-link ${
                            activeTab === 1 ? "active" : ""
                          }`}
                          onClick={() => setActiveTab(1)}
                        >
                          {text_leagueinfo}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={`nav-link ${
                            activeTab === 2 ? "active" : ""
                          }`}
                          onClick={() => setActiveTab(2)}
                        >
                          {text_leagueteam}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={`nav-link ${
                            activeTab === 3 ? "active" : ""
                          }`}
                          onClick={() => setActiveTab(3)}
                        >
                          {text_seasonschedule}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={`nav-link ${
                            activeTab === 4 ? "active" : ""
                          }`}
                          onClick={() => setActiveTab(4)}
                        >
                          {text_gamelist}
                        </Link>
                      </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                      {activeTab === 1 ? (
                        <>
                          <div className="nav my-event-tabs mt-4">
                            <div className="row w-100 p-4">
                              <div className="col-lg-4 col-md-12">
                                <div className="form-group border_bottom-create profile-night-form pb_30">
                                  <label className="form-label fs-16">
                                    {text_leaguename}
                                  </label>
                                  <input
                                    className="form-control h_50 league-input inputleaguenight"
                                    type="text"
                                    placeholder={text_enterleaguename}
                                    onChange={(e) =>
                                      setLeagueName(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-12">
                                <div className="form-group border_bottom-create profile-night-form pb_30">
                                  <label className="form-label fs-16">
                                    {text_leagueLogo}
                                  </label>
                                  <button
                                    className="btn btn-default btn-hover steps_btn mt-2"
                                    onClick={handleOpenImagePopup}
                                  >
                                    {text_upload}
                                  </button>
                                  {FilePath !== "" ? (
                                    <>
                                      <img
                                        src={FilePath}
                                        alt=""
                                        className="eventImg"
                                      />
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-12">
                                <div className="form-group border_bottom-create profile-night-form pb_30">
                                  <label className="form-label fs-16">
                                    {text_leagueDesc}
                                  </label>
                                  <input
                                    className="form-control h_50 inputleaguenight"
                                    type="text"
                                    placeholder="Enter Team Desciption"
                                    onChange={(e) =>
                                      setLegueDescription(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-lg-12 col-md-12">
                                <div className="form-group border_bottom-create profile-night-form pb_30">
                                  <div className="leagueinputSty mb-2">
                                    <label className="form-label fs-16">
                                      {text_leagueadmin}
                                    </label>
                                  </div>
                                  <div className="row">
                                    {leagueAdminArr.map((adminlist) => {
                                      return (
                                        <>
                                          <div className="col-lg-6 col-md-12">
                                            <div className="d-flex gap-2 mt-2">
                                              <input
                                                className="form-control inputleaguenight h_50 league-input mb-3"
                                                type="text"
                                                placeholder={
                                                  text_enterleagueadmin
                                                }
                                                value={
                                                  adminlist.leagueadminname
                                                }
                                                name="leagueadminname"
                                                onChange={(team) =>
                                                  handleChangeLeagueAdmin(
                                                    adminlist.userid,
                                                    team
                                                  )
                                                }
                                              />
                                              <button
                                                onClick={() =>
                                                  handleRemoveLeagueAdmin(
                                                    adminlist.userid
                                                  )
                                                }
                                                className="removeBtnleague"
                                              >
                                                <RxCross2 className="crossIcon" />
                                              </button>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    })}
                                  </div>
                                  <div className="d-flex gap-1">
                                    <button
                                      className="ADDBtn"
                                      onClick={handleAddLeagueAdmin}
                                    >
                                      +
                                    </button>{" "}
                                    <p>{text_addleagueadmin}</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-12">
                                <div className="form-group border_bottom-create profile-night-form pb_30">
                                  <label className="form-label fs-16">
                                    {text_twitterID}
                                  </label>
                                  <input
                                    className="form-control h_50 league-input inputleaguenight"
                                    type="text"
                                    placeholder={text_ph_twitterID}
                                    onChange={(e) => setTwitter(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-12">
                                <div className="form-group border_bottom-create profile-night-form pb_30">
                                  <label className="form-label fs-16">
                                    {text_facebookID}
                                  </label>
                                  <input
                                    className="form-control h_50 league-input inputleaguenight"
                                    type="text"
                                    placeholder={text_ph_facebookID}
                                    onChange={(e) =>
                                      setFaceBook(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-12">
                                <div className="form-group border_bottom-create profile-night-form pb_30">
                                  <label className="form-label fs-16">
                                    {text_youtubeID}
                                  </label>
                                  <input
                                    className="form-control h_50 league-input inputleaguenight"
                                    type="text"
                                    placeholder={text_ph_youtubeID}
                                    onChange={(e) => setYoutube(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-12">
                                <div className="form-group border_bottom-create profile-night-form pb_30">
                                  <label className="form-label fs-16">
                                    {text_instagramID}
                                  </label>
                                  <input
                                    className="form-control h_50 league-input inputleaguenight"
                                    type="text"
                                    placeholder={text_ph_instagramID}
                                    onChange={(e) =>
                                      setInstagram(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-12">
                                <div className="form-group border_bottom-create profile-night-form pb_30">
                                  <label className="form-label fs-16">
                                    {text_tiktokID}
                                  </label>
                                  <input
                                    className="form-control h_50 league-input inputleaguenight"
                                    type="text"
                                    placeholder={text_ph_tiktokID}
                                    onChange={(e) => setTiktok(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-12">
                                <div className="form-group border_bottom-create profile-night-form pb_30">
                                  <label className="form-label fs-16">
                                    {text_webURL}
                                  </label>
                                  <input
                                    className="form-control h_50 league-input inputleaguenight"
                                    type="text"
                                    placeholder={text_ph_webURL}
                                    onChange={(e) => setWebsite(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div className="d-flex justify-content-end">
                                <button
                                  onClick={() => showTab(1)}
                                  className="saveBtn"
                                >
                                  {text_next} &nbsp;
                                  <GrChapterNext />
                                </button>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          {activeTab === 2 ? (
                            <>
                              <div className="nav my-event-tabs mt-4">
                                <div className="row w-100 p-4">
                                  {leagueteams.map((team, teamIndex) => {
                                    return (
                                      <>
                                        <div
                                          className="col-lg-6 col-md-12"
                                          key={teamIndex}
                                        >
                                          <div className="form-group border_bottom-create profile-night-form pb_30">
                                            <div className="leagueinputSty mb-3">
                                              <label className="form-label  fs-16 mt-3">
                                                {text_leagueteam}
                                              </label>
                                              <div className="">
                                                <button
                                                  className="removeBtnleague"
                                                  onClick={() =>
                                                    removeTeam(teamIndex)
                                                  }
                                                >
                                                  <RxCross2 className="crossIcon" />
                                                </button>
                                              </div>
                                            </div>

                                            <div>
                                              <select
                                                className="form-control h_50 league-input inputleaguenight"
                                                defaultValue={team.leagueteame}
                                                onChange={(e) =>
                                                  handleTeamChange(e, teamIndex)
                                                }
                                              >
                                                <option
                                                  value=""
                                                  disabled
                                                  selected
                                                >
                                                  {text_selectteam}
                                                </option>

                                                {teamList.map((data) => {
                                                  return (
                                                    <>
                                                      <option
                                                        value={data?.teamid}
                                                      >
                                                        {data.teamname}
                                                      </option>
                                                    </>
                                                  );
                                                })}
                                              </select>
                                            </div>
                                            {team.leagueteame !== "" ? (
                                              <>
                                                <div className="mt-3 gap-4">
                                                  <div className="leagueinputSty">
                                                    <label className="form-label fs-16">
                                                      {text_admin}
                                                    </label>
                                                  </div>
                                                  {team.admin.map(
                                                    (admin, adminIndex) => {
                                                      return (
                                                        <>
                                                          <div className="d-flex gap-2 mt-2">
                                                            <input
                                                              className="form-control h_50 league-input inputleaguenight"
                                                              type="text"
                                                              name="adminname"
                                                              value={
                                                                admin.email
                                                              }
                                                            />
                                                            {console.log(
                                                              leagueteams
                                                            )}
                                                            <button
                                                              onClick={() =>
                                                                removeAdmin(
                                                                  teamIndex,
                                                                  adminIndex
                                                                )
                                                              }
                                                              className="removeBtnleague"
                                                            >
                                                              <RxCross2 className="crossIcon" />
                                                            </button>
                                                          </div>
                                                        </>
                                                      );
                                                    }
                                                  )}
                                                </div>
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })}
                                  <div className="col-lg-12 col-md-12 text-start mb-4">
                                    <div className="d-flex gap-1">
                                      <button
                                        className="ADDBtn"
                                        onClick={addTeam}
                                      >
                                        +
                                      </button>{" "}
                                      <p>{text_addleagueteam}</p>
                                    </div>
                                  </div>
                                  <div className="d-flex justify-content-between gap-2">
                                    <button
                                      onClick={() => showTab(-1)}
                                      className="cancelBtn league-cancel-btn"
                                    >
                                      <GrChapterPrevious /> &nbsp;{" "}
                                      {text_previous}
                                    </button>
                                    <button
                                      onClick={() => showTab(1)}
                                      className="saveBtn"
                                    >
                                      {text_next} &nbsp;
                                      <GrChapterNext />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              {activeTab === 3 ? (
                                <>
                                  <div className="nav my-event-tabs mt-4">
                                    <div className="row w-100 p-4">
                                      <div className="col-lg-6 col-md-12">
                                        <div className="form-group border_bottom-create profile-night-form pb_30">
                                          <label className="form-label fs-16">
                                            {text_startDate}
                                          </label>
                                          <DatePicker
                                            className="form-control dateInputSty h_50 league-date-input inputleaguenight"
                                            minDate={new Date()}
                                            selected={startDate}
                                            dateFormat="dd MMM,yyyy"
                                            onChange={(date) => [
                                              setStartDate(date),
                                              setEndDate(date),
                                            ]}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-md-12">
                                        <div className="form-group border_bottom-create profile-night-form pb_30">
                                          <label className="form-label fs-16">
                                            {text_endDate}
                                          </label>
                                          <DatePicker
                                            className="form-control dateInputSty h_50 league-date-input inputleaguenight"
                                            selected={endDate}
                                            minDate={startDate}
                                            dateFormat="dd MMM,yyyy"
                                            onChange={(date) =>
                                              setEndDate(date)
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-md-12">
                                        <div className="form-group border_bottom-create profile-night-form pb_30">
                                          <label className="form-label fs-16">
                                            {text_startTime}
                                          </label>
                                          <DatePicker
                                            className="form-control dateInputSty h_50 league-date-input inputleaguenight"
                                            selected={startTime}
                                            onChange={(date) => [
                                              setStartTime(date),
                                              setEndTime(date),
                                            ]}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={10}
                                            dateFormat="h:mm aa"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-md-12">
                                        <div className="form-group border_bottom-create profile-night-form pb_30">
                                          <label className="form-label fs-16">
                                            {text_endTime}
                                          </label>
                                          <DatePicker
                                            className="form-control dateInputSty h_50 league-date-input inputleaguenight"
                                            selected={endTime}
                                            onChange={(date) =>
                                              setEndTime(date)
                                            }
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={10}
                                            dateFormat="h:mm aa"
                                            filterTime={filterTime}
                                          />
                                        </div>
                                      </div>
                                      <div className="d-flex justify-content-between gap-2">
                                        <button
                                          onClick={() => showTab(-1)}
                                          className="cancelBtn league-cancel-btn"
                                        >
                                          <GrChapterPrevious />
                                          &nbsp; {text_previous}
                                        </button>
                                        <button
                                          onClick={() => showTab(1)}
                                          className="saveBtn"
                                        >
                                          {text_next} &nbsp;
                                          <GrChapterNext />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="nav my-event-tabs mt-4">
                                    <div className="row w-100 p-4">
                                      <div className="gatezonebox mb-3">
                                        {inputFieldsgame.map((inputField) => {
                                          return (
                                            <>
                                              <div className=" seatingArrengmentBox">
                                                <div>
                                                  <h5>{text_hometeam}</h5>

                                                  <select
                                                    type="text"
                                                    value={inputField.home}
                                                    name="home"
                                                    id=""
                                                    className="form-control h_40 venue-input inputleaguenight"
                                                    onChange={(game) => [
                                                      handleChangehometeamname(
                                                        inputField.game_id,
                                                        game
                                                      ),
                                                      console.log(
                                                        game.target.value
                                                      ),
                                                      handleChangehometeamid(
                                                        inputField.game_id,
                                                        game
                                                      ),
                                                    ]}
                                                  >
                                                    <option
                                                      value=""
                                                      disabled
                                                      selected
                                                    >
                                                      {text_selhometeam}
                                                    </option>
                                                    {leagueteams.length > 0 ? (
                                                      <>
                                                        {leagueteams.map(
                                                          (data) => {
                                                            return (
                                                              <>
                                                                <option
                                                                  value={[
                                                                    data?.leagueteame,
                                                                    data?.teamid,
                                                                  ]}
                                                                >
                                                                  {
                                                                    data?.leagueteame
                                                                  }
                                                                </option>
                                                              </>
                                                            );
                                                          }
                                                        )}
                                                      </>
                                                    ) : (
                                                      <>
                                                        <option disabled>
                                                          {text_validationteam}
                                                        </option>
                                                      </>
                                                    )}
                                                  </select>
                                                </div>
                                                <div className=" ">
                                                  <h5>{text_awayteam}</h5>

                                                  <select
                                                    type="text"
                                                    value={inputField.away}
                                                    name="away"
                                                    id=""
                                                    className="form-control h_40 venue-input inputleaguenight"
                                                    onChange={(game) => [
                                                      handleChangeawayteamname(
                                                        inputField.game_id,
                                                        game
                                                      ),
                                                      console.log(
                                                        game.target.value
                                                      ),
                                                      handleChangeawayteamid(
                                                        inputField.game_id,
                                                        game
                                                      ),
                                                    ]}
                                                  >
                                                    <option
                                                      value=""
                                                      disabled
                                                      selected
                                                    >
                                                      {text_selawayteam}
                                                    </option>
                                                    {leagueteams.length > 0 ? (
                                                      <>
                                                        {leagueteams.map(
                                                          (data) => {
                                                            return (
                                                              <>
                                                                <option
                                                                  value={[
                                                                    data?.leagueteame,
                                                                    data?.teamid,
                                                                  ]}
                                                                >
                                                                  {
                                                                    data?.leagueteame
                                                                  }
                                                                </option>
                                                              </>
                                                            );
                                                          }
                                                        )}
                                                      </>
                                                    ) : (
                                                      <>
                                                        <option disabled>
                                                          {text_validationteam}
                                                        </option>
                                                      </>
                                                    )}
                                                  </select>
                                                </div>
                                                <div className=" ">
                                                  <h5>{text_date}</h5>
                                                  <DatePicker
                                                    className="form-control dateInputSty inputleaguenight"
                                                    minDate={new Date()}
                                                    selected={
                                                      inputField.startdatetime
                                                    }
                                                    dateFormat="dd MMM,yyyy"
                                                    value={
                                                      inputField.startdatetime
                                                    }
                                                    name="startdatetime"
                                                    id=""
                                                    onChange={(game) =>
                                                      handleChangeInputgame(
                                                        inputField.game_id,
                                                        game
                                                      )
                                                    }
                                                  />
                                                </div>
                                                <div className=" ">
                                                  <h5>{text_venue}</h5>

                                                  <select
                                                    type="text"
                                                    value={
                                                      inputField.venueaddress
                                                    }
                                                    name="venueaddress"
                                                    id=""
                                                    className="form-control h_40 venue-input inputleaguenight"
                                                    onChange={(game) =>
                                                      handleChangeInputgame(
                                                        inputField.game_id,
                                                        game
                                                      )
                                                    }
                                                  >
                                                    <option selected disabled>
                                                      {text_selvenue}
                                                    </option>
                                                    {venueList.map((v) => {
                                                      return (
                                                        <>
                                                          <option
                                                            value={v.venuename}
                                                          >
                                                            {v.venuename}
                                                          </option>
                                                        </>
                                                      );
                                                    })}
                                                  </select>
                                                </div>
                                                <div className=" ">
                                                  <h5>{text_selgame}</h5>
                                                  <select
                                                    type="text"
                                                    value={inputField.gametype}
                                                    name="gametype"
                                                    id=""
                                                    className="form-control h_40 venue-input inputleaguenight"
                                                    onChange={(game) =>
                                                      handleChangeInputgame(
                                                        inputField.game_id,
                                                        game
                                                      )
                                                    }
                                                  >
                                                    <option
                                                      value=""
                                                      selected
                                                      disabled
                                                    >
                                                      {text_selgame}
                                                    </option>
                                                    <option value="leaguegame">
                                                      {text_leagueGame}
                                                    </option>
                                                    <option value="KnockOut">
                                                      {text_knockOut}
                                                    </option>
                                                    <option value="Final">
                                                      {text_championshipGame}/
                                                      {text_final}
                                                    </option>
                                                  </select>
                                                </div>
                                                <div className="">
                                                  <button
                                                    onClick={() =>
                                                      handleRemoveFieldsgame(
                                                        inputField.game_id
                                                      )
                                                    }
                                                    className="removeBtnVenue"
                                                  >
                                                    <RxCross2 className="crossIcon" />
                                                  </button>
                                                </div>
                                              </div>
                                            </>
                                          );
                                        })}
                                      </div>
                                      <div className="justify-content-start d-flex gap-1 mb-2">
                                        <button
                                          onClick={handleAddFieldsgame}
                                          className="ADDBtn"
                                        >
                                          +
                                        </button>{" "}
                                        <p>{text_addgame}</p>
                                      </div>
                                      <div className="d-flex justify-content-between gap-2">
                                        <button
                                          onClick={() => showTab(-1)}
                                          className="cancelBtn league-cancel-btn"
                                        >
                                          <GrChapterPrevious />
                                          &nbsp; {text_previous}
                                        </button>
                                        <button
                                          onClick={createLeagueHanldle}
                                          className="saveBtn"
                                        >
                                          {text_save}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  Image Poupup */}
      <Modal
        open={openImageUploadPopup}
        onClose={handleCloseImagePopup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Upload-image-Modal"
      >
        <Box
          sx={styleTwo}
          id="header-registermodal"
          className="Upload-image-event"
        >
          <div>
            <div className="d-flex justify-content-end">
              <IoIosCloseCircleOutline
                className="closeIcon-venue"
                onClick={() => [handleCloseImagePopup()]}
              />
            </div>
            <h3 className="popup-main-title">{text_upload_image}</h3>
            <Divider />
            {Loading === true ? (
              <>
                <center className=" mt-3 mb-3">
                  <Loader />
                </center>
              </>
            ) : (
              <>
                <div className="container">
                  <div className="text-center form-group mt-3">
                    <input
                      type="file"
                      className="form-control h_50 uploadinput landinginputnight"
                      onChange={ImguplpoadValidation}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-center gap-3 mt-3">
                  <button
                    onClick={() => handleCloseImagePopup()}
                    className="cancelBtn"
                  >
                    {" "}
                    {text_cancel}
                  </button>

                  <button onClick={HandleImgUpload} className="resell-Btn">
                    {" "}
                    {text_save}
                  </button>
                </div>
              </>
            )}
          </div>
        </Box>
      </Modal>
      <Footer />
      <ToastContainer />{" "}
    </>
  );
}

export default LeagueCreate;

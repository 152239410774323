import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import OrgDashboardSidebar from "./OrgDashboardSidebar";
import { toast } from "react-toastify";
import { API_DOMAIN, GlobalConstants } from "../../utils/GlobalConst";
import axios from "axios";
import { useNavigate } from "react-router-dom/dist/umd/react-router-dom.development";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function OrgAllLeague() {
  const navigate = useNavigate();
  const [allLeagueData, setAllLeagueData] = useState([]);
  const [nextToken, setNextToken] = useState("");
  const [hasMoreLeagues, setHasMoreLeagues] = useState("");

  const [text_srno, setText_srno] = useState("Sr No");
  const [text_leaguename, setText_leaguename] = useState("League Name");
  const [text_leagueadmin, setText_leagueadmin] = useState("League Admin");
  const [text_active, setText_active] = useState("Active");
  const [text_deactive, setText_deactive] = useState("Deactive");
  const [text_next, setText_next] = useState("Next");

  // new
  const [text_allLeague, setText_allLeague] = useState("All League");

  const getAllLeague = () => {
    var API_URL = API_DOMAIN + "league/getall/createdby";
    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    axios
      .get(API_URL, headerConfig)
      .then((response, i) => {
        console.log("all leagues ", response.data.data);

        var res = response.data.data;
        console.log(res);

        setAllLeagueData(res);
        var admin = res[0]?.leagueadmins;
        console.log(admin);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };
  useEffect(() => {
    getAllLeague();
  }, []);
  const getMoreLeague = (nextToken, D) => {
    var API_URL =
      API_DOMAIN +
      `league/getall/withpagination?limit=10&nexttoken=${nextToken}`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        var res = response.data.data;
        console.log(res.league);

        setAllLeagueData(res.league);

        setNextToken(res.nexttoken);
        setHasMoreLeagues(res.hasMoreLeagues);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  const ActivateTeam = (leagueid) => {
    var API_URL = API_DOMAIN + `league/status/update`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    let data = {
      leagueid: leagueid,
      isleagueactive: true,
    };
    console.log(leagueid);

    axios
      .put(API_URL, data, headerConfig)
      .then(function (response) {
        console.log(response);
        if (response.data.status === true) {
          getAllLeague();
        }
        toast.info("Team Activated Successfully");
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  const DeActivateTeam = (leagueid) => {
    var API_URL = API_DOMAIN + `league/status/update`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    let data = {
      leagueid: leagueid,
      isleagueactive: false,
    };

    axios
      .put(API_URL, data, headerConfig)
      .then(function (response) {
        console.log(response);
        if (response.data.status === true) {
          getAllLeague();
        }
        toast.info("Team Deactivated Successfully");
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);

  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_srno(
        xpath.select1("/resources/string[@name='text_srno']", doc).firstChild
          .data
      );
      setText_leaguename(
        xpath.select1("/resources/string[@name='text_leaguename']", doc)
          .firstChild.data
      );
      setText_leagueadmin(
        xpath.select1("/resources/string[@name='text_leagueadmin']", doc)
          .firstChild.data
      );
      setText_active(
        xpath.select1("/resources/string[@name='text_active']", doc).firstChild
          .data
      );
      setText_deactive(
        xpath.select1("/resources/string[@name='text_deactive']", doc)
          .firstChild.data
      );
      setText_next(
        xpath.select1("/resources/string[@name='text_next']", doc).firstChild
          .data
      );
      setText_allLeague(
        xpath.select1("/resources/string[@name='text_allLeague']", doc).firstChild
          .data
      );
    } catch (ert) {}
  };
  return (
    <>
      <Header />
      <OrgDashboardSidebar />
      <div id="otherPages">
        <div className="wrapper wrapper-body" id="wrapper-body">
          <div className="dashboard-body">
            <h2 className="text-center text-decoration-underline">
              <b>{text_allLeague}</b>
            </h2>
            <div className="container-fluid">
              <div className="col-md-12">
                <div className="conversion-setup">
                  <div className="tab-content">
                    <div
                      className="tab-pane fade active show"
                      id="overview-tab"
                      role="tabpanel"
                    >
                      <div className="table-card mt-2">
                        <div className="main-table">
                          <div className="table-responsive">
                            <table className="table">
                              <thead className="thead-dark">
                                <tr>
                                  <th>{text_srno}.</th>
                                  <th scope="col">{text_leaguename}</th>
                                  <th scope="col">{text_leagueadmin}</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {allLeagueData.length > 0 &&
                                  allLeagueData.map((v, index) => {
                                    return (
                                      <>
                                        <tr>
                                          <td>{index + 1}</td>
                                          <td>{v.leaguename}</td>

                                          <td>
                                            <ul className="admin-list-css">
                                              {v.leagueadmins.map((i) => {
                                                return (
                                                  <>
                                                    <li>{i.leagueadminname}</li>
                                                  </>
                                                );
                                              })}
                                            </ul>
                                          </td>

                                          <td>
                                            {v?.active === true ? (
                                              <>
                                                {console.log(v.isleagueactive)}
                                                <button
                                                  className="teamActiveSty"
                                                  onClick={() =>
                                                    DeActivateTeam(v.leagueid)
                                                  }
                                                >
                                                  {text_active}
                                                </button>
                                              </>
                                            ) : (
                                              <>
                                                <button
                                                  className="teamDeactiveSty"
                                                  onClick={() =>
                                                    ActivateTeam(v.leagueid)
                                                  }
                                                >
                                                  {text_deactive}
                                                </button>
                                              </>
                                            )}
                                          </td>
                                        </tr>
                                      </>
                                    );
                                  })}
                              </tbody>
                            </table>

                            {hasMoreLeagues === true ? (
                              <>
                                <div className="browse-btn">
                                  <button
                                    onClick={() =>
                                      getMoreLeague(nextToken, allLeagueData)
                                    }
                                    className="main-btn btn-hover "
                                  >
                                    {text_next}
                                  </button>
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrgAllLeague;

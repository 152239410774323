import React, { useState, useEffect } from "react";
import Header from "../Header/Header";
import OrgDashboardSidebar from "./OrgDashboardSidebar";
import "./OrgDashboard.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IoMdRefresh } from "react-icons/io";
import { FaChartPie } from "react-icons/fa";
import { CiSearch } from "react-icons/ci";
import { API_DOMAIN, GlobalConstants } from "../../utils/GlobalConst";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import img1 from "../../assets/img/event-imgs/big-1.jpg";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function OrgReport() {
  //variable
  const navigate = useNavigate();
  const data = useLocation();
  const eventID = data.state !== null ? data.state.event_id : 0;
  const [searchValue, setSearchValue] = useState("");
  const [reportTab, setReportTab] = useState("orders-tab");
  const [TotalTicketData, setTotalTicketData] = useState([]);
  const [allReportData, setAllReportData] = useState([]);

  const [AllEventsData, setAllEventsData] = useState([]);
  const [eventId, seteventID] = useState(eventID === 0 ? "" : eventID);

  // language variable
  const [text_reports, setText_reports] = useState("Reports");
  const [text_orders, setText_orders] = useState("Orders");
  const [text_tickets, setText_tickets] = useState("Tickets");
  const [text_payouts, setText_payouts] = useState("Payouts");
  const [text_active, setText_active] = useState("Active");
  const [text_ticketType, setText_ticketType] = useState("Ticket Type");
  const [text_totalticket, setText_totalticket] = useState("Total Ticket");
  const [text_name, setText_name] = useState("Name");
  const [text_address, setText_address] = useState("Address");
  const [text_ticketPrice, setText_ticketPrice] = useState("Ticket Price");
  const [text_date, setText_date] = useState("Date");
  const [text_download, setText_download] = useState("Download");

  // new
  const [text_orgreport_customers, setText_orgreport_customers] =
    useState("Customers");
  const [text_orgreport_searchname, setText_orgreport_searchname] =
    useState("Search by name");
  const [text_orgreport_refresh, setText_orgreport_refresh] =
    useState("Refresh");
  const [text_allevents, setText_allevents] = useState("All Event");
  const [text_today, setText_today] = useState("Today");
  const [text_yesterday, setText_yesterday] = useState("Yesterday");
  const [text_last_seven_day, setText_last_seven_day] = useState("Last 7 Days");
  const [text_last_thirty_day, setText_last_thirty_day] =
    useState("Last 30 Days");
  const [text_this_month, setText_this_month] = useState("This Month");
  const [text_last_month, setText_last_month] = useState("Last Month");
  const [text_custom_range, setText_custom_range] = useState("Custom Range");
  const [text_all_orders, setText_all_orders] = useState("All Orders");
  const [text_Refunded, setText_Refunded] = useState("Refunded");
  const [text_Refund_Rejected, setText_Refund_Rejected] =
    useState("Refund Rejected");
  const [text_Refund_Requested, setText_Refund_Requested] =
    useState("Refund Requested");
  const [text_Partially_Refunded, setText_Partially_Refunded] =
    useState("Partially Refunded");
  const [text_Canceled, setText_Canceled] = useState("Canceled");
  const [text_no_report_found, setText_no_report_found] =
    useState("No Report Found");
  const [text_viewReport, setText_viewReport] = useState("View Report");
  const [text_srno, setText_srno] = useState("Sr No");
  const [text_Purchaser, setText_Purchaser] = useState("Purchaser");
  const [text_total_amount, setText_total_amount] = useState("Total Amount");
  const [text_purchase_datetime, setText_purchase_datetime] = useState(
    "Purchase Date and Time"
  );
  const [text_id, setText_id] = useState("ID");
  const [text_email_add, setText_email_add] = useState("Email address");
  const [text_ticket_left, setText_ticket_left] = useState("Ticket Left");
  const [text_Remittance_ID, setText_Remittance_ID] = useState("Remittance ID");
  const [text_Remittance_Date, setText_Remittance_Date] =
    useState("Remittance Date");
  const [text_date_paid, setText_date_paid] = useState("Date Paid");
  const [text_transactionId, setText_transactionId] =
    useState("Transaction ID");
  const [text_Actions, setText_Actions] = useState("Actions");

  const customer = [
    {
      id: "2356",
      name: "Larry Paige",
      email: "larry@example.com",
      address:
        "140 St Kilda Rd, St Kilda, Victoria,Melbourne, Victoria, 3000,Australia",
    },
    {
      id: "2355",
      name: "John Cena",
      email: "johncena@example.com",
      address:
        "140 St Kilda Rd, St Kilda, Victoria,Melbourne, Victoria, 3000,Australia",
    },
    {
      id: "2354",
      name: "Jassica William",
      email: "jassica@example.com",
      address:
        "140 St Kilda Rd, St Kilda, Victoria,Melbourne, Victoria, 3000,Australia",
    },
  ];

  const payoutData = [
    {
      id: "12475",
      remitDate: "28/04/2022",
      paidDate: "26/04/2022",
      date: "22/04/2022",
      transactionID: "TXR21234123UX",
    },
  ];
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);
  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_reports(
        xpath.select1("/resources/string[@name='text_reports']", doc).firstChild
          .data
      );
      setText_orders(
        xpath.select1("/resources/string[@name='text_orders']", doc).firstChild
          .data
      );
      setText_tickets(
        xpath.select1("/resources/string[@name='text_tickets']", doc).firstChild
          .data
      );
      setText_payouts(
        xpath.select1("/resources/string[@name='text_payouts']", doc).firstChild
          .data
      );
      setText_active(
        xpath.select1("/resources/string[@name='text_active']", doc).firstChild
          .data
      );
      setText_ticketType(
        xpath.select1("/resources/string[@name='text_ticketType']", doc)
          .firstChild.data
      );
      setText_totalticket(
        xpath.select1("/resources/string[@name='text_totalticket']", doc)
          .firstChild.data
      );
      setText_name(
        xpath.select1("/resources/string[@name='text_name']", doc).firstChild
          .data
      );
      setText_address(
        xpath.select1("/resources/string[@name='text_address']", doc).firstChild
          .data
      );
      setText_ticketPrice(
        xpath.select1("/resources/string[@name='text_ticketPrice']", doc)
          .firstChild.data
      );
      setText_date(
        xpath.select1("/resources/string[@name='text_date']", doc).firstChild
          .data
      );
      setText_download(
        xpath.select1("/resources/string[@name='text_download']", doc)
          .firstChild.data
      );
      setText_orgreport_customers(
        xpath.select1(
          "/resources/string[@name='text_orgreport_customers']",
          doc
        ).firstChild.data
      );
      setText_orgreport_searchname(
        xpath.select1(
          "/resources/string[@name='text_orgreport_searchname']",
          doc
        ).firstChild.data
      );
      setText_orgreport_refresh(
        xpath.select1("/resources/string[@name='text_orgreport_refresh']", doc)
          .firstChild.data
      );
      setText_allevents(
        xpath.select1("/resources/string[@name='text_allevents']", doc)
          .firstChild.data
      );
      setText_today(
        xpath.select1("/resources/string[@name='text_today']", doc).firstChild
          .data
      );
      setText_yesterday(
        xpath.select1("/resources/string[@name='text_yesterday']", doc)
          .firstChild.data
      );
      setText_last_seven_day(
        xpath.select1("/resources/string[@name='text_last_seven_day']", doc)
          .firstChild.data
      );
      setText_last_thirty_day(
        xpath.select1("/resources/string[@name='text_last_thirty_day']", doc)
          .firstChild.data
      );
      setText_id(
        xpath.select1("/resources/string[@name='text_id']", doc).firstChild.data
      );
      setText_email_add(
        xpath.select1("/resources/string[@name='text_email_add']", doc)
          .firstChild.data
      );
      setText_ticket_left(
        xpath.select1("/resources/string[@name='text_ticket_left']", doc)
          .firstChild.data
      );
      setText_Remittance_ID(
        xpath.select1("/resources/string[@name='text_Remittance_ID']", doc)
          .firstChild.data
      );
      setText_Remittance_Date(
        xpath.select1("/resources/string[@name='text_Remittance_Date']", doc)
          .firstChild.data
      );
      setText_date_paid(
        xpath.select1("/resources/string[@name='text_date_paid']", doc)
          .firstChild.data
      );
      setText_transactionId(
        xpath.select1("/resources/string[@name='text_transactionId']", doc)
          .firstChild.data
      );
      setText_Actions(
        xpath.select1("/resources/string[@name='text_Actions']", doc).firstChild
          .data
      );
      setText_this_month(
        xpath.select1("/resources/string[@name='text_this_month']", doc)
          .firstChild.data
      );
      setText_last_month(
        xpath.select1("/resources/string[@name='text_last_month']", doc)
          .firstChild.data
      );
      setText_custom_range(
        xpath.select1("/resources/string[@name='text_custom_range']", doc)
          .firstChild.data
      );
      setText_all_orders(
        xpath.select1("/resources/string[@name='text_all_orders']", doc)
          .firstChild.data
      );
      setText_Refunded(
        xpath.select1("/resources/string[@name='text_Refunded']", doc)
          .firstChild.data
      );
      setText_Refund_Rejected(
        xpath.select1("/resources/string[@name='text_Refund_Rejected']", doc)
          .firstChild.data
      );
      setText_Refund_Requested(
        xpath.select1("/resources/string[@name='text_Refund_Requested']", doc)
          .firstChild.data
      );
      setText_Partially_Refunded(
        xpath.select1("/resources/string[@name='text_Partially_Refunded']", doc)
          .firstChild.data
      );
      setText_Canceled(
        xpath.select1("/resources/string[@name='text_Canceled']", doc)
          .firstChild.data
      );
      setText_no_report_found(
        xpath.select1("/resources/string[@name='text_no_report_found']", doc)
          .firstChild.data
      );
      setText_viewReport(
        xpath.select1("/resources/string[@name='text_viewReport']", doc)
          .firstChild.data
      );
      setText_srno(
        xpath.select1("/resources/string[@name='text_srno']", doc).firstChild
          .data
      );
      setText_Purchaser(
        xpath.select1("/resources/string[@name='text_Purchaser']", doc)
          .firstChild.data
      );
      setText_total_amount(
        xpath.select1("/resources/string[@name='text_total_amount']", doc)
          .firstChild.data
      );
      setText_purchase_datetime(
        xpath.select1("/resources/string[@name='text_purchase_datetime']", doc)
          .firstChild.data
      );
    } catch (ert) {}
  };
  //Total Tickets API
  useEffect(() => {
    if (eventId !== "") {
      var type = "select";
      var event_unique_id = eventId;
      var API_URL =
        API_DOMAIN +
        "event/public?type=" +
        type +
        "&event_unique_id=" +
        event_unique_id;

      axios
        .get(API_URL)
        .then(function (response) {
          var data = response.data.data;
          if (data !== null) {
            var res = data[0].TicketDetailsList;
            getMinMaxDetails(res);

            console.log(data[0].TicketDetailsList);
          }
        })
        .catch(function (error) {
          console.log(error);
          if (error.response.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            setTimeout(() => {
              navigate("/");
            }, 1000);
          } else {
            toast.error(error.response.data.message);
          }
        })
        .then(function () {});
    }
  }, [eventId]);

  const filteredTickets = TotalTicketData.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(searchValue.toLowerCase()) !==
      -1
  );

  //remaining and sold tickets
  useEffect(() => {
    if (eventId !== "") {
      var event_unique_id = eventId;
      var API_URL =
        API_DOMAIN + "event/buy/status?event_unique_id=" + event_unique_id;

      axios
        .get(API_URL)
        .then(function (response) {
          var res = response.data.data;

          const soldTic = res.filter((v) => {
            return v.sold === true;
          });
          const remainTic = res.filter((v) => {
            return v.sold === false;
          });
          console.log("sold ticket", soldTic);
          console.log("remaining Ticket", remainTic);
        })
        .catch(function (error) {
          toast.error(error.response.data.message);
          if (error.response.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            setTimeout(() => {
              navigate("/");
            }, 1000);
          } else {
            toast.error(error.response.data.message);
          }
        })
        .then(function () {});
    }
  }, [eventId]);

  const getMinMaxDetails = (data) => {
    var API_URL =
      API_DOMAIN + `event/ticket/price/check?event_unique_id=${eventId}`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        const res = response.data.data;
        console.log("000", res);
        const configured = res.filter((v) => {
          return v.isconfigure === true;
        });
        const notconfigured = res.filter((v) => {
          return v.isconfigure === false;
        });
        const dummy = data;
        for (let i = 0; i < dummy.length; i++) {
          for (let j = 0; j < configured.length; j++) {
            if (configured[j].seat_category_id === dummy[i].section_id) {
              dummy[i].min = configured[j].min;
              dummy[i].max = configured[j].max;
            }
          }
        }
        console.log(" Details", dummy);
        setTotalTicketData(dummy);

        console.log("configured", configured);
        console.log("notconfigured", notconfigured);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };

  const filteredReportData = allReportData.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(searchValue.toLowerCase()) !==
      -1
  );

  const getAllEvents = () => {
    var type = "selectall";
    var API_URL = API_DOMAIN + "event/my?type=" + type;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        var res = response?.data?.data;
        console.log(res);
        setAllEventsData(res);
      })
      .catch(function (error) {
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  useEffect(() => {
    getAllEvents();
  }, []);

  const filterAllEvent = AllEventsData.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(searchValue.toLowerCase()) !==
      -1
  );

  return (
    <>
      <Header />
      <OrgDashboardSidebar />
      <div id="otherPages">
        <div className="wrapper wrapper-body" id="wrapper-body">
          <div className="dashboard-body">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-main-title">
                    <h3>
                      <FaChartPie className="me-3" />
                      {text_reports}
                    </h3>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="main-card mt-5">
                    <div className="dashboard-wrap-content p-4">
                      <div
                        className="nav custom2-tabs btn-group gap-3"
                        role="tablist"
                      >
                        <button
                          className={
                            reportTab === "orders-tab"
                              ? "tab-link ms-0 active"
                              : "tab-link ms-0"
                          }
                          onClick={() => setReportTab("orders-tab")}
                        >
                          {text_orders}
                        </button>
                        <button
                          className={
                            reportTab === "customers-tab"
                              ? "tab-link ms-0 active"
                              : "tab-link ms-0"
                          }
                          onClick={() => setReportTab("customers-tab")}
                        >
                          {text_orgreport_customers}
                        </button>
                        <button
                          className={
                            reportTab === "tickets-tab"
                              ? "tab-link ms-0 active"
                              : "tab-link ms-0"
                          }
                          onClick={() => setReportTab("tickets-tab")}
                        >
                          {text_tickets}
                        </button>
                        <button
                          className={
                            reportTab === "payouts-tab"
                              ? "tab-link ms-0 active"
                              : "tab-link ms-0"
                          }
                          onClick={() => setReportTab("payouts-tab")}
                        >
                          {text_payouts}
                        </button>
                      </div>
                      <div className="d-md-flex flex-wrap align-items-center">
                        <div className="dashboard-date-wrap mt-4">
                          <div className="form-group">
                            <div className="relative-input position-relative">
                              <input
                                className="form-control h_40"
                                type="text"
                                placeholder={text_orgreport_searchname}
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                              />
                              <i className="uil uil-search">
                                <CiSearch className="search--icon" />
                              </i>
                            </div>
                          </div>
                        </div>
                        <div className="rs ms-auto mt-4 mt_r4">
                          <button
                            className="pe-4 w-100 ps-4 text-center co-main-btn h_40 d-inline-block"
                            onClick={() => [
                              window.history.replaceState({}, eventID),
                              window.location.reload(),
                            ]}
                          >
                            <IoMdRefresh className="me-3" />
                            {text_orgreport_refresh}
                          </button>
                        </div>
                      </div>
                      <div className="main-form mt-4">
                        <div className="row g-3">
                          <div className="col-lg-3 col-md-6 mb-5">
                            <div className="">
                              <select
                                className="selectpicker-add-orgReport"
                                data-size="5"
                                data-live-search="true"
                              >
                                <option value="all events" selected>
                                  {text_allevents}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-6 mb-5">
                            <div className="">
                              <select className="selectpicker-add-orgReport ">
                                <option value="Today">{text_today}</option>
                                <option value="Yesterday">
                                  {text_yesterday}
                                </option>
                                <option value="Last 7 Days">
                                  {text_last_seven_day}
                                </option>
                                <option value="Last 30 Days" selected>
                                  {text_last_thirty_day}
                                </option>
                                <option value="This Month">
                                  {text_this_month}
                                </option>
                                <option value="Last Month">
                                  {text_last_month}
                                </option>
                                <option value="Custom Range">
                                  {text_custom_range}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-6 mb-5">
                            <div className="">
                              <select className="selectpicker-add-orgReport ">
                                <option value="All Orders" selected>
                                  {text_all_orders}
                                </option>
                                <option value="Refunded">
                                  {text_Refunded}
                                </option>
                                <option value="Refund Rejected">
                                  {text_Refund_Rejected}
                                </option>
                                <option value="Refund Requested">
                                  {text_Refund_Requested}
                                </option>
                                <option value="Partially Refunded">
                                  {text_Partially_Refunded}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-6 mb-5">
                            <div className="">
                              <select className="selectpicker-add-orgReport ">
                                <option value="all">{text_all_orders}</option>
                                <option value="1">{text_active}</option>
                                <option value="0">{text_Canceled}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="event-list">
                    <div className="tab-content">
                      {reportTab === "orders-tab" && (
                        <>
                          {filteredReportData.length === 0 ? (
                            <>
                              <div>
                                <div className="all-promotion-list">
                                  <div className="main-card mt-4 p-4 pt-0">
                                    <div className="row">
                                      {eventId !== "" ? (
                                        <>
                                          <div className="mt-4">
                                            <h3 className="text-center font-16 mt-3 fw-bold">
                                              {text_no_report_found}
                                            </h3>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          {filterAllEvent.map((e) => {
                                            return (
                                              <>
                                                <div className="col-lg-3 col-md-6">
                                                  <div className="bank-card p-4 mt-4">
                                                    <div className="card-event-img card-event-img-report card-event-img-report-responsive">
                                                      <img
                                                        className=""
                                                        src={
                                                          e.EventImagePath ===
                                                          "path"
                                                            ? img1
                                                            : e.EventImagePath
                                                        }
                                                        alt=""
                                                      />
                                                    </div>
                                                    <h5 className="text-center mt-3">
                                                      {e.EventTitle}
                                                    </h5>
                                                    <div className="card-actions">
                                                      <div className="d-flex justify-content-center">
                                                        <button
                                                          className="viewreport-btn btn-hover"
                                                          onClick={() => [
                                                            seteventID(
                                                              e?.event_unique_id
                                                            ),
                                                          ]}
                                                        >
                                                          {text_viewReport}
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            );
                                          })}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="">
                                <div className="table-card mt-4">
                                  <div className="main-table">
                                    <div className="table-responsive">
                                      <table className="table">
                                        <thead className="thead-dark">
                                          <tr>
                                            <th scope="col">{text_srno}</th>
                                            <th scope="col">
                                              {text_Purchaser}
                                            </th>
                                            <th scope="col">
                                              {text_ticketType}
                                            </th>
                                            <th scope="col">
                                              {text_totalticket}
                                            </th>
                                            <th scope="col">
                                              {text_total_amount}
                                            </th>
                                            <th scope="col">
                                              {text_purchase_datetime}
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {filteredReportData.map((e, i) => {
                                            return (
                                              <>
                                                <tr>
                                                  <td>{i + 1}</td>
                                                  <td>{e.buyername}</td>
                                                  <td>{e.eventname}</td>
                                                  <td>{e.count}</td>
                                                  <td>{e.reference}</td>

                                                  <td>
                                                    {moment(
                                                      e.buydatetime
                                                    ).format(
                                                      "DD MMM, yyyy hh:mm a"
                                                    )}
                                                  </td>
                                                </tr>
                                              </>
                                            );
                                          })}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}
                      {reportTab === "customers-tab" && customer.length > 0 && (
                        <>
                          <div>
                            <div className="table-card mt-4">
                              <div className="main-table">
                                <div className="table-responsive">
                                  <table className="table">
                                    <thead className="thead-dark">
                                      <tr>
                                        <th scope="col">{text_id}</th>
                                        <th scope="col">{text_name}</th>
                                        <th scope="col">{text_email_add}</th>
                                        <th scope="col">{text_address}</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {customer.map((e) => {
                                        return (
                                          <>
                                            <tr>
                                              <td>{e.id}</td>
                                              <td>{e.name}</td>
                                              <td>{e.email}</td>
                                              <td>{e.address}</td>
                                            </tr>
                                          </>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      {reportTab === "tickets-tab" && (
                        <>
                          {filteredTickets.length === 0 ? (
                            <>
                              <div>
                                <div className="all-promotion-list">
                                  <div className="main-card mt-4 p-4 pt-0">
                                    <div className="row">
                                      {filterAllEvent.map((e) => {
                                        return (
                                          <>
                                            <div className="col-lg-3 col-md-6">
                                              <div className="bank-card p-4 mt-4">
                                                <div className="card-event-img card-event-img-report">
                                                  <img
                                                    className=""
                                                    src={
                                                      e.EventImagePath ===
                                                      "path"
                                                        ? img1
                                                        : e.EventImagePath
                                                    }
                                                    alt=""
                                                  />
                                                </div>
                                                <h5 className="text-center mt-3">
                                                  {e.EventTitle}
                                                </h5>
                                                <div className="card-actions">
                                                  <div className="d-flex justify-content-center">
                                                    <button
                                                      className="viewreport-btn btn-hover"
                                                      onClick={() => [
                                                        seteventID(
                                                          e?.event_unique_id
                                                        ),
                                                      ]}
                                                    >
                                                      {text_viewReport}
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              {filteredTickets.length > 0 && (
                                <>
                                  <div>
                                    <div className="table-card mt-4">
                                      <div className="main-table">
                                        <div className="table-responsive">
                                          <table className="table">
                                            <thead className="thead-dark">
                                              <tr>
                                                <th scope="col">{text_srno}</th>
                                                <th scope="col">
                                                  {text_ticketType}
                                                </th>
                                                <th scope="col">
                                                  {text_ticketPrice}
                                                </th>
                                                <th scope="col">
                                                  {text_ticket_left}
                                                </th>
                                                <th scope="col">
                                                  {text_totalticket}
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {filteredTickets.map((e, i) => {
                                                return (
                                                  <>
                                                    <tr>
                                                      <td>{i + 1}</td>
                                                      <td>
                                                        {e.ticket_category}
                                                      </td>
                                                      <td>
                                                        {e.min}-{e.max}
                                                      </td>
                                                      <td>
                                                        {e.no_tickets -
                                                          e.ticketsold}
                                                      </td>
                                                      <td>{e.no_tickets}</td>
                                                    </tr>
                                                  </>
                                                );
                                              })}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                      {reportTab === "payouts-tab" && payoutData.length > 0 && (
                        <>
                          <div>
                            <div className="table-card mt-4">
                              <div className="main-table">
                                <div className="table-responsive">
                                  <table className="table">
                                    <thead className="thead-dark">
                                      <tr>
                                        <th scope="col">
                                          {text_Remittance_ID}
                                        </th>
                                        <th scope="col">
                                          {text_Remittance_Date}
                                        </th>
                                        <th scope="col">{text_date_paid}</th>
                                        <th scope="col">{text_date}</th>
                                        <th scope="col">
                                          {text_transactionId}
                                        </th>
                                        <th scope="col">{text_Actions}</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {payoutData.map((e) => {
                                        return (
                                          <>
                                            <tr>
                                              <td>{e.id}</td>
                                              <td>{e.remitDate}</td>
                                              <td>{e.paidDate}</td>
                                              <td>{e.date}</td>
                                              <td>{e.transactionID}</td>
                                              <td>
                                                <Link className="a-link">
                                                  {text_download}
                                                </Link>
                                              </td>
                                            </tr>
                                          </>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrgReport;

import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { API_DOMAIN } from "../../utils/GlobalConst";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import confirmImg from "../../assets/img/confirmed.png";
import { FaTicketAlt } from "react-icons/fa";
import { GlobalConstants } from "../../utils/GlobalConst";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function BundlePaymentStatus() {
  const data = useLocation();
  const pid = data.state !== null ? data.state.paymentid : false;
  console.log("object", pid);

  const [paymentSuccess, setPaymentSuccess] = useState(false);

  const navigate = useNavigate();

  const [text_home, setText_home] = useState("Home");
  const [text_ticketconfirmed, setText_ticketconfirmed] =
    useState("Ticket Confirmed");
  const [text_PaymentProccessing, setText_PaymentProccessing] = useState(
    "Payment Proccessing"
  );
  const [text_viewtickets, setText_viewtickets] = useState("View Tickets");
  const [text_ticketconfirmed_desc, setText_ticketconfirmed_desc] = useState(
    "We are pleased to inform you that your reservation request has been received and confirmed"
  );
  const buyTicketHandle = (payid) => {
    var API_URL = API_DOMAIN + "seat/buy";

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    var data = {
      paymentid: payid,
    };
    axios
      .post(API_URL, data, headerConfig)
      .then(function (response) {
        console.log("check out", response);
        if (response.data.message === "success") {
          setPaymentSuccess(true);
          sessionStorage.setItem(
            "bundleTicket",
            JSON.stringify(response.data.data)
          );
        }
      })
      .catch(function (error) {
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  useEffect(() => {
    const paymentid = new URLSearchParams(window.location.search).get(
      "payment_intent"
    );
    buyTicketHandle(paymentid);
  }, [1]);
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);
  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_home(
        xpath.select1("/resources/string[@name='text_home']", doc).firstChild
          .data
      );
      setText_ticketconfirmed(
        xpath.select1("/resources/string[@name='text_ticketconfirmed']", doc)
          .firstChild.data
      );
      setText_PaymentProccessing(
        xpath.select1("/resources/string[@name='text_PaymentProccessing']", doc)
          .firstChild.data
      );
      setText_viewtickets(
        xpath.select1("/resources/string[@name='text_viewtickets']", doc)
          .firstChild.data
      );
      setText_ticketconfirmed_desc(
        xpath.select1(
          "/resources/string[@name='text_ticketconfirmed_desc']",
          doc
        ).firstChild.data
      );
    } catch (ert) {}
  };
  return (
    <>
      <Header />
      <div className="wrapper">
        <div className="breadcrumb-block">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-10">
                <div className="barren-breadcrumb">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">{text_home}</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {text_ticketconfirmed}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        {paymentSuccess === false ? (
          <>
            <div className="event-dt-block p-80">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-xl-5 col-lg-7 col-md-10">
                    <div className="booking-confirmed-content">
                      <div className="main-card">
                        <div className="booking-confirmed-top text-center p_30">
                          <h4>{text_PaymentProccessing}</h4>
                          <br />
                          <div className="booking-confirmed-img mt-4">
                            <div className="loader mt-4">
                              <div className="circleLoading"></div>
                              <div className="circleLoading"></div>
                              <div className="circleLoading"></div>
                              <div className="circleLoading"></div>
                            </div>
                          </div>
                          <br /> <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="event-dt-block p-80">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-xl-5 col-lg-7 col-md-10">
                    <div className="booking-confirmed-content">
                      <div className="main-card">
                        <div className="booking-confirmed-top text-center p_30">
                          <div className="booking-confirmed-img mt-4">
                            <img src={confirmImg} alt="" />
                          </div>
                          <h4>{text_ticketconfirmed}</h4>
                          <p className="ps-lg-4 pe-lg-4">
                            {text_ticketconfirmed_desc}.
                          </p>
                        </div>
                        <div className="">
                          <div className="px-4">
                            <button
                              onClick={() => navigate("/view-ticket-bundle")}
                              className="main-btn btn-hover h_50 w-100 mb-2"
                            >
                              <FaTicketAlt className="rotate-icon me-3" />
                              {text_viewtickets}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <ToastContainer />
      <Footer />
    </>
  );
}

export default BundlePaymentStatus;

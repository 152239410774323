import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./component/Login/Login";
import Register from "./component/Register/Register";
import ForgotPassword from "./component/ForgotPassword/ForgotPassword";
import PageNotFound from "./component/PageNotFound/PageNotFound";
import HomePage from "./component/HomePage/HomePage";
import AllEvent from "./component/AllEvent/AllEvent";
import ContactUs from "./component/ContactUs/ContactUs";
import CreateEvent from "./component/CreateEvent/CreateEvent";
import EventDetails from "./component/EventDetails/EventDetails";
import Profile from "./component/Profile/Profile";
import OrganiserProfile from "./component/OrganiserProfile/OrganiserProfile";
import OrgDashboard from "./component/OrgDashboard/OrgDashboard";
import ResellEvent from "./component/ResellEvent/ResellEvent";
import TermsConditions from "./component/TermsConditions/TermsConditions";
import PrivacyPolicy from "./component/PrivacyPolicy/PrivacyPolicy";
import FAQ from "./component/FAQ/FAQ";
import EventType from "./component/CreateEvent/EventType";
import ResellEventDetail from "./component/ResellEvent/ResellEventDetail";
import OrgEvent from "./component/OrgDashboard/OrgEvent";
import OrgPayout from "./component/OrgDashboard/OrgPayout";
import OrgReport from "./component/OrgDashboard/OrgReport";
import ResellTicket from "./component/ResellEvent/ResellTicket";
import BuyTicket from "./component/BuyTicket/BuyTicket";
import ResellBuyTicket from "./component/ResellBuyTicket/ResellBuyTicket";
import ResellPaymentStatus from "./component/ResellBuyTicket/ResellPaymentStatus";
import CreateEventConfiguration from "./component/CreateEvent/CreateEventConfiguration";
import SeatConfiguration from "./component/CreateEvent/SeatConfiguration";
import CreateVenue from "./component/CreateVenue/CreateVenue";
import RequestVenue from "./component/CreateVenue/RequestVenue";
import Support from "./component/Login/Support";
import AuthGard from "./utils/AuthGard";
import PaymentStatus from "./component/BuyTicket/PaymentStatus";
import OrganizationList from "./component/OrgDashboard/OrganizationList";
import Invoice from "./component/Invoice/Invoice";
import LeagueCreate from "./component/LeagueCreate/LeagueCreate";
import SeasonalPass from "./component/SeasonalPass/SeasonalPass";
import InvoiceBuy from "./component/Invoice/InvoiceBuy";
import LandingPage from "./component/LandingPage/LandingPage";
import SeasonalEvent from "./component/SeasonalEvent/SeasonalEvent";
import OrgMyTeam from "./component/OrgDashboard/OrgMyTeam";
import VenueConfiguration from "./component/CreateVenue/VenueConfiguration";
import OrgLandingPageInfo from "./component/OrgDashboard/OrgLandingPageInfo";
import BundleCreate from "./component/BundleCreate/BundleCreate";
import SeasonalPaymentStatus from "./component/SeasonalEvent/SeasonalPaymentStatus";
import BundleEvents from "./component/BundleEvent/BundleEvents";
import OrgAbout from "./component/OrgDashboard/OrgAbout";
import BundlePaymentStatus from "./component/BundleEvent/BundlePaymentStatus";
import InvoiceProfile from "./component/Profile/InvoiceProfile";
import ViewInvoiceProfile from "./component/Profile/ViewInvoiceProfile";
import CreateTeam from "./component/Team/CreateTeam";
import SeasonInvoice from "./component/Invoice/SeasonInvoice";
import BundleInvoice from "./component/Invoice/BundleInvoice";
import SupportChat from "./component/SupportChat/SupportChat";
import TeamPage from "./component/Team/TeamPage";
import LeaguePage from "./component/LeagueCreate/LeaguePage";
import OrgAllLeague from "./component/OrgDashboard/OrgAllLeague";
import VerifyTickets from "./component/OrgDashboard/VerifyTickets";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/signin" element={<Login />} />
        <Route path="/signup" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/support" element={<Support />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/all-event" element={<AllEvent />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route
          path="/event-type"
          element={<AuthGard Component={EventType} />}
        />
        <Route
          path="/create-event"
          element={<AuthGard Component={CreateEvent} />}
        />

        <Route
          path="/create-event/edit"
          element={<AuthGard Component={CreateEvent} />}
        />
        <Route path="/event-details/:eventID" element={<EventDetails />} />
        <Route path="/profile" element={<AuthGard Component={Profile} />} />
        <Route
          path="/organiser-profile"
          element={<AuthGard Component={OrganiserProfile} />}
        />
        <Route
          path="/org-dashboard"
          element={<AuthGard Component={OrgDashboard} />}
        />
        <Route path="/resell-event" element={<ResellEvent />} />
        <Route path="/resell-ticket/:eventID" element={<ResellTicket />} />
        <Route
          path="/resell-event-details/:resellID"
          element={<ResellEventDetail />}
        />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route
          path="/payment-status"
          element={<AuthGard Component={PaymentStatus} />}
        />
        <Route path="/org-events" element={<AuthGard Component={OrgEvent} />} />
        <Route
          path="/org-payout"
          element={<AuthGard Component={OrgPayout} />}
        />
        <Route
          path="/org-report"
          element={<AuthGard Component={OrgReport} />}
        />
        <Route
          path="/organization-list"
          element={<AuthGard Component={OrganizationList} />}
        />
        <Route
          path="/org-landing/info"
          element={<AuthGard Component={OrgLandingPageInfo} />}
        />
        <Route
          path="/buy-ticket"
          element={<AuthGard Component={BuyTicket} />}
        />
        <Route
          path="/resell-buy-ticket"
          element={<AuthGard Component={ResellBuyTicket} />}
        />
        <Route
          path="/resell-payment"
          element={<AuthGard Component={ResellPaymentStatus} />}
        />
        <Route
          path="/create-event/configuration"
          element={<AuthGard Component={CreateEventConfiguration} />}
        />
        <Route
          path="/seat/configuration"
          element={<AuthGard Component={SeatConfiguration} />}
        />
        <Route
          path="/create-venue"
          element={<AuthGard Component={CreateVenue} />}
        />
        <Route
          path="/create-venue/configuration"
          element={<AuthGard Component={VenueConfiguration} />}
        />
        <Route
          path="/request-venue"
          element={<AuthGard Component={RequestVenue} />}
        />
        <Route
          path="/payment-status"
          element={<AuthGard Component={PaymentStatus} />}
        />
        <Route path="/invoice" element={<AuthGard Component={Invoice} />} />
        <Route
          path="/buy-invoice"
          element={<AuthGard Component={InvoiceBuy} />}
        />
        <Route
          path="/invoice-profile"
          element={<AuthGard Component={InvoiceProfile} />}
        />
        <Route
          path="/view-ticket-invoice"
          element={<AuthGard Component={ViewInvoiceProfile} />}
        />
        <Route
          path="/create-league"
          element={<AuthGard Component={LeagueCreate} />}
        />
        <Route
          path="/create-seasonal"
          element={<AuthGard Component={SeasonalPass} />}
        />
        <Route path="/landingpage" element={<LandingPage />} />
        <Route
          path="/seasonalevent/:seasonalticketid"
          element={<SeasonalEvent />}
        />
        <Route
          path="/seasonalevent/paymentstatus"
          element={<AuthGard Component={SeasonalPaymentStatus} />}
        />
        <Route
          path="/bundleevent/paymentstatus"
          element={<AuthGard Component={BundlePaymentStatus} />}
        />
        <Route path="/bundleevent/:bundleticketid" element={<BundleEvents />} />
        <Route
          path="/org-myteam"
          element={<AuthGard Component={OrgMyTeam} />}
        />
        <Route
          path="/create-bundle"
          element={<AuthGard Component={BundleCreate} />}
        />
        <Route path="/org-about" element={<AuthGard Component={OrgAbout} />} />
        <Route
          path="/create-team"
          element={<AuthGard Component={CreateTeam} />}
        />
        <Route
          path="/view-ticket-season"
          element={<AuthGard Component={SeasonInvoice} />}
        />
        <Route
          path="/view-ticket-bundle"
          element={<AuthGard Component={BundleInvoice} />}
        />
        <Route
          path="/org-league"
          element={<AuthGard Component={OrgAllLeague} />}
        />
        <Route path="/team-page" element={<TeamPage />} />
        <Route path="/league-page" element={<LeaguePage />} />
        <Route
          path="/checkin-tickets"
          element={<AuthGard Component={VerifyTickets} />}
        />

        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <SupportChat />
    </BrowserRouter>
  );
}

export default App;

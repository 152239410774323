import React, { useState, useEffect } from "react";
import "../Login/Login.css";
import mainlogo from "../../assets/img/tiq8-Logo-white.png";
import { FaSignInAlt } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { API_DOMAIN, GlobalConstants } from "../../utils/GlobalConst";
import axios from "axios";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FcFeedback } from "react-icons/fc";
import moment from "moment";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { FaInfoCircle } from "react-icons/fa";

const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location?.state?.pageLand);
  const landPageTo = location?.state?.pageLand;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [openloginEmailInfo, setOpenloginEmailInfo] = useState(false);

  const [email, setEmail] = useState("");
  const [passsword, setPasssword] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [passErr, setPassErr] = useState("");
  const [loginErr, setLoginErr] = useState("");
  const [passVisibility, setPassVisibility] = useState(false);
  const [loginBtn, setLoginBtn] = useState(false);

  const [text_err_emailorphone, setText_err_emailorphone] = useState(
    "*Please Enter Email Or Phone Number!"
  );
  const [text_err_pass, setText_err_pass] = useState("Please Enter Password");
  const [text_verify_head, setText_verify_head] = useState(
    "Please Verify Your Account"
  );
  const [text_email_phone_heading, setText_email_phone_heading] = useState(
    "Enter your Registered Email or Phone number"
  );
  const [text_ph_email_phone, setText_ph_email_phone] = useState(
    "Enter Email or Phone Number"
  );
  const [text_ph_pass, setText_ph_pass] = useState("Enter Password");
  const [text_password, setText_password] = useState("Password");

  const [text_verify_msg, setText_verify_msg] = useState(
    "We have sent you a link on your registered email or phone no. to verify your Account."
  );
  const [text_verify_msg_span, setText_verify_msg_span] = useState(
    "Please check your Spam or Junk folder"
  );
  const [text_forgot, setText_forgot] = useState("Forgot Password");
  const [text_signin, setText_signin] = useState("Sign In");
  const [text_having_trouble, setText_having_trouble] = useState(
    "Having Trouble sign in"
  );
  const [text_signup, setText_signup] = useState("Signup");
  const [text_newtotiq8, setText_newtotiq8] = useState("New to TiQ8");

  const [text_close, setText_close] = useState("Close");
  const [text_signintotiq8, setText_signintotiq8] = useState("Sign in to TiQ8");
  const [login_title, setLogin_title] = useState(
    "The Easiest Way to Create Events and Sell More Tickets Online"
  );

  const [text_rightsReserved, setText_rightsReserved] = useState(
    "All rights reserved"
  );
  const [text_resendLink, setText_resendLink] = useState(
    "Resend verification link"
  );
  const [text_phone_info, setText_phone_info] = useState(
    "Enter Phone number with country code. For example"
  );
  const LoginValidationHandler = () => {
    var isValidLogin = true;

    if (!email) {
      isValidLogin = false;
      setEmailErr(<>{text_err_emailorphone}</>);
    }
    if (!passsword) {
      isValidLogin = false;
      setPassErr(<>*{text_err_pass}!</>);
    }
    setLoginBtn(false);
    return isValidLogin;
  };
  const handleCloseloginEmailInfo = () => {
    setOpenloginEmailInfo(false);
  };
  const loginHandler = () => {
    if (LoginValidationHandler()) {
      setLoginBtn(true);
      var API_URL = API_DOMAIN + "user/login";
      let headerConfig = {
        headers: {
          accept: "application/json",
        },
      };
      var data = {
        username: email.toLowerCase(),
        password: passsword,
      };
      axios
        .post(API_URL, data, headerConfig)
        .then(function (response) {
          console.log("response***", response);
          if (response.data.status === true) {
            setLoginBtn(false);
            setEmail("");
            setPasssword("");
            sessionStorage.setItem("token", response.data.data.token);
            sessionStorage.setItem("userid", response.data.data.user_id);
            sessionStorage.setItem("useremail", response.data.data.email);
            sessionStorage.setItem("usermobile", response.data.data.mobile);
            sessionStorage.setItem(
              "userfirstname",
              response.data.data.firstname
            );
            sessionStorage.setItem("userlastname", response.data.data.lastname);

            sessionStorage.setItem("isOrg", response.data.data.isOrg);
            sessionStorage.setItem(
              "isOrgActive",
              response.data.data.isOrgActive
            );
            sessionStorage.setItem("orgName", response.data.data.orgname);
            sessionStorage.setItem(
              "teamList",
              JSON.stringify(response.data.data.team)
            );

            if (
              landPageTo === "" ||
              landPageTo === null ||
              landPageTo === undefined
            ) {
              navigate("/");
            } else if (landPageTo === "/landingpage") {
              console.log("else if true");
              navigate(`${landPageTo}?cid=6545ecb05a5f6ddb0f89d82c`);
            } else {
              console.log("else true");
              navigate(`${landPageTo}`);
            }

            toast.success(response?.data?.message, {
              autoClose: 2000,
              theme: "light",
            });
          }
        })
        .catch(function (error) {
          if (
            error.response.data.message === "Account inactive" ||
            error.response.data.message === "mobile number not verified" ||
            error.response.data.message === "email not verified"
          ) {
            setOpenloginEmailInfo(true);
          } else {
          }
          console.log("catch block err***", error.response.data);
          setLoginErr(error.response.data.message);
          setLoginBtn(false);
        })
        .then(function () {});
    }
  };
  const changeVisibility = (e) => {
    e.preventDefault();
    setPassVisibility(!passVisibility);
  };

  const keyHandler = (e) => {
    if (e.key === "Enter") {
      if (loginBtn) {
        return;
      }
      loginHandler();
    }
  };

  const [helperr, setHelperr] = useState("");

  const SendVerificationEmail = () => {
    var API_URL = API_DOMAIN + "user/resendverifylink";
    let headerConfig = {
      headers: {
        accept: "application/json",
      },
    };
    var data = {
      username: email,
    };
    axios
      .post(API_URL, data, headerConfig)
      .then(function (response) {
        console.log("response***", response);
        if (response.data.status === true) {
          toast.success(response?.data?.message);

          setLoginErr("");
          setEmailErr("");
          setPassErr("");
          setEmail("");
          setPasssword("");
          setOpenloginEmailInfo(false);
        }
      })
      .catch(function (error) {
        setHelperr(error?.response?.data?.message);
        console.log("catch block err***", error.response.data);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setHelperr(error.response.data.message);
        }
      })
      .then(function () {});
  };

  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);

  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_signup(
        xpath.select1("/resources/string[@name='text_signup']", doc).firstChild
          .data
      );
      setText_signintotiq8(
        xpath.select1("/resources/string[@name='text_signintotiq8']", doc)
          .firstChild.data
      );
      setText_phone_info(
        xpath.select1("/resources/string[@name='text_phone_info']", doc)
          .firstChild.data
      );
      setLogin_title(
        xpath.select1("/resources/string[@name='login_title']", doc).firstChild
          .data
      );
      setText_email_phone_heading(
        xpath.select1(
          "/resources/string[@name='text_email_phone_heading']",
          doc
        ).firstChild.data
      );
      setText_ph_email_phone(
        xpath.select1("/resources/string[@name='text_ph_email_phone']", doc)
          .firstChild.data
      );
      setText_password(
        xpath.select1("/resources/string[@name='text_password']", doc)
          .firstChild.data
      );
      setText_ph_pass(
        xpath.select1("/resources/string[@name='text_ph_pass']", doc).firstChild
          .data
      );
      setText_forgot(
        xpath.select1("/resources/string[@name='text_forgot']", doc).firstChild
          .data
      );
      setText_newtotiq8(
        xpath.select1("/resources/string[@name='text_newtotiq8']", doc)
          .firstChild.data
      );
      setText_signin(
        xpath.select1("/resources/string[@name='text_signin']", doc).firstChild
          .data
      );
      setText_rightsReserved(
        xpath.select1("/resources/string[@name='text_rightsReserved']", doc)
          .firstChild.data
      );
      setText_having_trouble(
        xpath.select1("/resources/string[@name='text_having_trouble']", doc)
          .firstChild.data
      );
      setText_resendLink(
        xpath.select1("/resources/string[@name='text_resendLink']", doc)
          .firstChild.data
      );
      setText_close(
        xpath.select1("/resources/string[@name='text_close']", doc).firstChild
          .data
      );
      setText_verify_head(
        xpath.select1("/resources/string[@name='text_verify_head']", doc)
          .firstChild.data
      );
      setText_err_pass(
        xpath.select1("/resources/string[@name='text_err_pass']", doc)
          .firstChild.data
      );
      setText_err_emailorphone(
        xpath.select1("/resources/string[@name='text_err_emailorphone']", doc)
          .firstChild.data
      );
   
      setText_verify_msg_span(
        xpath.select1("/resources/string[@name='text_verify_msg_span']", doc)
          .firstChild.data
      );
      setText_verify_msg(
        xpath.select1("/resources/string[@name='text_verify_msg']", doc)
          .firstChild.data
      );
    } catch (ert) {}
  };
  return (
    <>
      <div className="form-wrapper" id="otherPages" onKeyPress={keyHandler}>
        <div className="app-form">
          <div className="app-form-sidebar">
            <Link to="/">
              <div className="sidebar-sign-logo">
                <img src={mainlogo} alt="" className="side-logo-outerPage" />
              </div>
            </Link>
            <div className="sign_sidebar_text">
              <h1>{login_title}</h1>
            </div>
          </div>
          <div className="app-form-content">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-10 col-md-10">
                  <div className="app-top-items">
                    <Link to="/">
                      <div className="sign-logo" id="logo">
                        <img src={mainlogo} alt="" />
                        <img className="logo-inverse" src={mainlogo} alt="" />
                      </div>
                    </Link>
                    <div className="app-top-right-link">
                      {text_newtotiq8}?
                      <Link className="sidebar-register-link" to="/signup">
                        {text_signup}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-xl-5 col-lg-6 col-md-7">
                  <div className="registration">
                    <div>
                      <h2 className="registration-title">
                        {text_signintotiq8}
                      </h2>
                      <div className="form-group mt-5">
                        <label className="form-label">
                          {text_email_phone_heading}
                          <span className="starsty">*</span>
                        </label>
                        <input
                          className="form-control h_50"
                          type="email"
                          placeholder={text_ph_email_phone}
                          onChange={(e) => [
                            setEmail(e.target.value),
                            setEmailErr(""),
                            setLoginErr(""),
                          ]}
                        />
                        <div className="d-flex mx-3 mt-3">
                          <FaInfoCircle />
                          &nbsp;
                          <span className="">
                            {text_phone_info}, +1xxxxxxxxx{" "}
                          </span>
                        </div>
                        <span className="validation-err">{emailErr}</span>
                      </div>
                      <div className="form-group mt-4">
                        <div className="field-password">
                          <label className="form-label">
                            {text_password}
                            <span className="starsty">*</span>
                          </label>
                          <Link
                            className="forgot-pass-link"
                            to="/forgot-password"
                          >
                            {text_forgot}?
                          </Link>
                        </div>
                        <div className="loc-group position-relative">
                          <input
                            className="form-control h_50"
                            type={passVisibility ? "text" : "password"}
                            placeholder={text_ph_pass}
                            onChange={(e) => [
                              setPasssword(e.target.value),
                              setPassErr(""),
                              setLoginErr(""),
                            ]}
                          />
                          <span className="pass-show-eye">
                            <button
                              className="reg-eye"
                              onClick={(e) => changeVisibility(e)}
                            >
                              {passVisibility === true ? (
                                <BsEyeFill className="showpassnight" />
                              ) : (
                                <BsEyeSlashFill className="showpassnight" />
                              )}
                            </button>
                          </span>
                        </div>
                        <span className="validation-err">{passErr}</span>
                      </div>

                      <button
                        className="main-btn btn-hover w-100 mt-4"
                        type="button"
                        onClick={loginHandler}
                        disabled={loginBtn ? true : false}
                      >
                        {text_signin} <FaSignInAlt />
                      </button>
                      <b>
                        {" "}
                        <center>
                          <span className="validation-err mt-2">
                            {loginErr}
                          </span>
                        </center>
                      </b>
                      <p>
                        <center>
                          <Link to="/support">
                            <u className="troubletext showpassnight">
                              {text_having_trouble}?
                            </u>
                          </Link>
                        </center>
                      </p>
                    </div>
                    <div className="new-sign-link">
                      {text_newtotiq8}?
                      <Link className="signup-link" to="/signup">
                        {text_signup}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="copyright-footer">
              © {moment(new Date()).format("YYYY")}, TiQ8. {text_rightsReserved}
              .
            </div>
          </div>
        </div>
      </div>
      <Dialog
        fullScreen={fullScreen}
        open={openloginEmailInfo}
        onClose={handleCloseloginEmailInfo}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" className="text-center">
          <FcFeedback className="Email-icon" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <h3 className="text-center">{text_verify_head}</h3>
            <h5 className="text-center">{text_verify_msg}</h5>
            <h5 className="text-center">{text_verify_msg_span}</h5>
            <br />
            <center>
              <Link
                onClick={() => [SendVerificationEmail()]}
                style={{ cursor: "pointer" }}
              >
                <u>{text_resendLink}</u>
              </Link>
              <p className="regErr-p  mb-2 mt-2">{helperr}</p>
            </center>
          </DialogContentText>
          <Divider />
        </DialogContent>
        <>
          <div className="d-flex justify-content-center mb-3">
            <button className="main-btn " onClick={handleCloseloginEmailInfo}>
              {text_close}
            </button>
          </div>
        </>
      </Dialog>
      <ToastContainer />
    </>
  );
}

export default Login;

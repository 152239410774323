import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import OrgDashboardSidebar from "./OrgDashboardSidebar";
import { Link, useNavigate } from "react-router-dom";
import { IoInformationCircle } from "react-icons/io5";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { MdEdit, MdOutlineDone } from "react-icons/md";
import {
  FaCamera,
  FaFacebookSquare,
  FaGlobe,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { API_DOMAIN } from "../../utils/GlobalConst";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { RxCross1 } from "react-icons/rx";
import {
  CitySelect,
  CountrySelect,
  StateSelect,
} from "react-country-state-city";
import img1 from "../../assets/img/event-imgs/big-1.jpg";
import { Box, Divider, Modal } from "@mui/material";
import Loader from "../../utils/Loader";
import { GlobalConstants } from "../../utils/GlobalConst";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function OrgAbout() {
  const [text_cancel, setText_cancel] = useState("Cancel");
  const [text_save, setText_save] = useState("Save");
  const [text_upload_image, setText_upload_image] = useState("Upload Image");
  const [text_orgadd, setText_orgadd] = useState("Organization Address");
  const [text_country, setText_country] = useState("Country");
  const [text_followus, setText_followus] = useState("Follow Us");
  const [text_orgdetails, setText_orgdetails] = useState(
    "Organization details"
  );
  const [text_lastname, setText_lastname] = useState("Last Name");
  const [text_firstname, setText_firstname] = useState("First Name");
  const [text_about, setText_about] = useState("About");
  const [text_website, setText_website] = useState("Website");
  const [text_update, setText_update] = useState("Update");
  const [text_noticeorgabout, setText_noticeorgabout] = useState(
    "Tell us about yourself and let people know who you are"
  );
  const [text_aboutmyorg, setText_aboutmyorg] = useState(
    "About My Organization"
  );
  const [text_youtube, setText_youtube] = useState("Youtube");
  const [text_email, setText_email] = useState("Email");
  const [text_phone, setText_phone] = useState("Phone");
  const [text_insta, setText_insta] = useState("Instagram");
  const [text_facebook, setText_facebook] = useState("Facebook");
  const [text_twitter, setText_twitter] = useState("Twitter");
  const [text_linkedin, setText_linkedin] = useState("LinkedIn");
  const [text_state, setText_state] = useState("State");
  const [text_city, setText_city] = useState("City");
  const [text_zipcode, setText_zipcode] = useState("Zipcode");

  const navigate = useNavigate();
  const UserMailID = sessionStorage.getItem("useremail");
  const [Website, setWebsite] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [twitter, setTwitter] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [youtube, setYoutube] = useState("");
  const [firstName, setFirstName] = useState("");
  const [bio, setBio] = useState("");
  const [lastName, setLastName] = useState("");
  const [country, setCountry] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [city, setCity] = useState("");
  const [countryID, setCountryID] = useState("");
  const [stateID, setStateID] = useState("");
  const [profileImgUrl, setProfileImgUrl] = useState("");
  const [coverImgUrl, setCoverImgUrl] = useState("");
  const [profileImg, setProfileImg] = useState();
  const [openEdit, setOpenEdit] = useState(false);
  const [openProfileImagePopup, setOpenProfileImagePopup] = useState(false);
  const handleOpenProfileImagePopup = () => setOpenProfileImagePopup(true);
  const handleCloseProfileImagePopup = () => setOpenProfileImagePopup(false);
  const [Loading, setLoading] = useState(false);

  const styleTwo = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    borderRadius: "20px",
  };

  const handleUpdateProfile = (url) => {
    var API_URL = API_DOMAIN + "user/profile/edit";

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    var data = {
      Website: Website,
      Facebook: facebook,
      Instagram: instagram,
      Twitter: twitter,
      LinkedIn: linkedin,
      Youtube: youtube,
      firstname: firstName,
      lastname: lastName,
      country: country,
      orgaddress: address,
      orgstate: state,
      zip: zipCode,
      city: city,
      Bio: bio,
      orgDetails: [
        {
          firstname: firstName,
          lastname: lastName,
          orgaddress: address,
          country: country,
          orgstate: state,
          zip: zipCode,
          city: city,
          Bio: bio,
        },
      ],
    };
    axios
      .patch(API_URL, data, headerConfig)
      .then(function (response) {
        console.log("Profile Data Update", response);
        if (response.status === 200) {
          sessionStorage.setItem("useremail", response.data.data.email);
          sessionStorage.setItem("usermobile", response.data.data.mobile);
          sessionStorage.setItem("userfirstname", response.data.data.firstname);
          sessionStorage.setItem("userlastname", response.data.data.lastname);

          toast.success(response?.data?.message, {
            autoClose: 2000,
            theme: "light",
          });
          setTimeout(() => {
            getProfielInfo();
            handleModalclose();
          }, [3000]);
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };

  const getProfielInfo = () => {
    var API_URL = API_DOMAIN + "user/profile/get";

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        var data = response?.data?.data;
        console.log("profile data", data);
        setWebsite(data?.Website);
        setFacebook(data?.Facebook);
        setInstagram(data?.Instagram);
        setTwitter(data?.Twitter);
        setLinkedin(data?.LinkedIn);
        setYoutube(data?.Youtube);
        setProfileImgUrl(data?.ProfileURL);
        setCoverImgUrl(data?.ProfileCoverURL);
        setFirstName(data?.firstname);
        setLastName(data?.lastname);
        setAddress(data?.orgDetails[0].orgaddress);
        setCountry(data?.orgDetails[0].country);
        setState(data?.orgDetails[0].orgstate);
        setCity(data?.orgDetails[0].city);
        setZipCode(data?.orgDetails[0].zip);
        setBio(data?.Bio);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          });
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  useEffect(() => {
    getProfielInfo();
  }, []);

  const imguplpoadValidation = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type;
      if (fileType === "image/jpeg" || fileType === "image/png") {
        setProfileImg(file);
        // You can perform further actions with the selected file here
      } else {
        setProfileImg(null);
        alert("Please upload only JPEG or PNG files.");
      }
    }
  };

  const handleProfileImgUpload = () => {
    var API_URL = API_DOMAIN + "file/profile";
    var formData = new FormData();
    formData.append("att_file", profileImg);
    console.log(formData);

    let headerConfig = {
      headers: {
        "Content-Type": "form-data",
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .post(API_URL, formData, headerConfig)
      .then(function (response) {
        console.log("Profile Img Upload", response);
        if (response.status === 200) {
          handleUpdateProfileurl(response?.data?.data?.Location);
          handleCloseProfileImagePopup();
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
          setTimeout(() => {
            setLoading(false);
            handleCloseProfileImagePopup();
            setProfileImg("");
          });
        }
      })
      .then(function () {});
  };
  const handleUpdateProfileurl = (url) => {
    var API_URL = API_DOMAIN + "user/profile/image/edit";

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    var data = {
      ProfileURL: url,
    };
    axios
      .patch(API_URL, data, headerConfig)
      .then(function (response) {
        console.log("Url Update", response);
        if (response.status === 200) {
          getProfielInfo();
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  const handleModalclose = () => {
    document.getElementById("org-profile-update-pop").classList.remove("show");
    document
      .getElementById("org-profile-update-pop")
      .removeAttribute("aria-labelledby");
    document.getElementById("org-profile-update-pop").removeAttribute("role");
    document
      .getElementById("org-profile-update-pop")
      .removeAttribute("data-bs-toggle");
    document.getElementById("org-profile-update-pop").style.display = "none";
  };
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);

  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_aboutmyorg(
        xpath.select1("/resources/string[@name='text_aboutmyorg']", doc)
          .firstChild.data
      );
      setText_upload_image(
        xpath.select1("/resources/string[@name='text_upload_image']", doc)
          .firstChild.data
      );
      setText_save(
        xpath.select1("/resources/string[@name='text_save']", doc).firstChild
          .data
      );
      setText_cancel(
        xpath.select1("/resources/string[@name='text_cancel']", doc).firstChild
          .data
      );
      setText_orgadd(
        xpath.select1("/resources/string[@name='text_orgadd']", doc).firstChild
          .data
      );
      setText_country(
        xpath.select1("/resources/string[@name='text_country']", doc).firstChild
          .data
      );
      setText_followus(
        xpath.select1("/resources/string[@name='text_followus']", doc)
          .firstChild.data
      );
      setText_orgdetails(
        xpath.select1("/resources/string[@name='text_orgdetails']", doc)
          .firstChild.data
      );
      setText_lastname(
        xpath.select1("/resources/string[@name='text_lastname']", doc)
          .firstChild.data
      );
      setText_firstname(
        xpath.select1("/resources/string[@name='text_firstname']", doc)
          .firstChild.data
      );
      setText_about(
        xpath.select1("/resources/string[@name='text_about']", doc).firstChild
          .data
      );
      setText_website(
        xpath.select1("/resources/string[@name='text_website']", doc).firstChild
          .data
      );
      setText_update(
        xpath.select1("/resources/string[@name='text_update']", doc).firstChild
          .data
      );
      setText_noticeorgabout(
        xpath.select1("/resources/string[@name='text_noticeorgabout']", doc)
          .firstChild.data
      );

      setText_youtube(
        xpath.select1("/resources/string[@name='text_aboutmyorg']", doc)
          .firstChild.data
      );
      setText_zipcode(
        xpath.select1("/resources/string[@name='text_zipcode']", doc).firstChild
          .data
      );
      setText_city(
        xpath.select1("/resources/string[@name='text_city']", doc).firstChild
          .data
      );
      setText_state(
        xpath.select1("/resources/string[@name='text_state']", doc).firstChild
          .data
      );
      setText_linkedin(
        xpath.select1("/resources/string[@name='text_linkedin']", doc)
          .firstChild.data
      );
      setText_twitter(
        xpath.select1("/resources/string[@name='text_twitter']", doc).firstChild
          .data
      );
      setText_facebook(
        xpath.select1("/resources/string[@name='text_facebook']", doc)
          .firstChild.data
      );
      setText_insta(
        xpath.select1("/resources/string[@name='text_insta']", doc).firstChild
          .data
      );
      setText_phone(
        xpath.select1("/resources/string[@name='text_phone']", doc).firstChild
          .data
      );
      setText_email(
        xpath.select1("/resources/string[@name='text_email']", doc).firstChild
          .data
      );
    } catch (ert) {}
  };
  return (
    <>
      <Header />
      <OrgDashboardSidebar />
      <div
        className="modal fade  popup-about"
        id="org-profile-update-pop"
        tabindex="-1"
        aria-labelledby={openEdit ? "orgProfileUpdatepopLabel" : ""}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-medium-2 modal-dialog-scrollable modal-dialog-centered modal-sm-height modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="orgProfileUpdatepopLabel">
                {text_orgdetails}
              </h5>
              <button
                type="button"
                className="close-model-btn"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <RxCross1 />
              </button>
            </div>
            <div className="modal-body">
              <div className="model-content main-form">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="form-group text-center mt-5">
                      <div className="user-avatar-img">
                        <img
                          src={profileImgUrl === "" ? img1 : profileImgUrl}
                          alt=""
                        />
                        <div
                          className="avatar-img-btn camera-icon-sty"
                          onClick={() => handleOpenProfileImagePopup()}
                        >
                          <input type="file" />
                          <label for="avatar-img">
                            <FaCamera />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label">{text_firstname}*</label>
                      <input
                        className="form-control seasontextnight h_40"
                        type="text"
                        placeholder=""
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label">{text_lastname}*</label>
                      <input
                        className="form-control seasontextnight h_40"
                        type="text"
                        placeholder=""
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label">{text_about}*</label>
                      <textarea
                        className="form-textarea"
                        placeholder={text_about}
                        value={bio}
                        onChange={(e) => setBio(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label">{text_email}*</label>
                      <input
                        className="form-control seasontextnight h_40"
                        type="text"
                        placeholder=""
                        value={sessionStorage.getItem("useremail")}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label">{text_phone}*</label>
                      <input
                        className="form-control seasontextnight h_40"
                        type="text"
                        placeholder=""
                        value={sessionStorage.getItem("usermobile")}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label">{text_website}*</label>
                      <input
                        className="form-control seasontextnight h_40"
                        type="text"
                        placeholder=""
                        value={Website}
                        onChange={(e) => setWebsite(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label">{text_facebook}*</label>
                      <input
                        className="form-control seasontextnight h_40"
                        type="text"
                        placeholder=""
                        value={facebook}
                        onChange={(e) => setFacebook(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label">{text_insta}*</label>
                      <input
                        className="form-control seasontextnight h_40"
                        type="text"
                        placeholder=""
                        value={instagram}
                        onChange={(e) => setInstagram(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label">{text_twitter}*</label>
                      <input
                        className="form-control seasontextnight h_40"
                        type="text"
                        placeholder=""
                        value={twitter}
                        onChange={(e) => setTwitter(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label">{text_linkedin}*</label>
                      <input
                        className="form-control seasontextnight h_40"
                        type="text"
                        placeholder=""
                        value={linkedin}
                        onChange={(e) => setLinkedin(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label">{text_youtube}*</label>
                      <input
                        className="form-control seasontextnight h_40"
                        type="text"
                        placeholder=""
                        value={youtube}
                        onChange={(e) => setYoutube(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <h4 className="address-title">{text_orgadd}</h4>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label">{text_orgadd} 1*</label>
                      <input
                        className="form-control seasontextnight h_50"
                        type="text"
                        placeholder=""
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group main-form mt-4">
                      <label className="form-label">{text_country}*</label>
                      <CountrySelect
                        onChange={(e) => [
                          setCountryID(e.id),
                          setCountry(e.name),
                        ]}
                        showFlag={false}
                        className="orgaboutselect"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label">{text_state}*</label>
                      <StateSelect
                        countryid={countryID}
                        onChange={(e) => [setStateID(e.id), setState(e.name)]}
                        className="form-control seasontextnight h_40"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label">{text_city}*</label>
                      <CitySelect
                        countryid={countryID}
                        stateid={stateID}
                        onChange={(e) => [setCity(e.name)]}
                        className="form-control seasontextnight h_40"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group mt-4">
                      <label className="form-label">{text_zipcode}*</label>
                      <input
                        className="form-control seasontextnight h_50"
                        type="text"
                        placeholder=""
                        value={zipCode}
                        onChange={(e) => setZipCode(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="co-main-btn min-width btn-hover h_40"
                data-bs-target="#org-profile-update-pop"
                data-bs-toggle="modal"
                data-bs-dismiss="modal"
                onClick={() => handleModalclose()}
              >
                {text_cancel}
              </button>
              <button
                type="button"
                data-bs-target="#org-profile-update-pop"
                data-bs-toggle="modal"
                data-bs-dismiss="modal"
                className="main-btn min-width btn-hover h_40 profile-update-btn"
                onClick={handleUpdateProfile}
              >
                {text_update}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div id="otherPages">
        <div className="wrapper wrapper-body" id="wrapper-body">
          <div className="dashboard-body">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-main-title">
                    <h3>
                      <IoInformationCircle /> {text_aboutmyorg}
                    </h3>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="conversion-setup">
                    <div className="main-card mt-5">
                      <div className="bp-title position-relative">
                        <h4>{text_about}</h4>
                        <div className="profile-edit-btn">
                          <button
                            className="btn"
                            data-bs-toggle="modal"
                            data-bs-target="#org-profile-update-pop"
                            onClick={() => setOpenEdit(true)}
                          >
                            <MdEdit className="orgabout-icon" />
                          </button>
                        </div>
                      </div>
                      <div className="about-details">
                        <div className="about-step text-center">
                          <div className="user-avatar-img">
                            <img
                              src={profileImgUrl === "" ? img1 : profileImgUrl}
                              alt=""
                            />
                          </div>
                          <div className="user-dts">
                            <h4 className="user-name">
                              {firstName + " " + lastName}
                              <span className="verify-badge">
                                <MdOutlineDone className="verify-person" />
                              </span>
                            </h4>
                            <span className="user-email">{UserMailID}</span>
                          </div>
                        </div>
                        <div className="about-step">
                          <h5>{text_noticeorgabout}</h5>
                          <p className="mb-0">{bio}</p>
                        </div>
                        <div className="about-step">
                          <h5>{text_followus}</h5>
                          <div className="social-links">
                            <Link
                              to="#"
                              className="social-link"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title=""
                              data-bs-original-title="Facebook"
                              aria-label="Facebook"
                            >
                              <FaFacebookSquare />
                            </Link>
                            <Link
                              to="#"
                              className="social-link"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title=""
                              data-bs-original-title="Instagram"
                              aria-label="Instagram"
                            >
                              <FaInstagram />
                            </Link>
                            <Link
                              to="#"
                              className="social-link"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title=""
                              data-bs-original-title="Twitter"
                              aria-label="Twitter"
                            >
                              <FaTwitter />
                            </Link>
                            <Link
                              to="#"
                              className="social-link"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title=""
                              data-bs-original-title="LinkedIn"
                              aria-label="LinkedIn"
                            >
                              <FaLinkedinIn />
                            </Link>
                            <Link
                              to="#"
                              className="social-link"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title=""
                              data-bs-original-title="Youtube"
                              aria-label="Youtube"
                            >
                              <FaYoutube />
                            </Link>
                            <Link
                              to="#"
                              className="social-link"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title=""
                              data-bs-original-title="Website"
                              aria-label="Website"
                            >
                              <FaGlobe />
                            </Link>
                          </div>
                        </div>
                        <div className="about-step">
                          <h5>{text_orgadd}</h5>
                          <p className="mb-0">
                            {address}, {city}, {state}, {zipCode}, {country}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={openProfileImagePopup}
        onClose={handleCloseProfileImagePopup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Upload-image-Modal"
      >
        <Box
          sx={styleTwo}
          id="header-registermodal"
          className="Upload-image-event"
        >
          <div>
            <div className="d-flex justify-content-end">
              <IoIosCloseCircleOutline
                className="closeIcon-venue"
                onClick={() => [handleCloseProfileImagePopup()]}
              />
            </div>
            <h3>{text_upload_image}</h3>
            <Divider />
            {Loading === true ? (
              <>
                <center className=" mt-3 mb-3">
                  <Loader />
                </center>
              </>
            ) : (
              <>
                <div className="container">
                  <div className="text-center form-group mt-3">
                    <input
                      type="file"
                      accept="image/*"
                      className="form-control seasontextnight h_50 uploadinput"
                      onChange={imguplpoadValidation}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-center gap-3 mt-3">
                  <button
                    onClick={() => handleCloseProfileImagePopup()}
                    className="cancelBtn"
                  >
                    {" "}
                    {text_cancel}
                  </button>

                  <button
                    onClick={handleProfileImgUpload}
                    className="resell-Btn"
                  >
                    {" "}
                    {text_save}
                  </button>
                </div>
              </>
            )}
          </div>
        </Box>
      </Modal>
      <ToastContainer />
    </>
  );
}

export default OrgAbout;

import React, { useEffect, useState } from "react";
import "./AllEvent.css";
import img1 from "../../assets/img/event-imgs/big-1.jpg";
import { BsFilterLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { API_DOMAIN, GlobalConstants } from "../../utils/GlobalConst";
import axios from "axios";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { IoLocationOutline } from "react-icons/io5";
import DatePicker from "react-datepicker";
import { MdDateRange } from "react-icons/md";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import PopularEvent from "./PopularEvent";

const xpath = require("xpath");
const dom = require("xmldom").DOMParser;

function UpcomingEvent() {
  const navigate = useNavigate();
  const [allEventData, setAllEventData] = useState([]);
  const [dataforfilter, setDataforfilter] = useState([]);
  const [text_BrowseMore, setTtext_BrowseMore] = useState("Browse More Events");
  const [startDate, setStartDate] = useState(new Date());
  const [events, setevents] = useState([]);
  const [calendarview, setCalendarview] = useState(false);
  const [showUpcoming, setShowUpcoming] = useState(true);

  //language variables
  const [text_upcomingevents, setText_upcomingevents] =
    useState("Upcoming Events");
  const [text_calendarview, setText_calendarview] = useState("Calendar View");
  const [text_Sort, setText_Sort] = useState("Sort");
  const [text_Upcoming, setText_Upcoming] = useState("Upcoming");
  const [text_mostpopular, setText_mostpopular] = useState("Most Popular");
  const [text_Recommended, setText_Recommended] = useState("Recommended");
  const [text_noeventfound, setText_noeventfound] = useState("No Event Found");
  const [text_buytickets, setText_buytickets] = useState("Buy Tickets");
  const [text_location, setText_location] = useState("Location");

  const getUpcomingEvent = () => {
    var API_URL = API_DOMAIN + "event/get/futureevent";
    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    axios
      .get(API_URL, headerConfig)
      .then((response) => {
        console.log("upcomingevents ", response.data.data.event);
        var res = response.data.data;
        const sortdata = res.event;
        sortdata.sort(
          (a, b) => new Date(a?.StartDateSort) - new Date(b?.StartDateSort)
        );
        console.log("sortdata", sortdata);
        setAllEventData(sortdata);
        setDataforfilter(sortdata);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  useEffect(() => {
    getUpcomingEvent();
    if (sessionStorage.getItem("token") !== null) {
      console.log("in if", sessionStorage.getItem("token"));
    }
  }, []);
  const handleDatesSet = (dateInfo) => {
    const startDate = new Date(dateInfo.start); // Start of visible range
    const formattedMonth = moment(startDate).format("MM YYYY"); // Format for the API
    console.log(formattedMonth);

    const url = API_DOMAIN + "event/get/futureevent";
    console.log(url);

    getCalendarData(url);
    console.log("helllooooo");
  };
  const getCalendarData = (url) => {
    var API_URL = url;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    axios
      .get(API_URL, headerConfig)
      .then((response) => {
        console.log("month wise events ", response.data.data.event);

        var res = response.data.data.event;
        const formattedEvents = res.map((event) => ({
          id: event.event_unique_id,
          img: event.EventImagePath,
          title: event.EventTitle, // Adjust based on your API response
          start: event.StartDate, // Ensure date is in ISO 8601 format
          end: event.EndDate,
          starttime: event.StartTime, // Ensure date is in ISO 8601 format
          endtime: event.EndTime, // Optional
          // address1: `${event.venuedetails[0].venuename},${event.venuedetails[0].address}, ${event.venuedetails[0].city}`,
          address2: ` ${event.venuedetails[0].state},${event.venuedetails[0].country},${event.venuedetails[0].zipcode}`,
          url: `/event-details/${event.event_unique_id}`,
        }));
        console.log(formattedEvents.address);

        setevents(formattedEvents);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };
  const handleEventClick = (info) => {
    info.jsEvent.preventDefault(); // Prevent default behavior
    const eventUrl = info.event.url; // Access the custom URL
    console.log(eventUrl);

    if (eventUrl) {
      navigate(eventUrl); // Redirect to the event details page
    }
  };

  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);

  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);

      setText_upcomingevents(
        xpath.select1("/resources/string[@name='text_upcomingevents']", doc)
          .firstChild.data
      );
      setText_calendarview(
        xpath.select1("/resources/string[@name='text_calendarview']", doc)
          .firstChild.data
      );
      setText_Sort(
        xpath.select1("/resources/string[@name='text_Sort']", doc).firstChild
          .data
      );
      setText_Upcoming(
        xpath.select1("/resources/string[@name='text_Upcoming']", doc)
          .firstChild.data
      );
      setText_mostpopular(
        xpath.select1("/resources/string[@name='text_mostpopular']", doc)
          .firstChild.data
      );
      setText_Recommended(
        xpath.select1("/resources/string[@name='text_Recommended']", doc)
          .firstChild.data
      );
      setText_noeventfound(
        xpath.select1("/resources/string[@name='text_noeventfound']", doc)
          .firstChild.data
      );
      setText_buytickets(
        xpath.select1("/resources/string[@name='text_buytickets']", doc)
          .firstChild.data
      );
      setText_location(
        xpath.select1("/resources/string[@name='text_location']", doc)
          .firstChild.data
      );
    } catch (ert) {}
  };

  console.log("first", allEventData);
  const dateSelectFilter = (date) => {
    console.log(moment.utc(date).format("DD MMM, yyyy"));
    const arr = dataforfilter.filter(
      (e) =>
        moment.utc(e?.StartDate).format("DD MMM, yyyy") ===
        moment.utc(date).format("DD MMM, yyyy")
    );
    setAllEventData(arr);
    console.log(arr);
  };
  const shortByFilter = (val) => {
    if (val === "upcoming") {
      setShowUpcoming(true);
    } else if (val === "mostpopular") {
      setShowUpcoming(false);
    }
  };
  return (
    <>
      {showUpcoming ? (
        <>
          <div className="main-title mt-4">
            <h3>{text_upcomingevents}</h3>
          </div>
          <div className="d-flex flex-column gap-3">
            <div className="d-flex justify-content-between flex-wrap gap-2">
              <div className="d-flex gap-3">
                <div
                  className="form-control filter-team d-flex"
                  style={{ zIndex: 999 }}
                >
                  <MdDateRange className="icon-team" />
                  <DatePicker
                    className="h_40 mx-1 form-control form-control-BR w-100"
                    selected={startDate}
                    dateFormat="dd MMM,yyyy"
                    onChange={(date) => [
                      setStartDate(date),
                      dateSelectFilter(date),
                    ]}
                  />
                </div>
                <div className="form-control filter-team d-flex">
                  <IoLocationOutline className="icon-team" />
                  <input
                    type="text"
                    className="h_40 form-control form-control-BR w-100"
                    name=""
                    id=""
                    placeholder={text_location}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-end gap-3">
                <div className="form-control filter-team w-100 d-flex">
                  <BsFilterLeft className="icon-team" />
                  {calendarview === true ? (
                    <>
                      <button
                        className="h_40 form-control form-control-BR w-100 text-start"
                        onClick={() => setCalendarview(false)}
                      >
                        {text_upcomingevents}
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="h_40 form-control form-control-BR w-100 text-start"
                        onClick={() => setCalendarview(true)}
                      >
                        {text_calendarview}
                      </button>
                    </>
                  )}
                </div>
                <div className="form-control filter-team  w-100 d-flex">
                  <BsFilterLeft className="icon-team" />
                  <select
                    name=""
                    id=""
                    onChange={(e) => shortByFilter(e.target.value)}
                    className="h_40 form-control form-control-BR w-100"
                  >
                    <option value="">{text_Sort}</option>
                    <option value="upcoming" className="form-control-BR">
                      {" "}
                      {text_Upcoming}
                    </option>
                    <option value="mostpopular" className="form-control-BR">
                      {" "}
                      {text_mostpopular}
                    </option>
                    <option value="recommended" className="form-control-BR">
                      {" "}
                      {text_Recommended}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            {calendarview === true ? (
              <>
                <div className="month-calendar-style">
                  <FullCalendar
                    timeZone="UTC"
                    plugins={[dayGridPlugin, interactionPlugin]}
                    initialView="dayGridMonth"
                    events={events}
                    eventClick={handleEventClick}
                    datesSet={handleDatesSet}
                    dayHeaderContent={(args) => {
                      // Format weekdays as 'Mon', 'Tue', etc.
                      return new Intl.DateTimeFormat("en", {
                        weekday: "short",
                      }).format(args.date);
                    }}
                    dayCellContent={(args) => {
                      // Format and display only the day number
                      return args.date.getDate(); // Get only the day (e.g., 1, 2, 3, etc.)
                    }}
                    weekends={true} // Show weekends
                    eventContent={(eventInfo) => (
                      <div className="calendar-event">
                        <h4 className="calendar-event-title">
                          {eventInfo.event.title}
                          <br />
                          <p>
                            {" "}
                            {moment(eventInfo.event.starttime).format(
                              "hh:mm A"
                            )}
                          </p>
                        </h4>
                      </div>
                    )}
                  />
                </div>
              </>
            ) : (
              <>
                {allEventData.length === 0 ? (
                  <>
                    <div className="container text-center mt-5">
                      <h2>{text_noeventfound}</h2>{" "}
                    </div>
                  </>
                ) : (
                  <>
                    {allEventData.map((e) => {
                      return (
                        <>
                          <div className="new-card-team d-flex flex-wrap justify-content-between  mt-3">
                            <div className="d-flex flex-wrap">
                              <img
                                src={
                                  e.EventImagePath === "path"
                                    ? img1
                                    : e.EventImagePath
                                }
                                alt=""
                                className="card-img-team"
                              />
                              <div className="m-3 responsive-team-card">
                                <h3> {e.EventTitle}</h3>
                                <span>
                                  {moment
                                    .utc(e?.StartDate)
                                    .format("DD MMM, yyyy")}{" "}
                                  • {moment.utc(e.StartDate).format("ddd")},{" "}
                                  {moment.utc(e?.StartTime).format("hh:mm a")}
                                </span>{" "}
                                <br />
                                <span>
                                  <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    stroke-width="0"
                                    viewBox="0 0 512 512"
                                    className="location-icon"
                                    height="10px"
                                    width="10px"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="22"
                                      d="M256 48c-79.5 0-144 61.39-144 137 0 87 96 224.87 131.25 272.49a15.77 15.77 0 0025.5 0C304 409.89 400 272.07 400 185c0-75.61-64.5-137-144-137z"
                                    ></path>
                                    <circle
                                      cx="256"
                                      cy="192"
                                      r="48"
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="32"
                                    ></circle>
                                  </svg>
                                  {e?.venuedetails[0].venuename} ({" "}
                                  {e?.venuedetails[0].address},{" "}
                                  {e?.venuedetails[0].city},{" "}
                                  {e?.venuedetails[0].state},{" "}
                                  {e?.venuedetails[0].country},{" "}
                                  {e?.venuedetails[0].zipcode})
                                </span>
                              </div>
                            </div>
                            <button
                              className="buybtn-team mx-3"
                              onClick={() =>
                                navigate(`/event-details/${e?.event_unique_id}`)
                              }
                            >
                              {text_buytickets}
                            </button>
                          </div>
                        </>
                      );
                    })}
                  </>
                )}
              </>
            )}
          </div>
        </>
      ) : (
        <>
          <PopularEvent />
        </>
      )}

      <ToastContainer />
    </>
  );
}

export default UpcomingEvent;

import React, { useEffect, useRef, useState } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import { FcHome } from "react-icons/fc";
import mainlogo from "../../assets/img/tiq8-Logo-white.png";
import img1 from "../../assets/img/event-imgs/big-1.jpg";

import QRCode from "react-qr-code";
import { FaCut } from "react-icons/fa";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import { GlobalConstants } from "../../utils/GlobalConst";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function SeasonInvoice() {
  const [text_seatname, setText_seatname] = useState("Seat Name");
  const [text_backtohome, setText_backtohome] = useState("Back to home");
  const [text_download, setText_download] = useState("Download");
  const [text_invoice, setText_invoice] = useState("Invoice");
  const [text_invoiceto, setText_invoiceto] = useState("Invoice to");
  const [text_invoiceid, setText_invoiceid] = useState("Invoice ID");
  const [text_total, setText_total] = useState("Total");
  const [text_events, setText_events] = useState("Events");
  const [text_unitPrice, setText_unitPrice] = useState("Unit Price");
  const [text_powerBy, setText_powerBy] = useState("Powered by TiQ8");
  const [text_qty, setText_qty] = useState("Qty");
  const [text_h, setText_h] = useState("h");
  const [text_duration, setText_duration] = useState("Duration");
  const [text_seasonName, setText_seasonName] = useState("Season Name");
  const ticketData = JSON.parse(sessionStorage.getItem("seasonTicket"));

  const SeasonDetails = JSON.parse(sessionStorage.getItem("seasonDetails"));

  const seatIdList = JSON.parse(sessionStorage.getItem("seasonSeatId"));
  console.log(
    ticketData[0].eventdetails[0].EventTitle,
    seatIdList.length,
    SeasonDetails
  );
  const [back, setBack] = useState("#FFFFFF");
  const [fore, setFore] = useState("#000000");
  const [size, setSize] = useState(128);
  const [eventDetails, setEventDetails] = useState(
    SeasonDetails[0].event_details
  );
  const [paymentId, setPaymentId] = useState(ticketData[0].paymentid);
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  console.log("object", eventDetails);
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);

  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_seatname(
        xpath.select1("/resources/string[@name='text_seatname']", doc)
          .firstChild.data
      );
      setText_invoice(
        xpath.select1("/resources/string[@name='text_invoice']", doc).firstChild
          .data
      );
      setText_invoiceto(
        xpath.select1("/resources/string[@name='text_invoiceto']", doc)
          .firstChild.data
      );
      setText_invoiceid(
        xpath.select1("/resources/string[@name='text_invoiceid']", doc)
          .firstChild.data
      );
      setText_total(
        xpath.select1("/resources/string[@name='text_total']", doc).firstChild
          .data
      );
      setText_backtohome(
        xpath.select1("/resources/string[@name='text_backtohome']", doc)
          .firstChild.data
      );
      setText_download(
        xpath.select1("/resources/string[@name='text_download']", doc)
          .firstChild.data
      );
      setText_events(
        xpath.select1("/resources/string[@name='text_events']", doc).firstChild
          .data
      );
      setText_unitPrice(
        xpath.select1("/resources/string[@name='text_unitPrice']", doc)
          .firstChild.data
      );
      setText_qty(
        xpath.select1("/resources/string[@name='text_qty']", doc).firstChild
          .data
      );
      setText_seasonName(
        xpath.select1("/resources/string[@name='text_seasonName']", doc)
          .firstChild.data
      );
      setText_powerBy(
        xpath.select1("/resources/string[@name='text_powerBy']", doc).firstChild
          .data
      );
      setText_h(
        xpath.select1("/resources/string[@name='text_h']", doc).firstChild.data
      );
      setText_duration(
        xpath.select1("/resources/string[@name='text_duration']", doc)
          .firstChild.data
      );
    } catch (ert) {}
  };
  return (
    <>
      <Header />
      <div className="invoice clearfix p-80 mt-5">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="d-flex justify-content-center gap-2">
              <center>
                <Link to="/" className="main-btn btn-hover h_40 mb-3 ">
                  <FcHome className="homeicon" /> {text_backtohome}
                </Link>
              </center>

              <center>
                <Link
                  className="main-btn btn-hover h_40 mb-3"
                  onClick={handlePrint}
                >
                  {text_download}
                </Link>
              </center>
            </div>
            <div className="col-lg-8 col-md-10" ref={componentRef}>
              <div className="invoice-header justify-content-between">
                <div className="invoice-header-logo">
                  <img src={mainlogo} alt="invoice-logo" />
                </div>
              </div>
              <div className="invoice-body">
                <div className="invoice_dts">
                  <div className="row">
                    <div className="col-md-12">
                      <h2 className="invoice_title">{text_invoice}</h2>
                    </div>
                    <div className="col-md-6">
                      <div className="vhls140">
                        <ul>
                          <li>
                            <div className="vdt-list">
                              {text_invoiceto}{" "}
                              {sessionStorage.getItem("userfirstname")}{" "}
                              {sessionStorage.getItem("userlastname")}
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="vhls140">
                        <ul>
                          <li>
                            <div className="vdt-list">
                              {text_invoiceid} : {paymentId}
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="main-table bt_40">
                  <div className="table-responsive">
                    <table className="table">
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">{text_seasonName}</th>
                          <th scope="col">{text_qty}</th>
                          <th scope="col">{text_unitPrice}</th>
                          <th scope="col">{text_total}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {console.log(eventDetails)}

                        {SeasonDetails.map((ed, index) => {
                          return (
                            <>
                              <tr>
                                <td>{index + 1}</td>
                                <td>
                                  <Link
                                    to="#"
                                    target=""
                                    className="invoice-link-sty showpassnight"
                                  >
                                    {ed?.ticketname}
                                  </Link>
                                </td>
                                <td>{seatIdList.length}</td>
                                <td>
                                  {" "}
                                  {ed?.currency}{" "}
                                  {Number(ed?.price)
                                    .toFixed(2)
                                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                                </td>
                                <td>
                                  {" "}
                                  {ed?.currency}{" "}
                                  {Number(seatIdList.length * ed?.price)
                                    .toFixed(2)
                                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                                </td>
                              </tr>
                            </>
                          );
                        })}

                        <tr>
                          <td colspan="1"></td>
                          <td colspan="5">
                            <div className="user_dt_trans text-end pe-xl-4">
                              <div className="totalinv2">
                                {text_invoice} {text_total} :
                                {SeasonDetails[0].currency}{" "}
                                {Number(
                                  SeasonDetails[0].price * seatIdList.length
                                )
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-md-12">
                    <div className="vhls140">
                      <ul>
                        <li>
                          <div className="vdt-list">
                            <b>{text_events}</b> :{" "}
                            {eventDetails.map((v) => {
                              return <>{v?.EventTitle}, </>;
                            })}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="invoice_footer">
                  {ticketData.map((v) => {
                    return (
                      <>
                        <div className="cut-line">
                          <i className="fa-solid fa-scissors">
                            <FaCut />
                          </i>
                        </div>
                        <div className="main-card">
                          <div className="row g-0">
                            <div className="col-lg-7">
                              <div className="event-order-dt p-4">
                                <div className="event-thumbnail-img">
                                  <img
                                    src={
                                      v?.eventdetails[0]?.EventImagePath ===
                                      "path"
                                        ? img1
                                        : v?.eventdetails[0].EventImagePath
                                    }
                                    alt=""
                                  />
                                </div>
                                <div className="event-order-dt-content">
                                  <h5>{v?.eventdetails[0].EventTitle}</h5>
                                  <span>
                                    {" "}
                                    {moment
                                      .utc(v?.eventdetails[0].StartDate)
                                      .format("DD MMM,yyyy") ===
                                    moment
                                      .utc(v?.eventdetails[0].EndDate)
                                      .format("DD MMM,yyyy") ? (
                                      <>
                                        {" "}
                                        {moment
                                          .utc(v?.eventdetails[0].StartDate)
                                          .format("ddd, DD MMM, yyyy")}
                                        {moment
                                          .utc(v?.eventdetails[0].StartTime)
                                          .format("hh:mm a")}
                                        &nbsp;.
                                      </>
                                    ) : (
                                      <>
                                        {" "}
                                        {moment
                                          .utc(v?.eventdetails[0].StartDate)
                                          .format("ddd, DD MMM, yyyy")}
                                        ,{" "}
                                        {moment
                                          .utc(v?.eventdetails[0].StartTime)
                                          .format("hh:mm a")}{" "}
                                        -{" "}
                                        {moment
                                          .utc(v?.eventdetails[0].EndDate)
                                          .format("ddd, DD MMM, yyyy")}
                                        ,{" "}
                                        {moment
                                          .utc(v?.eventdetails[0].StartTime)
                                          .format("hh:mm a")}{" "}
                                        &nbsp;. {text_duration}{" "}
                                        {new Date(
                                          moment.utc(v?.eventdetails[0].EndTime)
                                        ).getHours() -
                                          new Date(
                                            moment.utc(
                                              v?.eventdetails[0].StartTime
                                            )
                                          ).getHours()}{" "}
                                        {text_h}
                                      </>
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-5">
                              <div className="QR-dt p-4">
                                <ul className="QR-counter-type">
                                  <li></li>
                                  <li>{text_seatname}</li>
                                  <li>{v?.seat_name}</li>
                                </ul>
                                <div className="QR-scanner">
                                  <QRCode
                                    value={
                                      "Event Name:--" +
                                      v?.eventdetails[0].EventTitle +
                                      "Seat:--" +
                                      v?.seat_name +
                                      "Start Date:--" +
                                      moment
                                        .utc(v?.eventdetails[0].StartDate)
                                        .format("ddd, DD MMM, yyyy") +
                                      "Start Time:--" +
                                      moment
                                        .utc(v?.eventdetails[0].StartTime)
                                        .format("hh:mm a")
                                    }
                                    bgColor={back}
                                    fgColor={fore}
                                    size={size === "" ? 0 : size}
                                  />
                                </div>
                                <p>{text_powerBy}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default SeasonInvoice;

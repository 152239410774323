import React, { useEffect, useState } from "react";
import "../ResellEvent/ResellEvent.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import { GrUpload } from "react-icons/gr";
import { toast } from "react-toastify";
import axios from "axios";
import { API_DOMAIN, GlobalConstants } from "../../utils/GlobalConst";
import img1 from "../../assets/img/event-imgs/big-1.jpg";
import moment from "moment";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function ResellTicket() {
  const { eventID } = useParams();
  const navigate = useNavigate();
  const [Data, setData] = useState([]);
  const [eventDetail, setEventDetail] = useState("");
  const [nextToken, setNextToken] = useState("");
  const [hasMoreEvents, setHasMoreEvents] = useState();
  // language variable
  const [text_home, setText_home] = useState("Home");
  const [text_resellexpo, setText_resellexpon] = useState("Resell Exp on");
  const [text_BrowseMore, setTtext_BrowseMore] = useState("Browse More Events");

  const [text_viewdetails, setText_viewdetails] = useState("View Details");
  const [text_reselltickets, setText_reselltickets] =
    useState("Resell Tickets");
  const [text_resellevents, setText_resellevents] = useState("Resell Events");

  useEffect(() => {
    var event_unique_id = eventID;
    var API_URL =
      API_DOMAIN + "ticket/allresellticket?event_unique_id=" + event_unique_id;

    axios
      .get(API_URL)
      .then((response) => {
        console.log("all events ", response.data.data);
        var res = response.data.data;
        setData(res);
        EventDetailHandel(res[0]?.event_unique_id);
        setNextToken(res.nexttoken);
        setHasMoreEvents(res.hasMoreEvents);
        console.log("first", Data);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.message);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      });
  }, [1000]);
  const EventDetailHandel = (eventID) => {
    var type = "select";
    var API_URL =
      API_DOMAIN + "event/public?type=" + type + "&event_unique_id=" + eventID;
    console.log(eventID);
    axios
      .get(API_URL)
      .then(function (response) {
        console.log("Detaied Event", response.data.data);
        var res = response.data.data;
        setEventDetail(res);
      })
      .catch(function (error) {
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };

  const getMoreEventCard = (nextToken, D) => {
    var type = "selectall";
    var API_URL =
      API_DOMAIN + `event/public?type=${type}&limit=12&nexttoken=${nextToken}`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        var res = response?.data?.data?.event;
        for (let i = 0; i < res.length; i++) {
          D.push(res[i]);
          setData(D);
        }
        setHasMoreEvents(res.hasMoreEvents);
        setNextToken(res.nexttoken);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);

  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_home(
        xpath.select1("/resources/string[@name='text_home']", doc).firstChild
          .data
      );
      setText_resellexpon(
        xpath.select1("/resources/string[@name='text_resellexpo']", doc)
          .firstChild.data
      );

      setTtext_BrowseMore(
        xpath.select1("/resources/string[@name='text_BrowseMore']", doc)
          .firstChild.data
      );
      setText_viewdetails(
        xpath.select1("/resources/string[@name='text_viewdetails']", doc)
          .firstChild.data
      );
      setText_reselltickets(
        xpath.select1("/resources/string[@name='text_reselltickets']", doc)
          .firstChild.data
      );
      setText_resellevents(
        xpath.select1("/resources/string[@name='text_resellevents']", doc)
          .firstChild.data
      );
    } catch (ert) {}
  };
  return (
    <>
      <Header />
      <div className="eventdetail-wrapper eventdetail-nightmod" id="otherPages">
        <div className="breadcrumb-block">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-10">
                <div className="barren-breadcrumb">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">{text_home}</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/resell-event">{text_resellevents}</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {text_reselltickets}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row p-4">
            <h4 className="reselticket-text">{text_reselltickets}</h4>
            <div className="d-flex gap-3 flex-wrap">
              {Data.map((e, index) => {
                return (
                  <>
                    <div className="resell-card" id={index}>
                      <div className="main-card mt-4">
                        <div className="card-top season-card-top p-4">
                          <div className="card-event-img resell-img-style responsive-card-img">
                            <img
                              src={
                                eventDetail[0]?.EventImagePath === "path"
                                  ? img1
                                  : eventDetail[0]?.EventImagePath
                              }
                              alt=""
                            />
                          </div>
                          <div className="card-event-dt">
                            <div>
                              <h5 style={{ fontSize: "15px" }}>
                                {text_resellexpo} : &nbsp;
                                <span
                                  className="mt-1"
                                  style={{ fontSize: "15px", color: "#1d3f73" }}
                                >
                                  {moment(e?.expiredatetime).format(
                                    "DD MMM, yyyy"
                                  )}
                                </span>
                              </h5>
                            </div>
                            <h5>{eventDetail[0]?.EventTitle}</h5>
                            <h6>
                              {" "}
                              {eventDetail[0]?.sell_currency}{" "}
                              {Number(e?.seat_details[0]?.seat_resell_price)
                                .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                            </h6>
                            <div className="evnt-time">
                              {moment
                                .utc(eventDetail[0]?.StartDate)
                                .format("DD MMM, yyyy")}{" "}
                              To{" "}
                              {moment
                                .utc(eventDetail[0]?.EndDate)
                                .format("DD MMM, yyyy")}
                            </div>
                            <div className="evnt-time">
                              {" "}
                              {moment
                                .utc(eventDetail[0]?.StartTime)
                                .format("hh : mm a")}{" "}
                              To{" "}
                              {moment
                                .utc(eventDetail[0]?.EndTime)
                                .format("hh : mm a")}
                            </div>

                            <div className="event-btn-group">
                              <button
                                className="esv-btn me-2"
                                onClick={() =>
                                  navigate(
                                    `/resell-event-details/${e?.reselltoken_unique_id}`,
                                    {
                                      state: {
                                        resell_id: e?.reselltoken_unique_id,
                                        resell_ticket_details: e?.seat_details,
                                      },
                                    }
                                  )
                                }
                              >
                                <Link to={"#"} className="resell-view-ticket ">
                                  <GrUpload /> {text_viewdetails}
                                </Link>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
            {hasMoreEvents ? (
              <>
                <div className="browse-btn">
                  <button
                    onClick={() => getMoreEventCard(nextToken, Data)}
                    className="main-btn btn-hover "
                  >
                    {text_BrowseMore}
                  </button>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ResellTicket;

import React, { useEffect, useState } from "react";
import "../AllEvent/AllEvent.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import AllEventCard from "./AllEventCard";
import { Link } from "react-router-dom";
import { GlobalConstants } from "../../utils/GlobalConst";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function AllEvent() {
  const [text_noticeallevent, setText_noticeallevent] = useState(
    "Discover Events For All The Things You Love"
  );
  const [text_find, setText_find] = useState("Find");
  const AllCategoryOption = [
    {
      value: "01",
      name: "All",
    },
    {
      value: "02",
      name: "Arts",
    },
    {
      value: "03",
      name: "Business",
    },
    {
      value: "04",
      name: "Coaching and Consulting",
    },
    {
      value: "05",
      name: "Community and Culture",
    },
    {
      value: "06",
      name: "Education and Training",
    },
    {
      value: "07",
      name: "Family and Friends",
    },
    {
      value: "08",
      name: "Fashion and Beauty",
    },
    {
      value: "09",
      name: "Film and Entertainment",
    },
    {
      value: "10",
      name: "Food and Drink",
    },
    {
      value: "11",
      name: "Free",
    },
    {
      value: "12",
      name: "Health and Wellbeing",
    },
    {
      value: "13",
      name: "Hobbies and Interest",
    },
    {
      value: "14",
      name: "Music and Theater",
    },
    {
      value: "15",
      name: "Religion and Spirituality",
    },
    {
      value: "16",
      name: "Science and Technology",
    },
    {
      value: "17",
      name: "Sports and Fitness",
    },
    {
      value: "18",
      name: "Travel and Outdoor",
    },
    {
      value: "19",
      name: "Visual Arts",
    },
  ];
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);
  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_noticeallevent(
        xpath.select1("/resources/string[@name='text_noticeallevent']", doc)
          .firstChild.data
      );
      setText_find(
        xpath.select1("/resources/string[@name='text_find']", doc).firstChild
          .data
      );
    } catch (ert) {}
  };
  return (
    <>
      <Header />
      <div className="wrapper" id="otherPages">
        <div className="hero-banner">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-8 col-lg-8 col-md-10">
                <div className="hero-banner-content">
                  <h2 className="hometextnight">{text_noticeallevent}</h2>
                  <div className="search-form main-form">
                    <div className="row g-3">
                      <div className="col-lg-5 col-md-12">
                        <div className="form-group ">
                          <select
                            className="select-option-sty"
                            data-width="100%"
                            data-size="5"
                          >
                            <option
                              value="browse_all"
                              data-icon="fa-solid fa-tower-broadcast"
                              selected
                            >
                              Browse All
                            </option>
                            <option
                              value="online_events"
                              data-icon="fa-solid fa-video"
                            >
                              Online Events
                            </option>
                            <option
                              value="venue_events"
                              data-icon="fa-solid fa-location-dot"
                            >
                              Venue Events
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-5 col-md-12">
                        <div className="form-group">
                          <select className="select-option-sty">
                            {AllCategoryOption.map((v) => {
                              return (
                                <>
                                  <option value={v.value}>{v.name}</option>
                                </>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-12">
                        <Link to="#" className="main-btn btn-hover w-100">
                          {text_find}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="explore-events p-80">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="event-filter-items">
                  <div className="featured-controls">
                    <AllEventCard />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AllEvent;
